import React from "react";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import doc1 from "../docx/Приложение_№1_Требование_акционера_о_выкупе_акций.docx";

@inject("AppStore")
@observer
class PageShareholders extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className={"w768"}>
          <div className={"text"}>
            <strong>10.03.2025</strong>
            <br />
            <br />
            <div>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "center",
                }}
              >
                <strong>СООБЩЕНИЕ</strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "center",
                }}
              >
                <strong>
                  о проведении годового заседания общего собрания акционеров
                </strong>
              </p>
              <h1
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "center",
                  fontSize: "10pt",
                }}
              >
                АО «Самокарт»
              </h1>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                <em>
                  <span style={{ color: "#ff0000" }}>&nbsp;</span>
                </em>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                Акционерное общество «Самокарт» (далее – АО «Самокарт» или
                Общество) сообщает акционерам Общества о проведении годового
                заседания общего собрания акционеров.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                Полное фирменное наименование Общества: Акционерное общество
                «Самокарт».
              </p>
              <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
                Место нахождения Общества: 123308, г. Москва, проспект Маршала
                Жукова, д.4, строение 1 помещение 9.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                Способ принятия решения общим собранием акционеров: заседание,
                совмещенное с заочным голосованием<strong>.</strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                Вид заседания общего собрания акционеров:{" "}
                <strong>годовое.</strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                Дата проведения заседания:{" "}
                <strong>«01» апреля 2025 года;</strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                Время проведения заседания:
                <strong>12 часов 00 минут по московскому времени.</strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                  fontSize: "11pt",
                }}
              >
                <span style={{ fontSize: "10pt" }}>
                  Место проведения заседания (адрес, по которому будет
                  проводиться заседание общего собрания акционеров):
                </span>
                <span style={{ fontSize: "10pt", color: "#ff0000" }}>
                  &nbsp;
                </span>
                -{" "}
                <span style={{ fontSize: "10pt" }}>
                  г. Москва, Большой Балканский пер., д.20, стр.1.
                </span>
                <br />
                <span style={{ fontSize: "10pt" }}>
                  Дата, на которую определяются (фиксируются) лица, имеющие
                  право голоса при принятии решений общим собранием
                  акционеров:&nbsp;
                </span>
                <strong>
                  <span style={{ fontSize: "10pt" }}>
                    «07» марта 2025 года.
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                  widows: 0,
                  orphans: 0,
                }}
              >
                <span style={{ color: "#00000a" }}>
                  Категории (типы) акций, владельцы которых имеют право голоса
                  по всем вопросам повестки дня: обыкновенные акции,
                  привилегированные акции тип первый, привилегированные акции
                  тип второй.
                </span>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "12pt",
                  textAlign: "justify",
                }}
              >
                <strong>
                  Время и место начала регистрации лиц, имеющих право голоса при
                  принятии решения общим собранием акционеров:
                </strong>{" "}
                «01» апреля 2025 года с 11 часов 45 минут по московскому
                времени, по адресу места проведения заседания.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "12pt",
                  textAlign: "justify",
                }}
              >
                Для регистрации лицам, имеющим право голоса при принятии решений
                общим собранием акционеров (их представителям), необходимо
                предъявить документ, удостоверяющий личность, а также в случаях,
                предусмотренных законодательством Российской Федерации, передать
                лицу, исполняющему функции счетной комиссии, документы,
                подтверждающие полномочия для осуществления голосования (их
                копии, засвидетельствованные (удостоверенные) в порядке,
                предусмотренном законодательством Российской Федерации).
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                <strong>
                  Голосование на заседании общего собрания акционеров будет
                  совмещаться с заочным голосованием.
                </strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                <strong>
                  Дата окончания приема бюллетеней для голосования при
                  проведении заочного голосования:
                </strong>{" "}
                «29» марта 2025 года.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                  fontSize: "12pt",
                }}
              >
                <strong>
                  <span style={{ fontSize: "10pt" }}>
                    Почтовый адрес, по которому могут направляться заполненные
                    бюллетени для голосования
                  </span>
                </strong>
                <span style={{ fontSize: "10pt" }}>
                  : 123308, г. Москва, проспект Маршала Жукова, д.4, строение 1
                  помещение 9
                </span>
                .
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                При направлении заполненных документов в Общество представителям
                лиц, имеющих право голоса при принятии решений общим собранием
                акционеров, необходимо приложить документы, подтверждающие их
                полномочия (их копии, засвидетельствованные (удостоверенные) в
                порядке, предусмотренном законодательством Российской
                Федерации).
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                <span style={{ color: "#ff0000" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                <strong>
                  Способы подписания бюллетеней для голосования:&nbsp;
                </strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                Бюллетень для голосования подписывается лицом, имеющим право
                голоса при принятии решений общим собранием акционеров, или его
                представителем собственноручной подписью.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                К голосованию, осуществляемому бюллетенями для голосования,
                приравнивается получение регистратором общества сообщений о
                волеизъявлении лиц, которые имеют право голоса при принятии
                решений общим собранием акционеров, не зарегистрированы в
                реестре акционеров общества и в соответствии с требованиями
                законодательства РФ о ценных бумагах дали лицам, осуществляющим
                учет их прав на акции, указания (инструкции) о голосовании.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "center",
                }}
              >
                <strong>Повестка дня:</strong>
              </p>
              <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
                <li
                  style={{
                    marginLeft: "21.3pt",
                    textIndent: "-7.1pt",
                    textAlign: "justify",
                    listStylePosition: "inside",
                  }}
                >
                  <span
                    style={{
                      width: "1.65pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;
                  </span>
                  Утверждение годового отчета, годовой бухгалтерской
                  (финансовой) отчетности АО «Самокарт» за 2024&nbsp;год.
                </li>
                <li
                  style={{
                    marginLeft: "21.3pt",
                    textIndent: "-7.1pt",
                    textAlign: "justify",
                    listStylePosition: "inside",
                  }}
                >
                  <span
                    style={{
                      width: "1.65pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;
                  </span>
                  Распределение прибыли (в том числе выплата (объявление)
                  дивидендов) и убытков АО «Самокарт» по результатам&nbsp;&nbsp;
                  2024 года.
                </li>
                <li
                  style={{
                    marginLeft: "14.2pt",
                    textAlign: "justify",
                    listStylePosition: "inside",
                  }}
                >
                  <span
                    style={{
                      width: "1.65pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;
                  </span>
                  Избрание членов Совета директоров АО «Самокарт».
                </li>
              </ol>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "12pt",
                  textAlign: "justify",
                }}
              >
                Лица, имеющие право голоса при принятии решений общим собранием
                акционеров, могут ознакомиться с материалами, подлежащими
                предоставлению при подготовке к проведению заседания по адресу:
                123308, г. Москва, проспект Маршала Жукова, д.4, строение 1
                помещение 9, в течение 20 дней до даты проведения заседания
                общего собрания акционеров, в рабочие дни и часы Общества, а
                также во время регистрации и проведения заседания общего
                собрания акционеров по месту его проведения.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                Акционеры, зарегистрированные в реестре акционеров общества,
                обязаны своевременно предоставлять информацию об изменении своих
                данных, в том числе сведений об адресе, банковских реквизитах,
                регистратору общества.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                В случае, если акционером, зарегистрированным в реестре
                акционеров, своевременно не предоставлена информация об
                актуальных данных, в частности, сведения о почтовом адресе,
                банковских реквизитах, предупреждаем о возможности
                приостановления направления сообщений и (или) бюллетеней для
                голосования по почтовому адресу, а также о возможности
                приостановления выплаты дивидендов в случае, если решение о
                выплате дивидендов будет принято общим собранием акционеров.
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textAlign: "justify",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textIndent: "14.2pt",
                  textAlign: "justify",
                }}
              >
                Акционеры вправе предоставить
                <strong>
                  <span style={{ color: "#806000" }}>&nbsp;&nbsp;</span>
                </strong>
                регистратору общества Акционерному обществу «Реестр» (
                <a
                  href="http://www.aoreestr.ru"
                  style={{ textDecoration: "none" }}
                >
                  <u>
                    <span style={{ color: "#0000ff" }}>www.aoreestr.ru</span>
                  </u>
                </a>
                ) актуальную информацию о почтовом адресе акционера, банковских
                реквизитах, иные актуализированные сведения в порядке,
                предусмотренном Правилами ведения реестров владельцев ценных
                бумаг АО «Реестр» (
                <a
                  href="https://www.aoreestr.ru/emitent/rules-docs/"
                  style={{ textDecoration: "none" }}
                >
                  <u>
                    <span style={{ color: "#0000ff" }}>
                      https://www.aoreestr.ru/emitent/rules-docs/
                    </span>
                  </u>
                </a>{" "}
                ), в том числе посредством ЛКК «Реестр-Онлайн».
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textIndent: "18pt",
                  textAlign: "justify",
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  textIndent: "18pt",
                  textAlign: "justify",
                }}
              >
                <strong>Совет директоров АО «Самокарт».</strong>
              </p>
              <p style={{ bottom: 10, right: 10, position: "absolute" }}>
                <a
                  href="https://wordtohtml.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontSize: 11, color: "#d0d0d0" }}
                >
                  Converted to HTML with WordToHTML.net
                </a>{" "}
                <span style={{ fontSize: 11, color: "#d0d0d0" }}>|</span>{" "}
                <a
                  href="https://wordtohtml.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontSize: 11, color: "#d0d0d0" }}
                >
                  Email Signature Generator
                </a>
              </p>
            </div>

            <br />
            <br />
          </div>

          <div className={"text"}>
            <strong>24.05.2024</strong>
            <br />
            <br />

            <div align="center">
              <strong>отчет</strong>
              <br />
              <strong>об итогах голосования</strong>
              <br />
              <strong>на Внеочередном общем собрании акционеров</strong>
              <br />
              <strong>Акционерного общества "Самокарт"</strong>
              <br />
              <strong>(далее АО «Самокарт» и/или Общество)</strong>
              <br />
              <span lang="ru-RU">
                (350051, Краснодарский край, г. Краснодар, ул. Стахановская, д.
                15, корп. 3, ком. 2.)
              </span>
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span lang="ru-RU">г. Краснодар</span>
              <span lang="ru-RU">24.05.2024</span>
            </div>
            <br />
            <br />

            <div className="western" align="justify">
              Полное фирменное наименование общества: Акционерное общество
              «Самокарт» (далее именуемое Общество).
            </div>
            <div className="western" align="justify">
              Место нахождения Общества: Краснодарский край, г. Краснодар.
            </div>
            <div className="western" align="justify">
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, корп. 3, ком. 2.
            </div>
            <div className="western" align="justify">
              Вид общего собрания: внеочередное.
            </div>
            <div className="western" align="justify">
              Форма проведения общего собрания: заочное голосование.
            </div>
            <div className="western" align="justify">
              Дата определения лиц, имевших право на участие в общем собрании:
              «14» мая 2024 г.
            </div>
            <div className="western" align="justify">
              Дата проведения общего собрания: «21» мая 2024 г.
            </div>
            <div className="western" align="justify">
              Сведения о регистраторе, выполнявшем функции счетной комиссии:
            </div>
            <div className="western" align="justify">
              Полное фирменное наименование: Акционерное общество «Реестр».
            </div>
            <div className="western" align="justify">
              Место нахождения: Российская Федерация, г. Москва.
            </div>
            <div className="western" align="justify">
              Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
              стр. 1.
            </div>
            <div className="western" align="justify">
              Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
            </div>
            <div className="western" align="justify">
              Почтовый адрес, по которому направлялись заполненные бюллетени для
              голосования: 123308, г. Москва, Проспект Маршалла Жукова, д. 4,
              стр. 1, помещение 9.
            </div>
            <div className="western" align="justify">
              Председательствующий на общем собрании акционеров: Тимофеев
              Александр Сергеевич.
            </div>
            <div className="western" align="justify">
              Секретарь общего собрания акционеров: Сенькина Юлия Сергеевна.
            </div>
            <div className="western" align="justify">
              Дата составления протокола: 22 мая 2024 г.
            </div>

            <br />

            <div className="western" align="justify">
              <h2>
                <strong>Повестка дня собрания:</strong>
              </h2>
            </div>
            <div className="western" align="justify">
              1. Утвердить новую редакцию Устава АО «Самокарт» (редакция 5).
            </div>
            <br />
            <div className="western" align="justify">
              <strong>
                Результаты голосования и формулировки принятых решений:
              </strong>
            </div>
            <br />
            <div className="western" align="justify">
              <strong>По вопросу повестки дня №1:</strong>
            </div>
            <br />
            <div className="western" align="justify">
              <strong>
                Формулировка решения, поставленного на голосование:
              </strong>
            </div>

            <div className="western" align="justify">
              1. Утвердить новую редакцию Устава АО «Самокарт» (редакция 5).
            </div>
            <br />

            <table
              border={1}
              width={644}
              cellSpacing={0}
              cellPadding={7}
              bgcolor="#ced7e7"
            >
              <tbody>
                <tr valign="top">
                  <td bgcolor="#ffffff" width={385}>
                    <div className="western" align="justify">
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </div>
                  </td>
                  <td bgcolor="#ffffff" width={229}>
                    <div className="western" align="center">
                      <strong>23 590 243</strong>
                    </div>
                  </td>
                </tr>
                <tr valign="top">
                  <td bgcolor="#ffffff" width={385}>
                    <div className="western" align="justify">
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров»
                    </div>
                  </td>
                  <td bgcolor="#ffffff" width={229}>
                    <div className="western" align="center">
                      <strong>23 590 243</strong>
                    </div>
                  </td>
                </tr>
                <tr valign="top">
                  <td bgcolor="#ffffff" width={385}>
                    <div className="western" align="justify">
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </div>
                  </td>
                  <td bgcolor="#ffffff" width={229}>
                    <div className="western" align="center">
                      <strong>23 590 243</strong>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <br />

            <div className="western" align="justify">
              <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            </div>

            <br />

            <div className="western" align="justify">
              <strong>Итоги голосования:</strong>
            </div>
            <table
              border={1}
              width={644}
              cellSpacing={0}
              cellPadding={7}
              bgcolor="#ced7e7"
            >
              <tbody>
                <tr valign="top">
                  <td bgcolor="#ffffff" width={385}>
                    <div className="western" align="justify">
                      Число голосов, отданных за вариант голосования «ЗА»
                    </div>
                  </td>
                  <td bgcolor="#ffffff" width={229}>
                    <div className="western" align="center">
                      <strong>23 590 243</strong> | <strong>100%*</strong>
                    </div>
                  </td>
                </tr>
                <tr valign="top">
                  <td bgcolor="#ffffff" width={385}>
                    <div className="western" align="justify">
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </div>
                  </td>
                  <td bgcolor="#ffffff" width={229}>
                    <div className="western" align="center">
                      <strong>0</strong>
                    </div>
                  </td>
                </tr>
                <tr valign="top">
                  <td bgcolor="#ffffff" width={385}>
                    <div className="western" align="justify">
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </div>
                  </td>
                  <td bgcolor="#ffffff" width={229}>
                    <div className="western" align="center">
                      <strong>0</strong>
                    </div>
                  </td>
                </tr>
                <tr valign="top">
                  <td bgcolor="#ffffff" width={385}>
                    <div className="western" align="justify">
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </div>
                  </td>
                  <td bgcolor="#ffffff" width={229}>
                    <div className="western" align="center">
                      <strong>0</strong>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <br />

            <div className="western" align="justify">
              <small>
                *процент определяется от числа голосов, которыми обладали все
                акционеры-владельцы акций Общества всех категорий (типов).
              </small>
            </div>
            <br />
            <div className="western" align="justify">
              <strong>Формулировка решения, принятого общим собранием:</strong>
            </div>
            <div className="western" align="justify">
              1. Утвердить новую редакцию Устава АО «Самокарт» (редакция 5).
            </div>
            <br />
            <div className="western" align="justify">
              <strong>Председательствующий на общем собрании</strong>
            </div>
            <div className="western" align="justify">
              <strong>Тимофеев Александр Сергеевич</strong>
            </div>
            <br />
            <div className="western" align="justify">
              <strong>Секретарь общего собрания</strong>
            </div>
            <div className="western" align="justify">
              <strong>Сенькина Юлия Сергеевна</strong>
            </div>
          </div>
          <br />

          <div className={"text"}>
            <strong>15.05.2024</strong>
            <br />
            <br />

            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                fontSize: "14pt",
              }}
            >
              <strong>
                <span>Сообщение</span>
              </strong>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
              }}
            >
              <strong>
                <span>
                  о проведении внеочередного Общего собрания акционеров
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
              }}
            >
              <strong>
                <span>АО "Самокарт"</span>
              </strong>
            </p>

            <br />
            <br />
            <div
              style={{
                textAlign: "left",
              }}
            >
              <span>
                Акционерное общество "Самокарт" (далее – Общество) сообщает
                акционерам Общества о проведении внеочередного общего собрания
                акционеров.
              </span>
              <br />
              <span>
                Полное фирменное наименование Общества: Акционерное общество
                "Самокарт".
              </span>
              <br />
              <span>
                Место нахождения Общества: Краснодарский край, г. Краснодар.
              </span>
              <br />
              <span>
                Дата окончания приема заполненных бюллетеней для голосования
                (дата проведения собрания): 21 мая 2024 года.
              </span>
              <br />
              <span>
                Дата, на которую определяются (фиксируются) лица, имеющие право
                на участие в общем собрании акционеров: 14
                <span style={{ color: "#020202" }}>&nbsp;мая&nbsp;</span>2024
                года.
              </span>
              <br />
              <span>
                Категории (типы) акций, владельцы которых имеют право голоса по
                всем вопросам повестки дня общего собрания акционеров:
                обыкновенные, привилегированные.
              </span>
              <br />
              <span>
                Почтовый адрес, по которому должны направляться заполненные
                бюллетени для голосования:{" "}
                <span>
                  123308, г. Москва, проспект Маршала Жукова, д.4, стр. 1,&nbsp;
                </span>
                помещение 9.
              </span>
              <br />
              <span>
                При направлении заполненных документов в общество,
                представителям акционеров необходимо приложить документы,
                подтверждающие их полномочия для осуществления голосования (их
                копии, засвидетельствованные (удостоверенные) в порядке,
                предусмотренном законодательством Российской Федерации).
              </span>

              <br />
              <span>Вид собрания: внеочередное общее собрание акционеров.</span>
              <br />
              <span> Форма проведения собрания: Заочное голосование.</span>
            </div>

            <br />
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <strong>
                Вопросы, включенные в повестку дня внеочередного собрания
                акционеров Общества:
              </strong>
            </p>

            <br />

            <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontSize: "11pt",
                }}
              >
                Утвердить новую редакцию Устава АО «Самокарт» (редакция 5).
              </li>
            </ol>
            <br />

            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              <span>
                Акционеры Общества могут ознакомиться с материалами, подлежащими
                предоставлению при подготовке к проведению общего собрания
                акционеров Общества по адресу:&nbsp;
              </span>
              <span>
                123308, г. Москва, проспект Маршала Жукова, д.4, стр. 1,&nbsp;
              </span>
              <span>помещение 9</span>
              <span>&nbsp;&nbsp;</span>
              <span>с «16» мая 2024 г., в рабочие дни с 09.00 до 18.00.</span>
            </p>

            <br />
            <br />

            <strong>
              <div style={{ textAlign: "left" }}>
                Совет директоров АО "Самокарт"
              </div>
            </strong>
          </div>

          <br />
          <div className={"text"}>
            <strong>03.04.2024</strong>
            <br />
            <br />
            <strong>Отчет</strong>
            <br />
            <strong>
              об итогах голосования на годовом общем собрании акционеров
            </strong>
            <br />
            <strong>АО «Самокарт»</strong>
            <br />
            <br />
            Полное фирменное наименование общества: Акционерное общество
            «Самокарт» (далее именуемое Общество).
            <br />
            Место нахождения Общества: Краснодарский край, г. Краснодар.
            <br />
            Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, к. 3, ком. 2.
            <br />
            Вид общего собрания: годовое.
            <br />
            Форма проведения общего собрания: заочное голосование.
            <br />
            Дата определения (фиксации) лиц, имевших право на участие в общем
            собрании акционеров Общества: 08 марта 2024 г.
            <br />
            Дата проведения общего собрания: 02 апреля 2024 г.
            <br />
            Сведения о регистраторе, выполнявшем функции счетной комиссии:
            <br />
            Полное фирменное наименование: Акционерное общество "Реестр".
            <br />
            Место нахождения: Российская Федерация, г. Москва.
            <br />
            Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
            стр. 1.
            <br />
            Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
            <br />
            Председательствующий на общем собрании акционеров: Литвиненко Сергей
            Иванович.
            <br />
            Секретарь общего собрания акционеров: Евдаков Кирилл Сергеевич
            <br />
            <br />
            <br />
            <strong>Повестка дня собрания:</strong>
            <br />
            <br />
            1. Утверждение годового отчета, годовой бухгалтерской (финансовой)
            отчетности Общества за 2023 год.
            <br />
            2. Распределение прибыли (в том числе выплата (объявление)
            дивидендов) и убытков общества по результатам 2023 отчетного года{" "}
            <br />
            3. Избрание членов Совета директоров Общества.
            <br />
            4. О прекращении полномочий единоличного исполнительного органа в
            лице Евдакова К.С.
            <br />
            5. Об избрании единоличного исполнительного органа в лице Быкова
            Геннадия Викторовича.
            <br />
            6. Об изменении места нахождения общества.
            <br />
            <br />
            <br />
            <strong>
              Результаты голосования и формулировки принятых решений:
            </strong>
            <br />
            <strong> </strong>
            <br />
            <strong>По вопросу повестки дня №1:</strong>
            <br />
            <br />
            <strong>Формулировка решения, поставленного на голосование:</strong>
            <br />
            1. Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
            отчетность за 2023 отчетный год.
            <br />
            <br /> Число голосов, которыми обладали лица, включенные в список
            лиц, имевших право на участие в общем собрании, по данному вопросу
            повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, приходившихся на голосующие акции Общества, по
            данному вопросу повестки дня общего собрания, определенное с учетом
            положений пункта 4.24 «Положения об общих собраниях акционеров»
            (утв. Банком России 16.11.2018 N 660-П)
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, которыми обладали лица, принявшие участие в
            общем собрании, по данному вопросу повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> <br />
            <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            <br />
            <br />
            <strong>Итоги голосования: </strong>
            <br /> Число голосов, отданных за вариант голосования «ЗА»
            <br />
            <strong>23 590 243 | 100%*</strong>
            <br /> Число голосов, отданных за вариант голосования «ПРОТИВ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, которые не подсчитывались в связи с признанием
            бюллетеней в части голосования по данному вопросу повестки дня
            недействительными или по иным основаниям
            <br />
            <strong>0</strong>
            <br /> <br /> *процент определяется от числа голосов, которыми
            обладали лица, принявшие участие в общем собрании, по данному
            вопросу повестки дня, определенное с учетом положений п. 4.24
            «Положения об общих собраниях акционеров» (утв. Банком России
            16.11.2018 N 660-П).
            <br />
            <br />
            <strong>Формулировка решения, принятого общим собранием:</strong>
            <br />
            1. Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
            отчетность за 2023 отчетный год.
            <br />
            <br />
            <strong>По вопросу повестки дня №2:</strong>
            <br />
            <br />
            <strong>Формулировка решения, поставленного на голосование:</strong>
            <br />
            2. Дивиденды за 2023 год по обыкновенным акциям, привилегированным
            акциям тип первый и тип второй не выплачивать, в связи с получением
            убытка по результатам деятельности АО "Самокарт" в 2023 году.
            <br />
            <br /> Число голосов, которыми обладали лица, включенные в список
            лиц, имевших право на участие в общем собрании, по данному вопросу
            повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, приходившихся на голосующие акции Общества, по
            данному вопросу повестки дня общего собрания, определенное с учетом
            положений пункта 4.24 «Положения об общих собраниях акционеров»
            (утв. Банком России 16.11.2018 N 660-П)
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, которыми обладали лица, принявшие участие в
            общем собрании, по данному вопросу повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> <br />
            <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            <br />
            <br />
            <strong>Итоги голосования: </strong>
            <br /> Число голосов, отданных за вариант голосования «ЗА»
            <br />
            <strong>23 590 243 | 100%*</strong>
            <br /> Число голосов, отданных за вариант голосования «ПРОТИВ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, которые не подсчитывались в связи с признанием
            бюллетеней в части голосования по данному вопросу повестки дня
            недействительными или по иным основаниям
            <br />
            <strong>0</strong>
            <br /> <br /> *процент определяется от числа голосов, которыми
            обладали лица, принявшие участие в общем собрании, по данному
            вопросу повестки дня, определенное с учетом положений п. 4.24
            «Положения об общих собраниях акционеров» (утв. Банком России
            16.11.2018 N 660-П).
            <br />
            <br />
            <strong>Формулировка решения, принятого общим собранием:</strong>
            <br />
            2. Дивиденды за 2023 год по обыкновенным акциям, привилегированным
            акциям тип первый и тип второй не выплачивать, в связи с получением
            убытка по результатам деятельности АО "Самокарт" в 2023 году.
            <br />
            <br />
            <strong>По вопросу повестки дня №3:</strong>
            <br />
            <br />
            <strong>Формулировка решения, поставленного на голосование:</strong>
            <br />
            3. Избрать членом Совета директоров АО "Самокарт":
            <br />
            1. Тимофеев Александр Сергеевич <br />
            2. Чернышев Александр Дмитриевич <br />
            3. Быков Геннадий Викторович <br />
            4. Литвиненко Сергей Иванович <br />
            5. Евдаков Кирилл Сергеевич
            <br />
            <br /> Число голосов, которыми обладали лица, включенные в список
            лиц, имевших право на участие в общем собрании, по данному вопросу
            повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, приходившихся на голосующие акции Общества, по
            данному вопросу повестки дня общего собрания, определенное с учетом
            положений пункта 4.24 «Положения об общих собраниях акционеров»
            (утв. Банком России 16.11.2018 N 660-П)
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, которыми обладали лица, принявшие участие в
            общем собрании, по данному вопросу повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> <br />
            <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            <br />
            <br />
            <strong>Итоги голосования: </strong>
            <br /> Число голосов, отданных за вариант голосования «ЗА»
            <br />
            <strong>23 590 243 | 100%*</strong>
            <br /> Число голосов, отданных за вариант голосования «ПРОТИВ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, которые не подсчитывались в связи с признанием
            бюллетеней в части голосования по данному вопросу повестки дня
            недействительными или по иным основаниям
            <br />
            <strong>0</strong>
            <br /> <br /> *процент определяется от числа голосов, которыми
            обладали лица, принявшие участие в общем собрании, по данному
            вопросу повестки дня, определенное с учетом положений п. 4.24
            «Положения об общих собраниях акционеров» (утв. Банком России
            16.11.2018 N 660-П).
            <br />
            <br />
            <strong>Формулировка решения, принятого общим собранием:</strong>
            <br />
            3. Избрать членом Совета директоров АО "Самокарт":
            <br />
            1. Тимофеев Александр Сергеевич <br />
            2. Чернышев Александр Дмитриевич <br />
            3. Быков Геннадий Викторович <br />
            4. Литвиненко Сергей Иванович <br />
            5. Евдаков Кирилл Сергеевич
            <br />
            <br />
            <strong>По вопросу повестки дня №4:</strong>
            <br />
            <br />
            <strong>Формулировка решения, поставленного на голосование:</strong>
            <br />
            4. Прекратить полномочия единоличного исполнительного органа-
            Генерального директора АО "Самокарт" Евдакова Кирилла Сергеевича.
            <br />
            <br /> Число голосов, которыми обладали лица, включенные в список
            лиц, имевших право на участие в общем собрании, по данному вопросу
            повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, приходившихся на голосующие акции Общества, по
            данному вопросу повестки дня общего собрания, определенное с учетом
            положений пункта 4.24 «Положения об общих собраниях акционеров»
            (утв. Банком России 16.11.2018 N 660-П)
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, которыми обладали лица, принявшие участие в
            общем собрании, по данному вопросу повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> <br />
            <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            <br />
            <br />
            <strong>Итоги голосования: </strong>
            <br /> Число голосов, отданных за вариант голосования «ЗА»
            <br />
            <strong>23 590 243 | 100%*</strong>
            <br /> Число голосов, отданных за вариант голосования «ПРОТИВ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, которые не подсчитывались в связи с признанием
            бюллетеней в части голосования по данному вопросу повестки дня
            недействительными или по иным основаниям
            <br />
            <strong>0</strong>
            <br /> <br /> *процент определяется от числа голосов, которыми
            обладали лица, принявшие участие в общем собрании, по данному
            вопросу повестки дня, определенное с учетом положений п. 4.24
            «Положения об общих собраниях акционеров» (утв. Банком России
            16.11.2018 N 660-П).
            <br />
            <br />
            <strong>Формулировка решения, принятого общим собранием:</strong>
            <br />
            4. Прекратить полномочия единоличного исполнительного органа-
            Генерального директора АО "Самокарт" Евдакова Кирилла Сергеевича.
            <br />
            <br />
            <strong>По вопросу повестки дня №5:</strong>
            <br />
            <br />
            <strong>Формулировка решения, поставленного на голосование:</strong>
            <br />
            5. Избрать единоличный исполнительный орган - Генерального директора
            АО "Самокарт" в лице Быкова Геннадия Викторовича.
            <br />
            <br /> Число голосов, которыми обладали лица, включенные в список
            лиц, имевших право на участие в общем собрании, по данному вопросу
            повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, приходившихся на голосующие акции Общества, по
            данному вопросу повестки дня общего собрания, определенное с учетом
            положений пункта 4.24 «Положения об общих собраниях акционеров»
            (утв. Банком России 16.11.2018 N 660-П)
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, которыми обладали лица, принявшие участие в
            общем собрании, по данному вопросу повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> <br />
            <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            <br />
            <br />
            <strong>Итоги голосования: </strong>
            <br /> Число голосов, отданных за вариант голосования «ЗА»
            <br />
            <strong>23 590 243 | 100%*</strong>
            <br /> Число голосов, отданных за вариант голосования «ПРОТИВ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, которые не подсчитывались в связи с признанием
            бюллетеней в части голосования по данному вопросу повестки дня
            недействительными или по иным основаниям
            <br />
            <strong>0</strong>
            <br /> <br /> *процент определяется от числа голосов, которыми
            обладали лица, принявшие участие в общем собрании, по данному
            вопросу повестки дня, определенное с учетом положений п. 4.24
            «Положения об общих собраниях акционеров» (утв. Банком России
            16.11.2018 N 660-П).
            <br />
            <br />
            <strong>Формулировка решения, принятого общим собранием:</strong>
            <br />
            5. Избрать единоличный исполнительный орган - Генерального директора
            АО "Самокарт" в лице Быкова Геннадия Викторовича.
            <br />
            <br />
            <strong>По вопросу повестки дня №6:</strong>
            <br />
            <br />
            <strong>Формулировка решения, поставленного на голосование:</strong>
            <br />
            6. Изменить место нахождения общества на г. Москва и определить
            новым адресом общества следующий: 123308, г. Москва, внутригородская
            территория (внутригородское муниципальное образование) города
            федерального значения муниципальный округ Хорошево-Мневники,
            проспект Маршала Жукова, дом 4, строение 1.
            <br />
            Поручить генеральному директору совершить все (любые) юридические и
            иные действия, необходимые для внесения в ЕГРЮЛ сведений о принятии
            решения об изменении адреса общества, влекущем изменение его
            местонахождения (изменений в сведениях об адресе общества в ЕГРЮЛ).
            <br />
            <br /> Число голосов, которыми обладали лица, включенные в список
            лиц, имевших право на участие в общем собрании, по данному вопросу
            повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, приходившихся на голосующие акции Общества, по
            данному вопросу повестки дня общего собрания, определенное с учетом
            положений пункта 4.24 «Положения об общих собраниях акционеров»
            (утв. Банком России 16.11.2018 N 660-П)
            <br />
            <strong>23 590 243</strong>
            <br /> Число голосов, которыми обладали лица, принявшие участие в
            общем собрании, по данному вопросу повестки дня общего собрания
            <br />
            <strong>23 590 243</strong>
            <br /> <br />
            <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            <br />
            <br />
            <strong>Итоги голосования: </strong>
            <br /> Число голосов, отданных за вариант голосования «ЗА»
            <br />
            <strong>23 590 243 | 100%*</strong>
            <br /> Число голосов, отданных за вариант голосования «ПРОТИВ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
            <br />
            <strong>0</strong>
            <br /> Число голосов, которые не подсчитывались в связи с признанием
            бюллетеней в части голосования по данному вопросу повестки дня
            недействительными или по иным основаниям
            <br />
            <strong>0</strong>
            <br /> <br /> *процент определяется от числа голосов, которыми
            обладали лица, принявшие участие в общем собрании, по данному
            вопросу повестки дня, определенное с учетом положений п. 4.24
            «Положения об общих собраниях акционеров» (утв. Банком России
            16.11.2018 N 660-П).
            <br />
            <br />
            <strong>Формулировка решения, принятого общим собранием:</strong>
            <br />
            6. Изменить место нахождения общества на г. Москва и определить
            новым адресом общества следующий: 123308, г. Москва, внутригородская
            территория (внутригородское муниципальное образование) города
            федерального значения муниципальный округ Хорошево-Мневники,
            проспект Маршала Жукова, дом 4, строение 1.
            <br />
            Поручить генеральному директору совершить все (любые) юридические и
            иные действия, необходимые для внесения в ЕГРЮЛ сведений о принятии
            решения об изменении адреса общества, влекущем изменение его
            местонахождения (изменений в сведениях об адресе общества в ЕГРЮЛ).
            <br />
            <br />
            <strong> </strong>
            <br />
            <br />
            <strong>
              Председательствующий на собрании Литвиненко Сергей Иванович{" "}
            </strong>
            <br />
            <strong> </strong>
            <br />
            <strong>Секретарь собрания Евдаков Кирилл Сергеевич </strong>
            <br />
            <br />
            11.03.2024
            <br />
            <strong>
              Сообщение о проведении годового Общего собрания акционеров
            </strong>
            <br />
            <strong>АО «Самокарт»</strong>
            <br />
            <br />
            Акционерное общество «Самокарт» (далее – Общество) сообщает
            акционерам Общества о проведении годового общего собрания
            акционеров.
            <br />
            Полное фирменное наименование Общества: Акционерное общество
            «Самокарт». Место нахождения Общества: Краснодарский край, г.
            Краснодар.
            <br />
            <br />
            Дата окончания приема заполненных бюллетеней для голосования (дата
            проведения собрания): 02 апреля 2024 года.
            <br />
            Дата, на которую определяются (фиксируются) лица, имеющие право на
            участие в общем собрании акционеров: 08 марта 2024 года.
            <br />
            Категории (типы) акций, владельцы которых имеют право голоса по всем
            вопросам повестки дня общего собрания акционеров: обыкновенные,
            привилегированные.
            <br />
            Почтовый адрес, по которому должны направляться заполненные
            бюллетени для голосования: 350051, Краснодарский край, г. Краснодар,
            ул. Стахановская, д. 15, к. 3, ком. 2.
            <br />
            При направлении заполненных документов в общество, представителям
            акционеров необходимо приложить документы, подтверждающие их
            полномочия для осуществления голосования (их копии,
            засвидетельствованные (удостоверенные) в порядке, предусмотренном
            законодательством Российской Федерации).
            <br />
            Вид собрания: годовое.
            <br />
            Форма проведения собрания: Заочное голосование.
            <br />
            <br />
            <strong>
              Вопросы, включенные в повестку дня годового общего собрания
              акционеров Общества:
            </strong>
            <br />
            <br />
            1. Утверждение годового отчета, годовой бухгалтерской (финансовой)
            отчетности Общества за 2023 год.
            <br />
            2. Распределение прибыли (в том числе выплата (объявление)
            дивидендов) и убытков общества по результатам 2023 отчетного года.
            <br />
            3. Избрание членов Совета директоров Общества.
            <br />
            4. О прекращении полномочий единоличного исполнительного органа в
            лице Евдакова К.С.
            <br />
            5. Об избрании единоличного исполнительного органа в лице Быкова
            Геннадия Викторовича.
            <br />
            6. Об изменении места нахождения общества.
            <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: 350051, Краснодарский край, г.
            Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2. в течение 20 дней
            до даты проведения общего собрания акционеров, в рабочие дни и часы
            Общества.
            <br />
            <br />
            <strong>Совет директоров АО «Самокарт»</strong>
            <br />
            <br />
            <br />
            <br />
            24.10.2021
            <br />
            <br />
            <strong>
              Сообщение о&nbsp;проведении годового Общего собрания акционеров
            </strong>
            <br />
            <strong>АО «САМОКАРТ»</strong>
            <br />
            <br />
            Акционерное общество «Самокарт» (далее&nbsp;— АО «Самокарт»,
            Общество) сообщает акционерам Общества о&nbsp;проведении
            внеочередного общего собрания акционеров.
            <br />
            <br />
            Полное фирменное наименование Общества: Акционерное общество
            «Самокарт».
            <br />
            <br />
            Место нахождения Общества: РФ, г.&nbsp;Краснодар. Адрес Общества:
            г.&nbsp;Краснодар, ул.&nbsp;Стахановская, д.&nbsp;15, корп.3, ком.2.
            <br />
            <br />
            Дата окончания приема заполненных бюллетеней для&nbsp;голосования
            (дата проведения собрания): 15&nbsp;ноября 2021 года.
            <br />
            <br />
            Дата, на&nbsp;которую определяются (фиксируются) лица, имеющие право
            на&nbsp;участие в&nbsp;общем собрании акционеров: 23&nbsp;октября
            2021 года.
            <br />
            <br />
            Категории (типы) акций, владельцы которых имеют право голоса
            по&nbsp;всем вопросам повестки дня общего собрания акционеров:
            обыкновенные.
            <br />
            <br />
            Почтовый адрес, по&nbsp;которому должны направляться заполненные
            бюллетени для&nbsp;голосования: 350 051, г.&nbsp;Краснодар,
            ул.&nbsp;Стахановская, д.&nbsp;15, корп.3, комната 2. При
            направлении заполненных документов в&nbsp;общество, представителям
            акционеров необходимо приложить документы, подтверждающие
            их&nbsp;полномочия для&nbsp;осуществления голосования
            (их&nbsp;копии, засвидетельствованные (удостоверенные)
            в&nbsp;порядке, предусмотренном законодательством Российской
            Федерации).
            <br />
            <br />
            Вид собрания: внеочередное.
            <br />
            <br />
            Форма проведения собрания: Заочное голосование.
            <br />
            <br />
            <strong>
              Вопросы, включенные в&nbsp;повестку дня годового общего собрания
              акционеров Общества:
            </strong>
            <br />
            <br />
            1. Увеличение уставного капитала Общества путем размещения
            дополнительных обыкновенных акций.
            <br />
            <br />
            2. Увеличение уставного капитала Общества путем размещения
            привилегированных акций тип первый.
            <br />
            <br />
            3. Увеличение уставного капитала Общества путем размещения
            привилегированных акций тип второй.
            <br />
            <br />
            4. Об&nbsp;одобрении заключения Обществом дополнительного соглашения
            № 1 к&nbsp;договору займа от&nbsp;19.06.2021&nbsp;г.
            <br />
            <br />
            Акционеры Общества могут ознакомиться с&nbsp;материалами,
            подлежащими предоставлению при подготовке к&nbsp;проведению общего
            собрания акционеров Общества по&nbsp;адресу: г.&nbsp;Краснодар,
            ул.&nbsp;Стахановская, д.&nbsp;15, корп. 3, комната 2 в&nbsp;течение
            20 дней до&nbsp;даты проведения общего собрания акционеров,
            в&nbsp;рабочие дни с&nbsp;понедельника по&nbsp;пятницу с&nbsp;09
            часов 00 минут до&nbsp;17 часов 45 минут (по&nbsp;местному времени).
            <br />
            <br />
            Совет директоров&nbsp;АО «Самокарт»
            <br />
            <br />
            <a
              href="https://docs.google.com/document/d/1oO_drMcV17fzTUNxMSCLbXpYWH4eAIgF1fdDT1zhq9I/edit?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
              style={{
                borderBottom: "1px solid rgb(39, 44, 237)",
                boxShadow: "none",
                textDecoration: "none",
              }}
            >
              Бюллетень голосования № 1
            </a>
            <br />
            <br />
            <a
              href="https://docs.google.com/document/d/1oO_drMcV17fzTUNxMSCLbXpYWH4eAIgF1fdDT1zhq9I/edit?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
              style={{
                borderBottom: "1px solid rgb(39, 44, 237)",
                boxShadow: "none",
                textDecoration: "none",
              }}
            >
              Бюллетень голосования № 2
            </a>
          </div>

          <div className={"text"}>
            <p>10.08.2023</p>

            <p>
              <b>
                Отчет об итогах голосования на внеочередном общем собрании
                акционеров
                <br />
                АО «Самокарт»
              </b>
            </p>

            <p>
              Полное фирменное наименование общества: Акционерное общество
              «Самокарт» (далее именуемое Общество).
              <br />
              Место нахождения Общества: Краснодарский край, г. Краснодар.
              <br />
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, к. 3, ком. 2.
              <br />
              Вид общего собрания: внеочередное.
              <br />
              Форма проведения общего собрания: заочное голосование.
              <br />
              Дата определения (фиксации) лиц, имевших право на участие в общем
              собрании акционеров Общества: 11 июня 2023 г.
              <br />
              Дата проведения общего собрания: 04 августа 2023 г.
              <br />
              Сведения о регистраторе, выполнявшем функции счетной комиссии:
              <br />
              Полное фирменное наименование: Акционерное общество "Реестр".
              <br />
              Место нахождения: Российская Федерация, г. Москва.
              <br />
              Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
              стр. 1.
              <br />
              Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
              <br />
              Председательствующий на общем собрании акционеров: Литвиненко
              Сергей Иванович.
              <br />
              Секретарь общего собрания акционеров: Таракановская Наталья
              Сергеевна.
            </p>
            <p>
              <strong>Повестка дня собрания:</strong>
            </p>
            <ol>
              <li>
                О досрочном прекращении полномочий членов совета директоров АО
                &laquo;Самокарт&raquo;.
              </li>
              <li>
                Об избрании членов совета директоров АО &laquo;Самокарт&raquo;.
              </li>
              <li>
                Утверждение новой редакции устава АО &laquo;Самокарт&raquo;, в
                том числе в части изменения объема прав по привилегированным
                акциям первого и второго типа АО &laquo;Самокарт&raquo;.
              </li>
            </ol>
            <p>
              <strong>
                Результаты голосования и формулировки принятых решений:
              </strong>
            </p>
            <p>
              <strong>По вопросу повестки дня №1:</strong>
            </p>
            <p>
              <strong>
                Формулировка решения, поставленного на голосование:
              </strong>
            </p>
            <p>
              1. Досрочно прекратить полномочия всех членов Совета директоров АО
              &laquo;Самокарт&raquo;.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на <em>обыкновенные</em>{" "}
                      акции Общества*
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>19 426 605</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на привилегированные акции
                      Общества <em>первого типа*</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>2 794 209</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на привилегированные акции
                      Общества <em>второго типа*</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>1 369 429</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            </p>
            <p>
              <strong>Итоги голосования:&nbsp;</strong>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>19 426 605</strong>
                      <strong>/100 %**</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>2 794 209/100 </strong>
                      <strong>%***</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>1 369 429/100 % </strong>
                      <strong>****</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              * с учетом положений пункта 8.11. устава АО &laquo;Самокарт&raquo;
            </p>
            <p>
              **процент определяется от числа голосов, приходившихся на
              обыкновенные акции Общества, определенного с учетом положений
              пункта 8.11.4. устава общества
            </p>
            <p>
              ***процент определяется от числа голосов, приходившихся на
              привилегированные акции Общества первого типа, определенного с
              учетом положений пункта 8.11.2. устава общества
            </p>
            <p>
              ****процент определяется от числа голосов, приходившихся на
              привилегированные акции Общества второго типа, определенного с
              учетом положений пункта 8.11.3. устава общества
            </p>
            <p>
              <strong>Формулировка решения, принятого общим собранием:</strong>
            </p>
            <p>
              1. Досрочно прекратить полномочия всех членов Совета директоров АО
              &laquo;Самокарт&raquo;.
            </p>
            <p>
              <strong>По вопросу повестки дня №2:</strong>
            </p>
            <p>
              <strong>
                Формулировка решения, поставленного на голосование:
              </strong>
            </p>
            <p>2. Избрать членом Совета директоров Общества:</p>
            <ol>
              <li>Тимофеев Александр Сергеевич</li>
              <li>Навроцкий Гавриил Вильяминович</li>
              <li>Быков Геннадий Викторович</li>
              <li>Литвиненко Сергей Иванович</li>
              <li>Евдаков Кирилл Сергеевич</li>
            </ol>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на <em>обыкновенные</em>{" "}
                      акции Общества*
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>19 426 605</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на привилегированные акции
                      Общества <em>первого типа*</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>2 794 209</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на привилегированные акции
                      Общества <em>второго типа*</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>1 369 429</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            </p>
            <p>
              <strong>Итоги голосования: </strong>
            </p>
            <p>
              По кандидатуре <em>Тимофеева Александра Сергеевича:</em>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>19 426 605 | 100%**</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>2 794 209</strong>
                      <strong> | 100%***</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>1 369 429 | 100%****</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              По кандидатуре <em>Навроцкого Гавриила Вильяминовича:</em>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>19 426 605 | 100%**</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>2 794 209</strong>
                      <strong> | 100%***</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>1 369 429 | 100%****</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              По кандидатуре <em>Быкова Геннадия Викторовича:</em>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>19 426 605 | 100%**</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>2 794 209</strong>
                      <strong> | 100%***</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>1 369 429 | 100%****</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              По кандидатуре <em>Литвиненк</em>
              <em>о Сергея Ивановича:</em>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>13 380 634 | 68,8779%**</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>6 045 971 | 31,1221%**</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>2 794 209 | 100%***</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>1 369 429 | 100%****</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              По кандидатуре <em>Евдакова Кирилла Сергеевича:</em>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>13 380 634 | 68,8779%**</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>6 045 971</strong>
                      <strong> | 31,1221%**</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>2 794 209</strong>
                      <strong> | 100%***</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>1 369 429 | 100%****</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              * с учетом положений пункта 8.9.2. устава АО
              &laquo;Самокарт&raquo;
            </p>
            <p>
              **процент определяется от числа голосов, приходившихся на
              обыкновенные акции Общества, определенного с учетом положений
              пункта 8.9.2. устава общества
            </p>
            <p>
              ***процент определяется от числа голосов, приходившихся на
              привилегированные акции Общества первого типа, определенного с
              учетом положений пункта 8.9.2. устава общества
            </p>
            <p>
              ****процент определяется от числа голосов, приходившихся на
              привилегированные акции Общества второго типа, определенного с
              учетом положений пункта 8.9.2. устава общества
            </p>
            <p>
              <strong>Формулировка решения, принятого общим собранием:</strong>
            </p>
            <p>2. Избрать членом Совета директоров Общества:</p>
            <ol>
              <li>Тимофеев Александр Сергеевич</li>
              <li>Навроцкий Гавриил Вильяминович</li>
              <li>Быков Геннадий Викторович</li>
              <li>Литвиненко Сергей Иванович</li>
              <li>Евдаков Кирилл Сергеевич</li>
            </ol>
            <p>
              <strong>По вопросу повестки дня № 3:</strong>
            </p>
            <p>
              <strong>
                Формулировка решения, поставленного на голосование:
              </strong>
            </p>
            <p>
              3. Утвердить новую редакцию устава АО &laquo;Самокарт&raquo;, в
              том числе в части изменения объема прав по привилегированным
              акциям первого и второго типа АО &laquo;Самокарт&raquo;:
            </p>
            <p>
              А) взамен прав по привилегированным акциям первого типа АО
              &laquo;Самокарт&raquo;, изложенным в действующей редакции устава
              АО &laquo;Самокарт&raquo;, изложить в новой редакции устава АО
              &laquo;Самокарт&raquo;, что привилегированные акции АО
              &laquo;Самокарт&raquo; первого типа предоставляют их владельцам
              следующие права:
            </p>
            <p>
              - в случае ликвидации АО &laquo;Самокарт&raquo; право на получение
              начисленных, но не выплаченных дивидендов, а также на получение
              части имущества АО &laquo;Самокарт&raquo; в соответствии с
              очередностью, установленной законодательством России;
            </p>
            <p>
              - участвовать в соответствии с Федеральным законом &laquo;Об
              акционерных обществах&raquo; и Уставом АО &laquo;Самокарт&raquo; в
              общем собрании акционеров АО &laquo;Самокарт&raquo; с правом
              голоса по всем вопросам его компетенции, независимо от объявления
              и выплаты дивидендов;
            </p>
            <p>
              - в случае ликвидации АО &laquo;Самокарт&raquo; получить
              ликвидационную стоимость привилегированной акции АО
              &laquo;Самокарт&raquo; первого типа в размере её номинальной
              стоимости;
            </p>
            <p>
              - право на получение дивидендов наравне и в равном размере с
              владельцами обыкновенных акций АО &laquo;Самокарт&raquo;;
            </p>
            <p>- иметь иные права, предусмотренные Уставом.</p>
            <p>
              Б) взамен прав по привилегированным акциям второго типа АО
              &laquo;Самокарт&raquo;, изложенным в действующей редакции устава
              АО &laquo;Самокарт&raquo;, изложить в новой редакции устава АО
              &laquo;Самокарт&raquo;, что привилегированные акции АО
              &laquo;Самокарт&raquo; второго типа предоставляют их владельцам
              следующие права:&nbsp;
            </p>
            <p>
              - в случае ликвидации АО &laquo;Самокарт&raquo; право на получение
              начисленных, но не выплаченных дивидендов, а также на получение
              части имущества АО &laquo;Самокарт&raquo; в соответствии с
              очередностью, установленной законодательством России;
            </p>
            <p>
              - участвовать в соответствии с Федеральным законом &laquo;Об
              акционерных обществах&raquo; и Уставом АО &laquo;Самокарт&raquo; в
              общем собрании акционеров АО &laquo;Самокарт&raquo; с правом
              голоса по всем вопросам его компетенции, независимо от объявления
              и выплаты дивидендов;
            </p>
            <p>
              - в случае ликвидации АО &laquo;Самокарт&raquo; получить
              ликвидационную стоимость привилегированной акции АО
              &laquo;Самокарт&raquo; второго типа в размере её номинальной
              стоимости; - право на получение дивидендов наравне и в равном
              размере с владельцами обыкновенных акций АО
              &laquo;Самокарт&raquo;;
            </p>
            <p>- иметь иные права, предусмотренные Уставом.</p>
            <p>
              В) Очередность выплаты ликвидационной стоимости по каждому типу
              привилегированных акций оставить в новой редакции устава АО
              &laquo;Самокарт&raquo;, как в действующей: в первую очередь
              выплачивается ликвидационная стоимость владельцам
              привилегированных акций АО &laquo;Самокарт&raquo; первого типа, во
              вторую очередь выплачивается ликвидационная стоимость владельцам
              привилегированных акций АО &laquo;Самокарт&raquo; второго
              типа.&nbsp;
            </p>
            <p>
              Внести изменения в решение о выпуске акций АО
              &laquo;Самокарт&raquo; в части изменения объема прав по
              привилегированным акциям первого типа АО &laquo;Самокарт&raquo;.
            </p>
            <p>
              Внести изменения в решение о выпуске акций АО
              &laquo;Самокарт&raquo; в части изменения объема прав по
              привилегированным акциям второго типа АО &laquo;Самокарт&raquo;.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>Кворум по данному вопросу повестки дня имеется.</strong>
            </p>
            <p>
              <strong>Итоги голосования:&nbsp;</strong>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>23 590 243 | 100%*</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>0</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              *процент определяется от числа голосов, которыми обладали все
              акционеры &ndash; владельцы акций Общества всех категорий (типов).
            </p>
            <p>
              <strong>Формулировка решения, принятого общим собранием:</strong>
            </p>
            <p>
              3. Утвердить новую редакцию устава АО &laquo;Самокарт&raquo;, в
              том числе в части изменения объема прав по привилегированным
              акциям первого и второго типа АО &laquo;Самокарт&raquo;:
            </p>
            <p>
              А) взамен прав по привилегированным акциям первого типа АО
              &laquo;Самокарт&raquo;, изложенным в действующей редакции устава
              АО &laquo;Самокарт&raquo;, изложить в новой редакции устава АО
              &laquo;Самокарт&raquo;, что привилегированные акции АО
              &laquo;Самокарт&raquo; первого типа предоставляют их владельцам
              следующие права:
            </p>
            <p>
              - в случае ликвидации АО &laquo;Самокарт&raquo; право на получение
              начисленных, но не выплаченных дивидендов, а также на получение
              части имущества АО &laquo;Самокарт&raquo; в соответствии с
              очередностью, установленной законодательством России;
            </p>
            <p>
              - участвовать в соответствии с Федеральным законом &laquo;Об
              акционерных обществах&raquo; и Уставом АО &laquo;Самокарт&raquo; в
              общем собрании акционеров АО &laquo;Самокарт&raquo; с правом
              голоса по всем вопросам его компетенции, независимо от объявления
              и выплаты дивидендов;
            </p>
            <p>
              - в случае ликвидации АО &laquo;Самокарт&raquo; получить
              ликвидационную стоимость привилегированной акции АО
              &laquo;Самокарт&raquo; первого типа в размере её номинальной
              стоимости;
            </p>
            <p>
              - право на получение дивидендов наравне и в равном размере с
              владельцами обыкновенных акций АО &laquo;Самокарт&raquo;;
            </p>
            <p>- иметь иные права, предусмотренные Уставом.</p>
            <p>
              Б) взамен прав по привилегированным акциям второго типа АО
              &laquo;Самокарт&raquo;, изложенным в действующей редакции устава
              АО &laquo;Самокарт&raquo;, изложить в новой редакции устава АО
              &laquo;Самокарт&raquo;, что привилегированные акции АО
              &laquo;Самокарт&raquo; второго типа предоставляют их владельцам
              следующие права:&nbsp;
            </p>
            <p>
              - в случае ликвидации АО &laquo;Самокарт&raquo; право на получение
              начисленных, но не выплаченных дивидендов, а также на получение
              части имущества АО &laquo;Самокарт&raquo; в соответствии с
              очередностью, установленной законодательством России;
            </p>
            <p>
              - участвовать в соответствии с Федеральным законом &laquo;Об
              акционерных обществах&raquo; и Уставом АО &laquo;Самокарт&raquo; в
              общем собрании акционеров АО &laquo;Самокарт&raquo; с правом
              голоса по всем вопросам его компетенции, независимо от объявления
              и выплаты дивидендов;
            </p>
            <p>
              - в случае ликвидации АО &laquo;Самокарт&raquo; получить
              ликвидационную стоимость привилегированной акции АО
              &laquo;Самокарт&raquo; второго типа в размере её номинальной
              стоимости; - право на получение дивидендов наравне и в равном
              размере с владельцами обыкновенных акций АО
              &laquo;Самокарт&raquo;;
            </p>
            <p>- иметь иные права, предусмотренные Уставом.</p>
            <p>
              В) Очередность выплаты ликвидационной стоимости по каждому типу
              привилегированных акций оставить в новой редакции устава АО
              &laquo;Самокарт&raquo;, как в действующей: в первую очередь
              выплачивается ликвидационная стоимость владельцам
              привилегированных акций АО &laquo;Самокарт&raquo; первого типа, во
              вторую очередь выплачивается ликвидационная стоимость владельцам
              привилегированных акций АО &laquo;Самокарт&raquo; второго
              типа.&nbsp;
            </p>
            <p>
              Внести изменения в решение о выпуске акций АО
              &laquo;Самокарт&raquo; в части изменения объема прав по
              привилегированным акциям первого типа АО &laquo;Самокарт&raquo;.
            </p>
            <p>
              Внести изменения в решение о выпуске акций АО
              &laquo;Самокарт&raquo; в части изменения объема прав по
              привилегированным акциям второго типа АО &laquo;Самокарт&raquo;.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                Председательствующий на собрании Литвиненко Сергей Иванович
              </strong>
            </p>
            <p>
              <strong>
                Секретарь собрания Таракановская Наталья Сергеевна
              </strong>
            </p>
          </div>
          <div className="text">
            <p>23.06.2023</p>

            <p>
              <b>
                Отчет об итогах голосования на внеочередном общем собрании
                акционеров
                <br />
                Акционерного общества &laquo;Самокарт&raquo;
              </b>
            </p>

            <p>
              Полное фирменное наименование общества: Акционерное общество
              &laquo;Самокарт&raquo; (далее именуемое Общество).
              <br />
              Место нахождения Общества: Краснодарский край, г. Краснодар.
              <br />
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, к. 3, ком. 2.
              <br />
              Вид общего собрания: внеочередное.
              <br />
              Форма проведения общего собрания: <b>заочное</b> голосование.
              <br />
              Дата определения (фиксации) лиц, имевших право на участие в общем
              собрании: &laquo;26&raquo; мая 2023 г.
              <br />
              Дата проведения общего собрания: &laquo;20&raquo; июня 2023 г.
              <br />
              Почтовый адрес, по которому направлялись заполненные бюллетени для
              голосования: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, корпус 3, комната 2.
              <br />
              Дата составления протокола: &laquo;20&raquo; июня 2023 г.
              <br />
              Председательствующий на общем собрании: Литвиненко Сергей
              Иванович.
              <br />
              Секретарь общего собрания и{" "}
              <b>лицо, осуществляющее на Собрании функции счетной комиссии</b>:
              Таракановская Наталья Сергеевна.
            </p>

            <p>
              <b>Повестка дня собрания:</b>
            </p>

            <ol>
              <li>
                Отмена решения общего собрания акционеров АО
                &laquo;Самокарт&raquo; от 07.04.2023, Протокол № 07/04 от
                07.04.2023 по вопросу повестки дня &laquo; Внесение изменений в
                решение общего собрания акционеров АО &laquo;Самокарт&raquo; от
                18.03.2022, протокол от 23.03.2022 №18/03 по вопросу повестки
                дня &laquo; Увеличение уставного капитала АО
                &laquo;Самокарт&raquo; путем размещения дополнительных
                обыкновенных акций АО &laquo;Самокарт&raquo;;
              </li>
              <li>
                Отмена решения общего собрания акционеров АО
                &laquo;Самокарт&raquo; от 18.03.2022, Протокол № 18/03 от
                23.03.2022 по вопросу повестки дня &laquo;Увеличение уставного
                капитала АО &laquo;Самокарт&raquo; путем размещения
                дополнительных обыкновенных акций АО &laquo;Самокарт&raquo;.
              </li>
            </ol>

            <p>
              <b>Результаты голосования и формулировки принятых решений:</b>
            </p>

            <p>
              <b>По вопросу повестки дня № 1:</b>
            </p>

            <p>
              <b>Формулировка решения, поставленного на голосование:</b>
            </p>
            <p>
              1. Отменить решение общего собрания акционеров АО
              &laquo;Самокарт&raquo; от 07.04.2023, Протокол №07/04 от
              07.04.2023 по повестке дня &laquo;Внесение изменений в решение
              общего собрания акционеров АО &laquo;Самокарт&raquo; от
              18.03.2022, протокол от 23.03.2022 №18/03 по вопросу повестки дня
              &laquo; Увеличение уставного капитала АО &laquo;Самокарт&raquo;
              путем размещения дополнительных обыкновенных акций АО
              &laquo;Самокарт&raquo;.
            </p>

            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              <b>Кворум по данному вопросу повестки дня имеется.</b>
            </p>

            <p>
              <b>Итоги голосования:&nbsp;</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              &nbsp;&nbsp; &nbsp; &nbsp; *процент определяется от числа голосов,
              которыми обладали лица, принявшие участие в общем собрании, по
              данному вопросу повестки дня, определенное с учетом положений п.
              4.24 &laquo;Положения об общих собраниях акционеров&raquo; (утв.
              Банком России 16.11.2018 N 660-П).
            </p>

            <p>
              <b>Формулировка решения, принятого общим собранием:</b>
            </p>
            <p>
              1. Отменить решение общего собрания акционеров АО
              &laquo;Самокарт&raquo; от 07.04.2023, Протокол №07/04 от
              07.04.2023 по повестке дня &laquo;Внесение изменений в решение
              общего собрания акционеров АО &laquo;Самокарт&raquo; от
              18.03.2022, протокол от 23.03.2022 №18/03 по вопросу повестки дня
              &laquo; Увеличение уставного капитала АО &laquo;Самокарт&raquo;
              путем размещения дополнительных обыкновенных акций АО
              &laquo;Самокарт&raquo;.
            </p>

            <p>
              <b>По вопросу повестки дня № 2:</b>
            </p>

            <p>
              <b>Формулировка решения, поставленного на голосование:</b>
            </p>
            <p>
              2. Отменить решение общего собрания акционеров АО
              &laquo;Самокарт&raquo; от 18.03.2022, Протокол №18/03 от
              23.03.2022 по вопросу повестки дня &laquo; Увеличение уставного
              капитала АО &laquo;Самокарт&raquo; путем размещения дополнительных
              обыкновенных акций АО &laquo;Самокарт&raquo;.
            </p>

            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              <b>Кворум по данному вопросу повестки дня имеется.</b>
            </p>

            <p>
              <b>Итоги голосования:&nbsp;</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              &nbsp;&nbsp; &nbsp; &nbsp; *процент определяется от числа голосов,
              которыми обладали лица, принявшие участие в общем собрании, по
              данному вопросу повестки дня, определенное с учетом положений п.
              4.24 &laquo;Положения об общих собраниях акционеров&raquo; (утв.
              Банком России 16.11.2018 N 660-П).
            </p>
            <p>
              <b>Формулировка решения, принятого общим собранием:</b>
            </p>
            <p>
              2. Отменить решение общего собрания акционеров АО
              &laquo;Самокарт&raquo; от 18.03.2022, Протокол №18/03 от
              23.03.2022 по вопросу повестки дня &laquo; Увеличение уставного
              капитала АО &laquo;Самокарт&raquo; путем размещения дополнительных
              обыкновенных акций АО &laquo;Самокарт&raquo;.
            </p>
            <p>Совет директоров Общества</p>
          </div>
          <hr />

          <div className="text">
            <p>14.06.2023</p>

            <p>
              <b>
                Сообщение о проведении внеочередного Общего собрания акционеров
                АО "Самокарт"
              </b>
            </p>

            <p>
              Акционерное общество "Самокарт" (далее – Общество) сообщает
              акционерам Общества о проведении внеочередного общего собрания
              акционеров.
              <br />
              Полное фирменное наименование общества: Акционерное общество
              &laquo;Самокарт&raquo; (далее именуемое Общество).
              <br />
              Место нахождения Общества: Краснодарский край, г. Краснодар.
              <br />
              Дата окончания приема заполненных бюллетеней для голосования (дата
              проведения собрания): 04 августа 2023 года.
              <br />
              Дата, на которую определяются (фиксируются) лица, имеющие право на
              участие в общем собрании акционеров: 11 июня 2023 года.
              <br />
              Категории (типы) акций, владельцы которых имеют право голоса по
              всем вопросам повестки дня общего собрания акционеров:
              обыкновенные, привилегированные.
              <br />
              Почтовый адрес, по которому должны направляться заполненные
              бюллетени для голосования: 350051, Краснодарский край, г.
              Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2.
              <br />
              При направлении заполненных документов в общество, представителям
              акционеров необходимо приложить документы, подтверждающие их
              полномочия для осуществления голосования (их копии,
              засвидетельствованные (удостоверенные) в порядке, предусмотренном
              законодательством Российской Федерации).
              <br />
              Вид собрания: внеочередное.
              <br />
              Форма проведения собрания: Заочное голосование.
            </p>

            <p>
              <b>
                Вопросы, включенные в повестку дня внеочередного общего собрания
                акционеров Общества:
              </b>
            </p>

            <ol>
              <li>
                О досрочном прекращении полномочий членов совета директоров АО
                «Самокарт».
              </li>
              <li>Об избрании членов совета директоров АО «Самокарт».</li>
              <li>
                Утверждение новой редакции устава АО «Самокарт», в том числе в
                части изменения объема прав по привилегированным акциям первого
                и второго типа АО «Самокарт».
              </li>
            </ol>

            <p>
              Акционеры Общества могут ознакомиться с материалами, подлежащими
              предоставлению при подготовке к проведению общего собрания
              акционеров Общества по адресу: 350051, Краснодарский край, г.
              Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2. в течение 20
              дней до даты проведения общего собрания акционеров, в рабочие дни
              и часы Общества.
            </p>

            <p>
              <em>
                Наличие у акционеров права требовать выкупа Обществом
                принадлежащих им акций:
              </em>{" "}
              настоящим сообщаем о наличии у акционеров Общества права требовать
              выкупа Обществом всех или части принадлежащих им акций в случае
              принятия Собранием решения об утверждении новой редакции устава АО
              &laquo;Самокарт&raquo; в части изменения объема прав по
              привилегированным акциям первого и второго типа АО
              &laquo;Самокарт&raquo;,{" "}
              <em>
                если они голосовали против принятия такого решения либо не
                принимали участия в голосовании.{" "}
              </em>
            </p>
            <p>Цена выкупа:</p>
            <p>
              - цена выкупа одной обыкновенной акции Общества составляет 1
              (Один) рубль.
            </p>
            <p>
              - цена выкупа одной привилегированной ТИП ПЕРВЫЙ акции Общества
              составляет 1 (Один) рубль.
            </p>
            <p>
              - цена выкупа одной привилегированной ТИП ВТОРОЙ акции Общества
              составляет 1 (Один) рубль.
            </p>
            <p>
              определена Советом директоров (Протокол Совета директоров № 30/05
              от 30.05.2023 г.) по состоянию на 31.12.2022 г.&nbsp;
            </p>
            <p>
              Список акционеров, имеющих право требовать выкупа принадлежащих им
              акций, составляется на основании данных, содержащихся в списке
              лиц, имевших право на участие в Собрании.
            </p>
            <p>
              Порядок осуществления выкупа: требование о выкупе акций акционера,
              зарегистрированного в реестре акционеров Общества, или отзыв
              такого требования предъявляются Регистратору Общества -
              Акционерное общество "Реестр" (далее &ndash;
              &laquo;Регистратор&raquo;) путем направления по почте либо
              вручения под роспись документа в письменной форме, подписанного
              акционером, по адресу: 129090, г. Москва, пер. Большой Балканский,
              д. 20, стр. 1.
            </p>
            <p>
              Требование о выкупе акций акционера, зарегистрированного в реестре
              акционеров Общества, должно содержать сведения, позволяющие
              идентифицировать предъявившего его акционера, а также количество
              акций, с указанием категории (типа), выкупа которых он требует.
              Требование может быть составлено по форме приложения № 1 к
              настоящему сообщению. Со дня получения Регистратором Требования о
              выкупе акций и до дня внесения в реестр акционеров Общества записи
              о переходе прав на выкупаемые акции к Обществу или до дня
              получения отзыва акционером такого требования акционер не вправе
              распоряжаться предъявленными к выкупу акциями, в том числе
              передавать их в залог или обременять другими способами, о чем
              Регистратор без распоряжения акционера вносит запись об
              установлении такого ограничения по счету, на котором учитываются
              права на акции акционера, предъявившего такое требование.
            </p>
            <p>
              Требования акционеров о выкупе акций должны быть предъявлены либо
              отозваны не позднее 45 (Сорок пять) дней с даты принятия
              соответствующего решения Собранием, т.е. не позднее 18 сентября
              2023 г. Отзыв требования о выкупе акций допускается только в
              отношении всех предъявленных к выкупу акций Общества. Требование о
              выкупе акций акционера или его отзыв считается предъявленным
              Обществу в день его получения Регистратором.
            </p>
            <p>
              По истечении 45 (Сорок пять) дней с даты проведения Собрания,
              Общество обязано в течение 30 (Тридцать) дней выкупить акции у
              акционеров, включенных в список лиц, имеющих право требовать
              выкупа Обществом принадлежащих им акций. В случае предъявления
              требований о выкупе акций лицами, не включенными в указанный
              список, Общество не позднее 5 (Пять) рабочих дней после истечения
              45 (Сорок пять) дней с даты принятия соответствующего решения
              Собранием обязано направить отказ в удовлетворении таких
              требований.
            </p>
            <p>
              Выплата денежных средств в связи с выкупом Обществом акций лицам,
              зарегистрированным в реестре акционеров Общества, осуществляется
              путем их перечисления на банковские счета, реквизиты которых
              имеются у Регистратора или указаны в требовании. При отсутствии
              информации о реквизитах банковского счета или невозможности
              зачисления денежных средств на банковский счет по обстоятельствам,
              не зависящим от Общества, соответствующие денежные средства за
              выкупленные Обществом акции перечисляются в депозит нотариуса по
              месту нахождения Общества. Регистратор общества вносит записи о
              переходе прав на выкупаемые акции к Обществу, за исключением
              перехода прав на акции, учет прав на которые осуществляется
              номинальными держателями, на основании утвержденного Советом
              директоров Общества отчета об итогах предъявления требований
              акционеров о выкупе акций и документов, подтверждающих исполнение
              Обществом обязанности по выплате денежных средств акционерам, без
              распоряжения лица, зарегистрированного в реестре акционеров
              Общества.
            </p>
            <p>
              Общая сумма средств, направляемых Обществом на выкуп акций, не
              может превышать 10% стоимости чистых активов Общества на дату
              принятия решения Собрания. В случае, если общее количество акций,
              в отношении которых заявлены требования о выкупе, превышает
              количество акций, которое может быть выкуплено Обществом с учетом
              установленного выше ограничения, акции выкупаются у акционеров
              пропорционально заявленным требованиям.
            </p>

            <p>Совет директоров Общества</p>

            <a
              className="docx-download"
              rel="noopener noreferrer"
              target="_blank"
              href={doc1}
            >
              Приложение №1 Требование акционера о выкупе акций
            </a>
          </div>
          <hr />

          <div className="text">
            <p>29.05.2023</p>
            <p>
              <b>
                Сообщение о проведении внеочередного общего собрания акционеров
                <br />
                Акционерного общества &laquo;Самокарт&raquo;
              </b>
            </p>
            <p>
              Акционерное общество &laquo;Самокарт&raquo; (далее &ndash; АО
              &laquo;Самокарт&raquo; или Общество) сообщает акционерам Общества
              о проведении общего собрания акционеров.
              <br />
              Полное фирменное наименование Общества: Акционерное общество
              &laquo;Самокарт&raquo;.
              <br />
              Место нахождения Общества: РФ, г. Краснодар.
              <br />
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, к. 3, ком. 2.
              <br />
              Дата проведения собрания (дата окончания приема заполненных
              бюллетеней для голосования): &laquo;20&raquo; июня 2023 г.
              <br />
              Вид собрания: внеочередное
              <br />
              Форма проведения собрания: заочное голосование.
              <br />
              Дата, на которую определяются (фиксируются) лица, имеющие право на
              участие в общем собрании акционеров: &laquo;26&raquo; мая 2023 г.{" "}
              <br />
              Категории (типы) акций, владельцы которых имеют право голоса по
              всем вопросам повестки дня общего собрания акционеров:
              обыкновенные акции, привилегированные акции первого и второго
              типа.
              <br />
              Почтовый адрес, по которому должны направляться заполненные
              бюллетени для голосования: 350051, Краснодарский край, г.
              Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2.
              <br />
              При направлении заполненных документов в общество, представителям
              акционеров необходимо приложить документы, подтверждающие их
              полномочия для осуществления голосования (их копии,
              засвидетельствованные (удостоверенные) в порядке, предусмотренном
              законодательством Российской Федерации).
            </p>
            <p>
              <b>
                Вопросы, включенные в повестку дня общего собрания акционеров
                Общества:
              </b>
            </p>
            <ol>
              <li>
                Отмена решения общего собрания акционеров АО «Самокарт» от
                07.04.2023, Протокол No07/04 от 07.04.2023 по вопросу повестки
                дня «Внесение изменений в решение общего собрания акционеров АО
                «Самокарт» от 18.03.2022, протокол от 23.03.2022 No18/03 по
                вопросу повестки дня «Увеличение уставно- го капитала АО
                «Самокарт» путем размещения дополнительных обыкновенных акций АО
                «Самокарт»;
              </li>
              <li>
                Отмена решения общего собрания акционеров АО «Самокарт» от
                18.03.2022, Протокол No18/03 от 23.03.2022 по вопросу повестки
                дня «Увеличение уставного капитала АО «Самокарт» путем
                размещения дополнительных обыкновенных акций АО «Самокарт».
              </li>
            </ol>
            <p>
              Акционеры Общества могут ознакомиться с материалами, подлежащими
              предоставлению при подготовке к проведению общего собрания
              акционеров Общества по адресу: 350051, Краснодарский край, г.
              Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2 (часть), в
              рабочие дни и часы Общества.
            </p>
            <p>Совет директоров Общества</p>
          </div>
          <hr />

          <div className="text">
            <p>16.05.2023</p>

            <p>
              <b>
                ОТЧЕТ
                <br />
                об итогах голосования на годовом общем собрании акционеров
                <br />
                Акционерного общества "Самокарт".
              </b>
            </p>

            <p>
              Полное фирменное наименование общества: Акционерное общество
              «Самокарт» (далее именуемое Общество).
              <br />
              Место нахождения Общества: Краснодарский край, г. Краснодар.
              <br />
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, к. 3, ком. 2<br />
              Вид общего собрания: годовое.
              <br />
              Форма проведения общего собрания: заочное голосование.
              <br />
              Дата определения (фиксации) лиц, имевших право на участие в общем
              собрании акционеров Общества: 21 апреля 2023 г.
              <br />
              Дата проведения общего собрания: 15 мая 2023 г.
              <br />
              Сведения о регистраторе, выполнявшем функции счетной комиссии:
              <br />
              Полное фирменное наименование: Акционерное общество «Реестр».
              <br />
              Место нахождения: Российская Федерация, г. Москва.
              <br />
              Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
              стр. 1.
              <br />
              Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
              <br />
            </p>

            <p>
              <b>Повестка дня собрания:</b>
            </p>

            <ol>
              <li>
                Утверждение годового отчета, годовой бухгалтерской (финансовой)
                отчетности Общества за 2022 год.
              </li>
              <li>
                Распределение прибыли (в том числе выплата (объявление)
                дивидендов) и убытков общества по результатам 2022 отчетного
                года
              </li>
              <li>Избрание членов Совета директоров Общества.</li>
            </ol>
            <p>
              <b>Формулировка решения, поставленного на голосование:</b>
            </p>
            <p>
              1. Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
              отчетность за 2022 отчетный год.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется.</b>
            </p>
            <p>
              <b>Итоги голосования:&nbsp;</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              *процент определяется от числа голосов, которыми обладали все
              акционеры - владельцы акций Общества всех категорий (типов).
            </p>
            <p>
              <b>Формулировка решения, принятого общим собранием:</b>
            </p>
            <p>
              1. Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
              отчетность за 2022 отчетный год.
            </p>
            <p>
              <b>По вопросу повестки дня №2:</b>
            </p>
            <p>
              <b>Формулировка решения, поставленного на голосование:</b>
            </p>
            <p>
              2. Дивиденды за 2022 год по обыкновенным акциям, привилегированным
              акциям тип первый и тип второй не выплачивать, в связи с
              получением убытка по результатам деятельности АО "Самокарт" в 2022
              году.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется.</b>
            </p>
            <p>
              <b>Итоги голосования:&nbsp;</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              *процент определяется от числа голосов, которыми обладали все
              акционеры - владельцы акций Общества всех категорий (типов).
            </p>
            <p>
              <b>Формулировка решения, принятого общим собранием:</b>
            </p>
            <p>
              2. Дивиденды за 2022 год по обыкновенным акциям, привилегированным
              акциям тип первый и тип второй не выплачивать, в связи с
              получением убытка по результатам деятельности АО "Самокарт" в 2022
              году.
            </p>
            <p>
              <b>По вопросу повестки дня №3:</b>
            </p>
            <p>
              <b>Формулировка решения, поставленного на голосование:</b>
            </p>
            <p>3. Избрать членом Совета директоров Общества:</p>
            <ol>
              <li>Адамов Мурад Яхберович</li>
              <li>Кобяков Илья Сергеевич</li>
              <li>Левицкий Роман Борисович</li>
              <li>Литвиненко Сергей Иванович</li>
              <li>Остапенко Владимир Валерьевич</li>
            </ol>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>23 590 243</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на <em>обыкновенные</em>{" "}
                      акции Общества*
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>19 426 605</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на привилегированные акции
                      Общества <em>первого типа*</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>2 794 209</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на привилегированные акции
                      Общества <em>второго типа*</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>1 369 429</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется.</b>
            </p>
            <p>
              <b>Итоги голосования:&nbsp;</b>
            </p>
            <p>
              <b>По кандидатуре Адамова Мурада Яхберовича:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>обыкновенных</em> акций*,
                      отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>обыкновенных</em> акций*,
                      отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 634/68,8779 %**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>обыкновенных</em> акций*,
                      отданных за вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>6 045 971/31,1221 %**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>привилегированных</em>{" "}
                      акций* <em>первого тип</em>а, отданных за вариант
                      голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>2 794 209/100%***</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>привилегированных</em>{" "}
                      акций* <em>первого тип</em>а, отданных за вариант
                      голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>привилегированных</em>{" "}
                      акций* <em>первого тип</em>а, отданных за вариант
                      голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>привилегированных</em>{" "}
                      акций* <em>второго типа</em>, отданных за вариант
                      голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>привилегированных</em>{" "}
                      акций* <em>второго типа</em>, отданных за вариант
                      голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев <em>привилегированных</em>{" "}
                      акций* <em>второго типа</em>, отданных за вариант
                      голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>1 369 429/100%****</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>По кандидатуре Кобякова Ильи Сергеевича:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>6 045 971/31,1221 %**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 634/68,8779 %**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>2 794 209/100%***</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>1 369 429/100%****</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>По кандидатуре Левицкого Романа Борисовича:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 634/68,8779 %**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>6 045 971/31,1221 %**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>2 794 209/100%***</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>6 045 971/100%****</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>По кандидатуре Литвиненко Сергея Ивановича:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 634/68,8779 %**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>6 045 971/31,1221 %**</b>
                    </p>
                    <p>
                      <b>/</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>2 794 209/100%***</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>6 045 971/100%****</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>По кандидатуре Остапенко Владимира Валерьевича:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 634/68,8779 %**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев обыкновенных акций*, отданных за
                      вариант голосования &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>6 045 971/31,1221%**</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>2 794 209/100%***</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* первого
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, владельцев привилегированных акций* второго
                      типа, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>6 045 971/100%****</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              * с учетом положений пункта 8.9.2. устава АО
              &laquo;Самокарт&raquo;
              <br />
              **процент определяется от числа голосов, приходившихся на
              обыкновенные акции Общества, определенного с учетом положений
              пункта 8.9.2. устава общества
              <br />
              ***процент определяется от числа голосов, приходившихся на
              привилегированные акции Общества первого типа, определенного с
              учетом положений пункта 8.9.2. устава общества
              <br />
              ****процент определяется от числа голосов, приходившихся на
              привилегированные акции Общества второго типа, определенного с
              учетом положений пункта 8.9.2. устава общества
            </p>
            <p>
              <b>Формулировка решения, принятого общим собранием:</b>
            </p>
            <p>3. Избрать членом Совета директоров Общества:</p>
            <ol>
              <li>Адамов Мурад Яхберович</li>
              <li>Кобяков Илья Сергеевич</li>
              <li>Левицкий Роман Борисович</li>
              <li>Литвиненко Сергей Иванович</li>
              <li>Остапенко Владимир Валерьевич</li>
            </ol>

            <p>Совет директоров Общества</p>
          </div>
          <hr />

          <div className="text">
            <p>23.04.2023</p>

            <p>
              <b>
                Сообщение о проведении годового Общего собрания акционеров
                Акционерного общества «Самокарт»
              </b>
            </p>

            <p>
              АКЦИОНЕРНОЕ ОБЩЕСТВО "САМОКАРТ" (далее – Общество) сообщает
              акционерам Общества о проведении годового общего собрания
              акционеров.
              <br />
              Полное фирменное наименование Общества: Акционерное общество
              «Самокарт».
              <br />
              Место нахождения Общества: РФ, г. Краснодар. <br />
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, к. 3, ком. 2.
              <br />
              Дата окончания приема заполненных бюллетеней для голосования (дата
              проведения собрания): 15 мая 2023 года.
              <br />
              Дата, на которую определяются (фиксируются) лица, имеющие право на
              участие в общем собрании акционеров: 21 апреля 2023 года.
              <br />
              Категории (типы) акций, владельцы которых имеют право голоса по
              всем вопросам повестки дня общего собрания акционеров:
              обыкновенные, привилегированные.
              <br />
              Почтовый адрес, по которому должны направляться заполненные
              бюллетени для голосования: 350051, Г. КРАСНОДАР, УЛ. СТАХАНОВСКАЯ,
              Д. 15, КОРПУС 3, КОМНАТА 2.
              <br />
              При направлении заполненных документов в общество, представителям
              акционеров необходимо приложить документы, подтверждающие их
              полномочия для осуществления голосования (их копии,
              засвидетельствованные (удостоверенные) в порядке, предусмотренном
              законодательством Российской Федерации).
              <br />
              Вид собрания: годовое.
              <br />
              Форма проведения собрания: Заочное голосование.
            </p>

            <p>
              <b>
                Вопросы, включенные в повестку дня общего собрания акционеров
                Общества:
              </b>
            </p>

            <ol>
              <li>
                Утверждение годового отчета, годовой бухгалтерской (финансовой)
                отчетности Общества за 2022 год.
              </li>
              <li>
                Распределение прибыли (в том числе выплата (объявление)
                дивидендов) и убытков общества по результатам 2022 отчетного
                года.
              </li>
              <li>Избрание членов Совета директоров Общества.</li>
            </ol>
            <p>
              Акционеры Общества могут ознакомиться с материалами, подлежащими
              предоставлению при подготовке к проведению общего собрания
              акционеров Общества по адресу: Г. КРАСНОДАР, УЛ.СТАХАНОВСКАЯ, Д.
              15, КОРП.3, КОМ.2 в течение 20 дней до даты проведения общего
              собрания акционеров, в рабочие дни и часы Общества.
            </p>

            <p>Совет директоров Общества</p>
          </div>
          <hr />

          <div className="text">
            <p>17.03.2023</p>

            <p>
              <b>
                Сообщение о проведении внеочередного общего собрания акционеров
                Акционерного общества «Самокарт»
              </b>
            </p>

            <p>
              Акционерное общество «Самокарт» (далее – АО «Самокарт» или
              Общество) сообщает акционерам Общества о проведении общего
              собрания акционеров.
              <br />
              Полное фирменное наименование Общества: Акционерное общество
              «Самокарт».
              <br />
              Место нахождения Общества: РФ, г. Краснодар. <br />
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, к. 3, ком. 2.
              <br />
              Дата проведения собрания (дата окончания приема заполненных
              бюллетеней для голосования): «07» апреля 2023 г.
              <br />
              Вид собрания: внеочередное <br />
              Форма проведения собрания: заочное голосование. <br />
              Дата, на которую определяются (фиксируются) лица, имеющие право на
              участие в общем собрании акционеров: «24» марта 2023 г.
              <br />
              Категории (типы) акций, владельцы которых имеют право голоса по
              всем вопросам повестки дня общего собрания акционеров:
              обыкновенные акции, привилегированные акции первого и второго
              типа.
              <br />
              Почтовый адрес, по которому должны направляться заполненные
              бюллетени для голосования: 350051, Краснодарский край, г.
              Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2.
              <br />
              При направлении заполненных документов в общество, представителям
              акционеров необходимо приложить документы, подтверждающие их
              полномочия для осуществления голосования (их копии,
              засвидетельствованные (удостоверенные) в порядке, предусмотренном
              законодательством Российской Федерации).
            </p>

            <p>
              <b>
                Вопросы, включенные в повестку дня общего собрания акционеров
                Общества:
              </b>
            </p>

            <ol>
              <li>
                Согласие общего собрания акционеров АО «Самокарт» на заключение
                соглашения об изменении условий договора конвертируемого займа
                №2 заключенного 18.03.2022 между АО «Самокарт» (заемщик) и
                Инвестиционным товариществом «Система СмартТех» (от имени и в
                интересах Инвестиционного товарищества «Система СмартТех»
                размещаемые обыкновенные акции приобретает Управляющий товарищ –
                Общество с ограниченной ответственностью Управляющая компания
                «Система СмартТех», ОГРН 1197746727441), созданное на основании
                договора инвестиционного товарищества, удостоверенного
                нотариусом города Москвы Ралько Василием Васильевичем 12 ноября
                2020 года за реестровым номером 77/2317-н/77-2020-4-1274
                (регистрационный номер договора в реестре нотариальных действий
                единой информационной системе нотариата 172747157) (заимодавец);
              </li>
              <li>
                Согласие общего собрания акционеров АО «Самокарт» на заключение
                соглашения об изменении условий договора конвертируемого займа
                №21-22 заключенного путем внесения 18.03.2022 изменений в
                договор займа №21-22 от 01.03.2022 между АО «Самокарт» (заемщик)
                и Варлашиным Владиславом Валерьевичем (заимодавец);
              </li>
              <li>
                Внесение изменений в решение общего собрания акционеров АО
                «Самокарт» от 18.03.2022, протокол от 23.03.2022 №18/03 по
                вопросу повестки дня «Увеличение уставного капитала АО
                «Самокарт» путем размещения дополнительных обыкновенных акций АО
                «Самокарт».
              </li>
            </ol>
            <p>
              Акционеры Общества могут ознакомиться с материалами, подлежащими
              предоставлению при подготовке к проведению общего собрания
              акционеров Общества по адресу: 350051, Краснодарский край, г.
              Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2, в рабочие дни и
              часы Общества.
            </p>

            <p>Совет директоров Общества</p>
          </div>
          <hr />

          <div className="text">
            <p>20.01.2023</p>
            <p>
              <b>
                Сообщение о проведении внеочередного общего собрания акционеров
                <br />
                Акционерного общества &laquo;Самокарт&raquo;
              </b>
            </p>
            <p>
              Акционерное общество &laquo;Самокарт&raquo; (далее &ndash; АО
              &laquo;Самокарт&raquo; или Общество) сообщает акционерам Общества
              о проведении общего собрания акционеров.
              <br />
              Полное фирменное наименование Общества: Акционерное общество
              &laquo;Самокарт&raquo;.
              <br />
              Место нахождения Общества: РФ, г. Краснодар.
              <br />
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, к. 3, ком. 2.
              <br />
              Дата проведения собрания (дата окончания приема заполненных
              бюллетеней для голосования): &laquo;30&raquo; января 2023 г.
              <br />
              Вид собрания: внеочередное
              <br />
              Форма проведения собрания: заочное голосование.
              <br />
              Дата, на которую определяются (фиксируются) лица, имеющие право на
              участие в общем собрании акционеров: &laquo;24&raquo; января 2023
              г. <br />
              Категории (типы) акций, владельцы которых имеют право голоса по
              всем вопросам повестки дня общего собрания акционеров:
              обыкновенные акции, привилегированные акции первого и второго
              типа.
              <br />
              Почтовый адрес, по которому должны направляться заполненные
              бюллетени для голосования: 350051, Краснодарский край, г.
              Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2.
              <br />
              При направлении заполненных документов в общество, представителям
              акционеров необходимо приложить документы, подтверждающие их
              полномочия для осуществления голосования (их копии,
              засвидетельствованные (удостоверенные) в порядке, предусмотренном
              законодательством Российской Федерации).
            </p>
            <p>
              <b>
                Вопросы, включенные в повестку дня общего собрания акционеров
                Общества:
              </b>
            </p>
            <ol>
              <li>
                Согласие общего собрания акционеров АО &laquo;Самокарт&raquo; на
                заключение соглашения об изменении условий договора
                конвертируемого займа №2 заключенного 18.03.2022 между АО
                &laquo;Самокарт&raquo; (заемщик) и Инвестиционным товариществом
                &laquo;Система СмартТех&raquo; (от имени и в интересах
                Инвестиционного товарищества &laquo;Система СмартТех&raquo;
                размещаемые обыкновенные акции приобретает Управляющий товарищ
                &ndash; Общество с ограниченной ответственностью Управляющая
                компания &laquo;Система СмартТех&raquo;, ОГРН 1197746727441),
                созданное на основании договора инвестиционного товарищества,
                удостоверенного нотариусом города Москвы Ралько Василием
                Васильевичем 12 ноября 2020 года за реестровым номером
                77/2317-н/77-2020-4-1274 (регистрационный номер договора в
                реестре нотариальных действий единой информационной системе
                нотариата 172747157) (заимодавец);
              </li>
              <li>
                Согласие общего собрания акционеров АО &laquo;Самокарт&raquo; на
                заключение соглашения об изменении условий договора
                конвертируемого займа №21-22 заключенного путем внесения
                18.03.2022 изменений в договор займа №21-22 от 01.03.2022 между
                АО &laquo;Самокарт&raquo; (заемщик) и Варлашиным Владиславом
                Валерьевичем (заимодавец);
              </li>
              <li>
                Внесение изменений в решение общего собрания акционеров АО
                &laquo;Самокарт&raquo; от 18.03.2022, протокол от 23.03.2022
                №18/03 по вопросу повестки дня &laquo;Увеличение уставного
                капитала АО &laquo;Самокарт&raquo; путем размещения
                дополнительных обыкновенных акций АО &laquo;Самокарт&raquo;.
              </li>
            </ol>
            <p>
              Акционеры Общества могут ознакомиться с материалами, подлежащими
              предоставлению при подготовке к проведению общего собрания
              акционеров Общества по адресу: 350051, Краснодарский край, г.
              Краснодар, ул. Стахановская, д. 15, к. 3, ком. 2 (часть), в
              рабочие дни и часы Общества.
            </p>
            <p>Совет директоров Общества</p>
          </div>
          <hr />

          <div className="text">
            <p>06.07.2022</p>
            <br />
            <b>
              Сообщение о проведении внеочередного Общего собрания акционеров АО
              "САМОКАРТ"
            </b>
            <br />
            АО "Самокарт" (далее – Общество) сообщает акционерам Общества о
            проведении внеочередного общего собрания акционеров.
            <br />
            Полное фирменное наименование Общества: Акционерное общество
            "Самокарт".
            <br />
            Место нахождения Общества: Краснодарский край, г. Краснодар.
            <br />
            Дата окончания приема заполненных бюллетеней для голосования (дата
            проведения собрания): 25 июля 2022 года.
            <br />
            Дата, на которую определяются (фиксируются) лица, имеющие право на
            участие в общем собрании акционеров: 08 июля 2022 года.
            <br />
            Категории (типы) акций, владельцы которых имеют право голоса по всем
            вопросам повестки дня общего собрания акционеров: обыкновенные.
            <br />
            Почтовый адрес, по которому должны направляться заполненные
            бюллетени для голосования: 350051, Краснодарский край, г. Краснодар,
            ул. Стахановская, д. 15, корп. 3, комн. 2.
            <br />
            При направлении заполненных документов в общество, представителям
            акционеров необходимо приложить документы, подтверждающие их
            полномочия для осуществления голосования (их копии,
            засвидетельствованные (удостоверенные) в порядке, предусмотренном
            законодательством Российской Федерации).
            <br />
            Вид собрания: внеочередное
            <br />
            Форма проведения собрания: Заочное голосование.
            <br />
            <br />
            <b>
              Вопросы, включенные в повестку дня годового общего собрания
              акционеров Общества:
            </b>
            <br />
            <br />
            1. Принятие решения о согласии на совершение сделки - заключение
            Обществом договора конвертируемого займа с ООО "Кэпитал Перформ".
            <br />
            2. Принятие решения о согласии на совершение сделки - заключение
            Обществом договора конвертируемого займа с Варлашиным В.В.,
            Литвиненко С.И., Левицким Е.Р., Остапенко В.В., Евдаковым К.С.
            <br />
            3. Увеличение уставного капитала Общества путем размещения
            дополнительных обыкновенных акций Общества.
            <br />
            4. Принятие решения о согласии на совершение сделки в совершении
            которой имеется заинтересованность - одобрение заключения Обществом
            договора конвертируемого займа с Варлашиным В.В., Литвиненко С.И.,
            Левицким Е.Р., Остапенко В.В., Евдаковым К.С.
            <br />
            5. Принятие решения о согласии на совершение сделки в совершении
            которой имеется заинтересованность - одобрение заключения Обществом
            дополнительного соглашения №5 к договору об осуществлении прав
            акционеров Общества, заключенному 19 июня 2021 года.
            <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: г. Краснодар, ул. Стахановская, д.
            15, корп. 3, комн. 2, в рабочие дни и часы Общества.
            <br />
            <br />
            Совет директоров АО "Самокарт"
            <br />
            <br />
            <a href="/Bulletin_25.07.2022.docx">Бюллетень голосования</a>
            <br />
            <br />
          </div>
          <hr />

          <div className="text">
            <p>03.06.2022</p>
            <br />
            <b>
              ОТЧЕТ об итогах голосования на годовом общем собрании акционеров
              Акционерного общества "Самокарт".
            </b>
            <br />
            Полное фирменное наименование общества: Акционерное общество
            «Самокарт» (далее именуемое Общество).
            <br />
            Место нахождения Общества: Краснодарский край, г. Краснодар.
            <br />
            Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, корпус 3, комната 2.
            <br />
            Вид общего собрания: годовое.
            <br />
            Форма проведения общего собрания: заочное голосование.
            <br />
            Дата определения (фиксации) лиц, имевших право на участие в общем
            собрании акционеров Общества: «09» мая 2022 г.
            <br />
            Дата проведения общего собрания: «31» мая 2022 г.
            <br />
            Сведения о регистраторе, выполнявшем функции счетной комиссии:
            <br />
            Полное фирменное наименование: Акционерное общество "Реестр".
            <br />
            Место нахождения: Российская Федерация, г. Москва.
            <br />
            Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
            стр. 1.
            <br />
            Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
            <br />
            <br />
            <br />
            <b>ПОВЕСТКА ДНЯ СОБРАНИЯ:</b>
            <br />
            1. Утверждение годового отчета, годовой бухгалтерской (финансовой)
            отчетности Общества за 2021 год.
            <br />
            2. Распределение прибыли (в том числе выплата (объявление)
            дивидендов) и убытков общества по результатам 2021 отчетного года.
            <br />
            3. Избрание членов Совета директоров Общества.
            <br />
            4. Утверждение аудитора Общества.
            <br />
            5. Об изменении места нахождения общества и внесение изменений в
            устав.
            <br />
            6. Принятие решения о согласии на совершение сделки - заключение
            Обществом договора конвертируемого займа путем внесения изменений в
            договор займа № 22-22 от 14.04.2021 г.
            <br />
            7. Принятие решения о согласии на совершение сделки в совершении
            которой имеется заинтересованность - заключение Обществом
            дополнительного соглашения № 4 к договору об осуществлении прав
            акционеров, заключенного 19.06.2021 г.
            <br />
            <br />
            <br />
            <b>Результаты голосования и формулировки принятых решений:</b>
            <br />
            <b>По вопросу повестки дня №1:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
            отчетность за 2021 отчетный год
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
            отчетность за 2021 отчетный год.
            <br />
            <br />
            <b>По вопросу повестки дня №2:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            Дивиденды за 2021 год по обыкновенным акциям общества не
            выплачивать, в связи с получением убытка по результатам деятельности
            АО "Самокарт" в 2021 году.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            Дивиденды за 2021 год по обыкновенным акциям общества не
            выплачивать, в связи с получением убытка по результатам деятельности
            АО "Самокарт" в 2021 году.
            <br />
            <br />
            <b>По вопросу повестки дня №3:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            Избрать членом Совета директоров Общества: <br />
            1. Караашев Лаэрт Григорьевич <br />
            2. Кобяков Илья Сергеевич <br />
            3. Левицкий Роман Борисович <br />
            4. Литвиненко Сергей Иванович <br />
            5. Остапенко Владимир Валерьевич <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>62 789 000</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>62 789 000</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>62 789 000</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Голосование кумулятивное. 5 вакансий. Кворум по данному вопросу
            повестки дня имеется.
            <br />
            Итоги голосования: Число голосов «ЗА», распределенных среди
            кандидатов:
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Ф.И.О. кандидата</p>
                  </td>
                  <td>
                    <p>
                      <b>Число голосов</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Караашев Лаэрт Григорьевич</p>
                  </td>
                  <td>
                    <p>
                      <b> 12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Кобяков Илья Сергеевич</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Левицкий Роман Борисович</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Литвиненко Сергей Иванович</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Остапенко Владимир Валерьевич</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ в
                      отношении всех кандидатов»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ в отношении всех кандидатов»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            Избрать членом Совета директоров Общества: <br />
            1. Караашев Лаэрт Григорьевич <br />
            2. Кобяков Илья Сергеевич <br />
            3. Левицкий Роман Борисович <br />
            4. Литвиненко Сергей Иванович <br />
            5. Остапенко Владимир Валерьевич <br />
            <br />
            <b>По вопросу повестки дня №4:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            Утвердить аудитором Общества на 2022 год ООО "Гик Аудит" (ИНН
            2310181350).
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            <b>Решение не принято.</b>
            <br />
            <br />
            <b>По вопросу повестки дня №5:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            Изменить место нахождения общества на г. Москва и определить новым
            адресом общества следующий: г. Москва, ул. Большая Новодмитровская,
            д. 36, стр. 3, пом. 1. <br />
            Внести в устав изменения в связи со сменой места нахождения общества
            и утвердить устав общества в новой редакции. Поручить генеральному
            директору совершить все (любые) юридические и иные действия,
            необходимые для внесения в ЕГРЮЛ сведений о принятии решения об
            изменении адреса общества, влекущем изменение его местонахождения
            (изменений в сведениях об адресе общества в ЕГРЮЛ), а также
            зарегистрировать устав общества в новой редакции в порядке и сроки,
            установленные законодательством.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            Изменить место нахождения общества на г. Москва и определить новым
            адресом общества следующий: г. Москва, ул. Большая Новодмитровская,
            д. 36, стр. 3, пом. 1. <br />
            Внести в устав изменения в связи со сменой места нахождения общества
            и утвердить устав общества в новой редакции. Поручить генеральному
            директору совершить все (любые) юридические и иные действия,
            необходимые для внесения в ЕГРЮЛ сведений о принятии решения об
            изменении адреса общества, влекущем изменение его местонахождения
            (изменений в сведениях об адресе общества в ЕГРЮЛ), а также
            зарегистрировать устав общества в новой редакции в порядке и сроки,
            установленные законодательством.
            <br />
            <br />
            <b>По вопросу повестки дня №6:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            В соответствии с п. 7 ст. 32.3 Федерального закона от 26 декабря
            1995 № 208-ФЗ "Об акционерных обществах" дать предварительное
            согласие на заключение Обществом договора конвертируемого займа
            путем внесения изменений в договор займа № 22-22 от 14.04.2021 г.
            между Обществом и Варлашиным Владиславом Валерьевичем (далее –
            "ДКЗ") на следующих условиях: <br />
            1.1. Стороны ДКЗ: Общество (заемщик), Варлашин Владислав Валерьевич,
            ИНН 231204904297 (далее – "Заимодавец"). <br />
            1.2. Заимодавец обязуется предоставить Обществу заем в размере 30
            000 000 (тридцать миллионов) рублей 00 копеек (далее – "Заем"). Заем
            может быть возвращен (i) путем конвертации суммы в размере 29 965
            591 (двадцать девять миллионов девятьсот шестьдесят пять тысяч
            пятьсот девяносто один) рубль 45 копеек в обыкновенные акции
            Общества (далее – "Обыкновенные акции") и путем возврата суммы в
            размере 34 408 (тридцать четыре тысячи четыреста восемь) рублей 55
            копеек в денежной форме в либо (i) путем возврата всей суммы Займа в
            денежной форме. <br />
            1.3. Срок и (или) иные обстоятельства, при наступлении которых
            Заимодавец вправе предъявить требование о размещении ему
            Обыкновенных акций (далее – "Требование"): истечение 6 (шесть)
            месяцев с даты зачисления суммы Займа в полном объеме на расчетный
            счет Общества. <br />
            1.4. Заимодавец вправе требовать размещение ему 3 512 965 (три
            миллиона пятьсот двенадцать тысяч девятьсот шестьдесят пять)
            Обыкновенных акций. <br />
            1.5. Цена размещения дополнительных Обыкновенных акций во исполнение
            ДКЗ составляет 8 (восемь) рублей 53 копейки за одну Обыкновенную
            акцию. <br />
            1.6. Сумма Займа должна быть возвращена Заимодавцу не позднее даты
            истечения 1 (один) года с даты заключения ДКЗ (далее – "Срока
            погашения"). <br />
            1.7. Сумма Займа подлежит возврату в денежной форме в случае, если
            (i) Обыкновенные акции не были размещены в соответствии с
            Требованием в течение Срока погашения, или (ii) Заимодавец направил
            требование о возврате Займа в денежной форме в связи с Событием
            дефолта (как определено в форме ДКЗ, приведенной в приложении [1] к
            Протоколу (далее – "Форма ДКЗ")). <br />
            1.8. Проценты на сумму Займа не начисляются. <br />
            Иные условия ДКЗ указаны в Форме ДКЗ.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            В соответствии с п. 7 ст. 32.3 Федерального закона от 26 декабря
            1995 № 208-ФЗ "Об акционерных обществах" дать предварительное
            согласие на заключение Обществом договора конвертируемого займа
            путем внесения изменений в договор займа № 22-22 от 14.04.2021 г.
            между Обществом и Варлашиным Владиславом Валерьевичем (далее –
            "ДКЗ") на следующих условиях: <br />
            1.1. Стороны ДКЗ: Общество (заемщик), Варлашин Владислав Валерьевич,
            ИНН 231204904297 (далее – "Заимодавец"). <br />
            1.2. Заимодавец обязуется предоставить Обществу заем в размере 30
            000 000 (тридцать миллионов) рублей 00 копеек (далее – "Заем"). Заем
            может быть возвращен (i) путем конвертации суммы в размере 29 965
            591 (двадцать девять миллионов девятьсот шестьдесят пять тысяч
            пятьсот девяносто один) рубль 45 копеек в обыкновенные акции
            Общества (далее – "Обыкновенные акции") и путем возврата суммы в
            размере 34 408 (тридцать четыре тысячи четыреста восемь) рублей 55
            копеек в денежной форме в либо (i) путем возврата всей суммы Займа в
            денежной форме. <br />
            1.3. Срок и (или) иные обстоятельства, при наступлении которых
            Заимодавец вправе предъявить требование о размещении ему
            Обыкновенных акций (далее – "Требование"): истечение 6 (шесть)
            месяцев с даты зачисления суммы Займа в полном объеме на расчетный
            счет Общества. <br />
            1.4. Заимодавец вправе требовать размещение ему 3 512 965 (три
            миллиона пятьсот двенадцать тысяч девятьсот шестьдесят пять)
            Обыкновенных акций. <br />
            1.5. Цена размещения дополнительных Обыкновенных акций во исполнение
            ДКЗ составляет 8 (восемь) рублей 53 копейки за одну Обыкновенную
            акцию. <br />
            1.6. Сумма Займа должна быть возвращена Заимодавцу не позднее даты
            истечения 1 (один) года с даты заключения ДКЗ (далее – "Срока
            погашения"). <br />
            1.7. Сумма Займа подлежит возврату в денежной форме в случае, если
            (i) Обыкновенные акции не были размещены в соответствии с
            Требованием в течение Срока погашения, или (ii) Заимодавец направил
            требование о возврате Займа в денежной форме в связи с Событием
            дефолта (как определено в форме ДКЗ, приведенной в приложении [1] к
            Протоколу (далее – "Форма ДКЗ")). <br />
            1.8. Проценты на сумму Займа не начисляются. <br />
            Иные условия ДКЗ указаны в Форме ДКЗ.
            <br />
            <br />
            <b>По вопросу повестки дня №7:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            Дать согласие на совершение сделки, в совершении которой имеется
            заинтересованность - одобрить заключение Обществом дополнительного
            соглашения №4 (далее – "Дополнительное соглашение") к договору об
            осуществлении прав акционеров Общества, заключенному 19 июня 2021
            года, с учетом дополнительного соглашения № 1 к нему от 29 октября
            2021 года, дополнительного соглашения № 2 к нему от 24 января 2022
            года, дополнительного соглашения №3 к нему от 18 марта 2022 года по
            форме, приведенной в приложении [2] к Протоколу, как сделки, в
            совершении которой имеется заинтересованность. <br />
            Стороны Дополнительного соглашения: Общество, Заимодавец, Общество с
            ограниченной ответственностью Управляющая компания "Система
            СмартТех" (ИНН 7708367753), выступающее в качестве Управляющего
            Товарища Инвестиционного товарищества "Система СмартТех", созданного
            на основании договора инвестиционного товарищества, удостоверенного
            нотариусом города Москвы Ралько Василием Васильевичем 12 ноября 2020
            года в реестре №77/2317-н/77-2020-4-1274 (регистрационный номер
            договора в Единой Информационной Системе Нотариата 172747157),
            Акционерное общество "ТИЛТЕХ" (ИНН 9729027864), действующее в
            качестве управляющего товарища Инвестиционного товарищества "ТилТех
            Капитал" на основании договора инвестиционного товарищества,
            удостоверенного нотариусом города Москвы Горбатенко Николаем
            Владимировичем 11 ноября 2016 года за реестровым номером 2-2758
            (регистрационный номер договора в реестре нотариальных действий
            единой информационной системе нотариата 262942247), Евдаков Кирилл
            Сергеевич, Остапенко Владимир Валерьевич, Левицкий Ефим Романович,
            Литвиненко Сергей Иванович, Горный Александр Аркадьевич. <br />
            Условия Дополнительного соглашения определяются в соответствии с
            формой Дополнительного соглашения, приведенной в приложении [2] к
            Протоколу. <br />
            Основания признания заключения Обществом Дополнительного соглашения
            сделкой, в совершении которой имеется заинтересованность: (i)
            Евдаков Кирилл Сергеевич одновременно является стороной
            Дополнительного соглашения и действует в качестве единоличного
            исполнительного органа Общества, (ii) [Остапенко Владимир
            Валерьевич, Левицкий Ефим Романович, Литвиненко Сергей Иванович
            одновременно являются сторонами сделки и членами совета директоров
            Общества].
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>3 914 391</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>3 914 391</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>3 914 391 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            Формулировка решения, принятого общим собранием:
            <br />
            Дать согласие на совершение сделки, в совершении которой имеется
            заинтересованность - одобрить заключение Обществом дополнительного
            соглашения №4 (далее – "Дополнительное соглашение") к договору об
            осуществлении прав акционеров Общества, заключенному 19 июня 2021
            года, с учетом дополнительного соглашения № 1 к нему от 29 октября
            2021 года, дополнительного соглашения № 2 к нему от 24 января 2022
            года, дополнительного соглашения №3 к нему от 18 марта 2022 года по
            форме, приведенной в приложении [2] к Протоколу, как сделки, в
            совершении которой имеется заинтересованность. <br />
            Стороны Дополнительного соглашения: Общество, Заимодавец, Общество с
            ограниченной ответственностью Управляющая компания "Система
            СмартТех" (ИНН 7708367753), выступающее в качестве Управляющего
            Товарища Инвестиционного товарищества "Система СмартТех", созданного
            на основании договора инвестиционного товарищества, удостоверенного
            нотариусом города Москвы Ралько Василием Васильевичем 12 ноября 2020
            года в реестре №77/2317-н/77-2020-4-1274 (регистрационный номер
            договора в Единой Информационной Системе Нотариата 172747157),
            Акционерное общество "ТИЛТЕХ" (ИНН 9729027864), действующее в
            качестве управляющего товарища Инвестиционного товарищества "ТилТех
            Капитал" на основании договора инвестиционного товарищества,
            удостоверенного нотариусом города Москвы Горбатенко Николаем
            Владимировичем 11 ноября 2016 года за реестровым номером 2-2758
            (регистрационный номер договора в реестре нотариальных действий
            единой информационной системе нотариата 262942247), Евдаков Кирилл
            Сергеевич, Остапенко Владимир Валерьевич, Левицкий Ефим Романович,
            Литвиненко Сергей Иванович, Горный Александр Аркадьевич. <br />
            Условия Дополнительного соглашения определяются в соответствии с
            формой Дополнительного соглашения, приведенной в приложении [2] к
            Протоколу. <br />
            Основания признания заключения Обществом Дополнительного соглашения
            сделкой, в совершении которой имеется заинтересованность: (i)
            Евдаков Кирилл Сергеевич одновременно является стороной
            Дополнительного соглашения и действует в качестве единоличного
            исполнительного органа Общества, (ii) [Остапенко Владимир
            Валерьевич, Левицкий Ефим Романович, Литвиненко Сергей Иванович
            одновременно являются сторонами сделки и членами совета директоров
            Общества].
            <br />
            <br />
            Председательствующий на общем собрании Литвиненко Сергей Иванович{" "}
            <br />
            Секретарь общего собрания Левицкий Ефим Романович
            <br />
            <br />
          </div>
          <hr />

          <div className="text">
            <p>10.05.2022 </p>
            <br />
            <b>
              Сообщение
              <br />
              о проведении годового Общего собрания акционеров
              <br />
              АО "САМОКАРТ"
              <br />
              <br />
            </b>
            АО "Самокарт" (далее – Общество) сообщает акционерам Общества о
            проведении годового общего собрания акционеров.
            <br />
            Полное фирменное наименование Общества: Акционерное общество
            "Самокарт".
            <br />
            Место нахождения Общества: Краснодарский край, г. Краснодар.
            <br />
            Дата окончания приема заполненных бюллетеней для голосования (дата
            проведения собрания): 31 мая 2022 года.
            <br />
            Дата, на которую определяются (фиксируются) лица, имеющие право на
            участие в общем собрании акционеров: 09 мая 2022 года.
            <br />
            Категории (типы) акций, владельцы которых имеют право голоса по всем
            вопросам повестки дня общего собрания акционеров: обыкновенные.
            <br />
            Почтовый адрес, по которому должны направляться заполненные
            бюллетени для голосования: 350051, Краснодарский край, г. Краснодар,
            ул. Стахановская, д. 15, корп. 3, комн. 2.
            <br />
            При направлении заполненных документов в общество, представителям
            акционеров необходимо приложить документы, подтверждающие их
            полномочия для осуществления голосования (их копии,
            засвидетельствованные (удостоверенные) в порядке, предусмотренном
            законодательством Российской Федерации).
            <br />
            Вид собрания: годовое.
            <br />
            Форма проведения собрания: Заочное голосование.
            <br />
            <br />
            1. Утверждение годового отчета, годовой бухгалтерской (финансовой)
            отчетности Общества за 2021 год.
            <br />
            2. Распределение прибыли (в том числе выплата (объявление)
            дивидендов) и убытков общества по результатам 2021 отчетного года.
            <br />
            3. Избрание членов Совета директоров Общества.
            <br />
            4. Утверждение аудитора Общества.
            <br />
            5. Об изменении места нахождения общества и внесение изменений в
            устав.
            <br />
            6. Принятие решения о согласии на совершение сделки - заключение
            Обществом договора конвертируемого займа путем внесения изменений в
            договор займа № 22-22 от 14.04.2021 г. <br />
            7. Принятие решения о согласии на совершение сделки в совершении
            которой имеется заинтересованность - заключение Обществом
            дополнительного соглашения № 4 к договору об осуществлении прав
            акционеров, заключенного 19.06.2021 г.
            <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: г. Краснодар, ул. Стахановская, д.
            15, корп. 3, комн. 2 в течение 20 дней до даты проведения общего
            собрания акционеров, в рабочие дни и часы Общества.
            <br />
            <br />
            <a href="/2022_35186_Bulletin.doc">Бюллетень голосования</a>
            <br />
            <br />
          </div>
          <hr />

          <div className="text">
            <p>25.04.2022</p>
            <br />
            <b>
              Сообщение о дате, до которой от акционеров будут приниматься
              предложения о внесении вопросов в повестку дня годового общего
              собрания акционеров и предложения о выдвижении кандидатов в Совет
              директоров и иные органы Акционерного общества «Самокарт».
            </b>
            <br />
            <br />
            Полное фирменное наименование Общества: Акционерное общество
            «Самокарт».
            <br />
            Место нахождения Общества: Краснодарский край, г. Краснодар.
            <br />
            Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, корп. 3, комн. 2.
            <br />
            Акционерное общество «Самокарт» (далее – АО «Самокарт» или Общество)
            сообщает акционерам Общества, что в соответствии с п. 3 ч. 1 ст. 17
            Федерального закона от 08.03.2022 № 46-ФЗ "О внесении изменений в
            отдельные законодательные акты Российской Федерации" и решением
            Совета директоров Общества от 25 апреля 2022 г. (Протокол № 25/04 от
            25 апреля 2022 г.) акционеры (акционер), являющиеся в совокупности
            владельцами не менее чем 2 процентов голосующих акций Общества,
            вправе до 04 мая 2022 г. включительно внести вопросы в повестку дня
            годового общего собрания акционеров, которое состоится 31 мая 2022
            года, и выдвинуть кандидатов в совет директоров Общества, число
            которых не может превышать количественный состав соответствующего
            органа (далее – Предложения).
            <br />
            Указанные предложения должны поступить в Общество не позднее чем за
            27 дней до даты проведения в 2022 году годового общего собрания
            акционеров.
            <br />
            Акционеры (акционер), являющиеся в совокупности владельцами не менее
            чем 2 процентов голосующих акций Общества, вправе вносить
            Предложения в дополнение к таким Предложениям, ранее поступившим в
            Общество, а акционеры, от которых указанные Предложения поступили
            ранее, вправе вносить новые Предложения взамен поступивших. В случае
            внесения акционерами новых Предложений ранее поступившие от них
            Предложения считаются отозванными.
            <br />
            Направляемые Предложения должны соответствовать требованиям,
            установленным ст. 53 Федерального закона от 26.12.1995 N 208-ФЗ
            (ред. от 25.02.2022) "Об акционерных обществах", гл. 2 Положения
            Банка России от 16.11.2018 N 660-П "Об общих собраниях акционеров",
            а также требованиям Устава и внутренних документов Общества.
            <br />
            При направлении Предложений в Общество представителям акционеров
            необходимо приложить документы, подтверждающие их полномочия для
            направления предложения (их копии, засвидетельствованные
            (удостоверенные) в порядке, предусмотренном законодательством
            Российской Федерации).
            <br />
            <b>Совет директоров АО «Самокарт»</b>
            <br />
            <br />
          </div>
          <hr />

          <div className="text">
            <p>23.03.2022 </p>
            <br />
            <b>
              ПРОТОКОЛ № 18/03
              <br />
              внеочередного общего собрания акционеров
              <br />
              АО «Самокарт»
              <br />
            </b>
            <br />
            <br />
            Полное фирменное наименование общества: Акционерное общество
            «Самокарт» (далее именуемое Общество).
            <br />
            Место нахождения Общества: Краснодарский край, г. Краснодар.
            <br />
            Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, корпус 3, комната 2.
            <br />
            Вид общего собрания: внеочередное.
            <br />
            Форма проведения общего собрания: заочное голосование.
            <br />
            Дата определения (фиксации) лиц, имевших право на участие в общем
            собрании акционеров Общества: 26 февраля 2022 г.
            <br />
            Дата проведения общего собрания: 18 марта 2022 г.
            <br />
            Сведения о регистраторе, выполнявшем функции счетной комиссии:
            <br />
            Полное фирменное наименование: Акционерное общество "Реестр".
            <br />
            Место нахождения: Российская Федерация, г. Москва.
            <br />
            Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
            стр. 1.
            <br />
            Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
            <br />
            Почтовый адрес, по которому направлялись заполненные бюллетени для
            голосования: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, корпус 3, комната 2.
            <br />
            Дата составления протокола: 23 марта 2022 г.
            <br />
            Председательствующий на общем собрании акционеров: Литвиненко Сергей
            Иванович
            <br />
            Секретарь общего собрания акционеров: Левицкий Ефим Романович <br />
            <br />
            <br />
            <b>ПОВЕСТКА ДНЯ СОБРАНИЯ:</b>
            <br />
            <br />
            1. Утверждение Устава Общества в новой редакции.
            <br />
            2. Принятие решения о согласии на совершение сделки - заключение
            Обществом договора конвертируемого займа.
            <br />
            3. Принятие решения о согласии на совершение сделки - заключение
            Обществом договора конвертируемого займа с Варлашиным В.В.
            <br />
            4. Увеличение уставного капитала Общества путем размещения
            дополнительных обыкновенных акций Общества.
            <br />
            5. Принятие решения о согласии на совершение сделки в совершении
            которой имеется заинтересованность- одобрение заключения Обществом
            дополнительного соглашения №3 к договору об осуществлении прав
            акционеров Общества, заключенному 19 июня 2021 года.
            <br />
            <br />
            <b>Результаты голосования и формулировки принятых решений:</b>
            <br />
            <b>По вопросу повестки дня №1:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            1. Утвердить Устав Общества в новой редакции.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            1. Утвердить Устав Общества в новой редакции.
            <br />
            <br />
            <b>По вопросу повестки дня №2:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            2. В соответствии с п. 7 ст. 32.3 Федерального закона от 26 декабря
            1995 № 208-ФЗ "Об акционерных обществах" дать предварительное
            согласие на заключение Обществом договора конвертируемого займа
            (далее – "ДКЗ 1") на следующих условиях: <br />
            1.1. Стороны ДКЗ 1: Общество (заемщик), Общество с ограниченной
            ответственностью Управляющая компания "Система СмартТех", ИНН
            7708367753, выступающее в качестве управляющего товарища
            Инвестиционного товарищества "Система СмартТех", созданного на
            основании договора инвестиционного товарищества, удостоверенного
            нотариусом города Москвы Ралько Василием Васильевичем 12 ноября 2020
            года в реестре №77/2317н/77-2020-4-1274 (регистрационный номер
            договора в Единой Информационной Системе Нотариата 172747157), место
            нахождения нотариуса по адресу: 108812, г. Москва, 22км Киевское
            шоссе, д.6, стр. 1 (далее – "Заимодавец1").
            <br />
            1.2. Заимодавец 1 обязуется предоставить Обществу заем в размере 10
            000 000 (десять миллионов) рублей 00 копеек (далее – "Заем 1").
            <br />
            1.3. Срок и (или) иные обстоятельства, при наступлении которых
            Заимодавец 1 вправе предъявить требование о размещении ему
            обыкновенных акций Общества (далее – "Обыкновенные акции") (далее –
            "Требование 1"): (i) получение Заимодавцем 1 Оферты о смене контроля
            (как определено в форме ДКЗ 1, приведенной в Приложении 1 к
            Протоколу (далее – "Форма ДКЗ 1"), (ii) увеличение уставного
            капитала Общества путем размещения им акций в пользу третьего лица,
            не являющегося акционером Общества на дату заключения ДКЗ 1 (за
            исключением случая, предусмотренного пунктом (iii) настоящего пункта
            далее), (iii) увеличение уставного капитала Общества путем
            размещения им акций в пользу Публичного акционерного общества
            "Акционерная Финансовая Корпорация "Система", юридического лица,
            учрежденного в соответствии с законодательством Российской
            Федерации, ОГРН 1027700003891 (далее – "Инвестор") и (iv) получение
            Заимодавцем 1 Оферты об общем праве присоединения (как определено в
            Форме ДКЗ 1).
            <br />
            1.4. Заимодавец 1 вправе требовать размещение ему Обыкновенных акций
            в количестве, определяемом в соответствии с Приложением 1 к Форме
            ДКЗ 1.
            <br />
            1.5. порядок определения цены размещения дополнительных Обыкновенных
            акций во исполнение ДКЗ 1:
            <br />
            1.5.1. в случае получения Заимодавцем 1 Оферты о смене контроля
            и/или Оферты об общем праве присоединения- 46 (сорок шесть) рублей
            53 копейки за одну Обыкновенную акцию, 1.5.2. в случае увеличения
            уставного капитала Общества путем размещения им акций в пользу
            третьего лица, не являющегося акционером Общества на дату заключения
            ДКЗ 1 и/или увеличения уставного капитала Общества путем размещения
            им акций в пользу Инвестора цена размещения одной Обыкновенной акции
            определяется по следующей формуле: P= CL/K, где:
            <br />
            P - цена размещения одной Обыкновенной акции; <br />
            K – количество Обыкновенных акций, которые должны быть выпущены
            Заимодавцу 1 в результате конвертации в соответствии с условиями ДКЗ
            1 (в случае получения дробного числа, округленное по правилам
            математического округления); CL – размер Займа 1. <br />
            1.6. Заем 1 предоставляются Обществу на срок до 28 марта 2023 года.{" "}
            <br />
            1.7. Проценты за пользование Займом 1 в размере 100% (сто процентов)
            от суммы Займа 1 начисляются: (i) в случае, предусмотренном в пункте
            5.2.1.2 Формы ДКЗ 1, (ii) в случае наступления Ликвидационного
            события (как определено в Форме ДКЗ 1) (если до наступления
            Ликвидационного события в пользу Заимодавца 1 не были размещены
            Конвертируемые акции (как определено в Форме ДКЗ 1)), (iii) в
            случае, если в пользу Заимодавца 1 не были размещены Конвертируемые
            акции в течение срока размещения Обыкновенных акций, определенного
            ДКЗ 1, во исполнение ДКЗ 1 по обстоятельствам, не зависящим от
            Заимодавца 1 или (iv) в случае наступления События дефолта (как
            определено в Форме ДКЗ 1). <br />
            Иные условия ДКЗ 1 указаны в Форме ДКЗ 1.
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            2. В соответствии с п. 7 ст. 32.3 Федерального закона от 26 декабря
            1995 № 208-ФЗ "Об акционерных обществах" дать предварительное
            согласие на заключение Обществом договора конвертируемого займа
            (далее – "ДКЗ 1") на следующих условиях:
            <br />
            1.1. Стороны ДКЗ 1: Общество (заемщик), Общество с ограниченной
            ответственностью Управляющая компания "Система СмартТех", ИНН
            7708367753, выступающее в качестве управляющего товарища
            Инвестиционного товарищества "Система СмартТех", созданного на
            основании договора инвестиционного товарищества, удостоверенного
            нотариусом города Москвы Ралько Василием Васильевичем 12 ноября 2020
            года в реестре №77/2317н/77-2020-4-1274 (регистрационный номер
            договора в Единой Информационной Системе Нотариата 172747157), место
            нахождения нотариуса по адресу: 108812, г. Москва, 22км Киевское
            шоссе, д.6, стр. 1 (далее – "Заимодавец1").
            <br />
            1.2. Заимодавец 1 обязуется предоставить Обществу заем в размере 10
            000 000 (десять миллионов) рублей 00 копеек (далее – "Заем 1").
            <br />
            1.3. Срок и (или) иные обстоятельства, при наступлении которых
            Заимодавец 1 вправе предъявить требование о размещении ему
            обыкновенных акций Общества (далее – "Обыкновенные акции") (далее –
            "Требование 1"): (i) получение Заимодавцем 1 Оферты о смене контроля
            (как определено в форме ДКЗ 1, приведенной в Приложении 1 к
            Протоколу (далее – "Форма ДКЗ 1"), (ii) увеличение уставного
            капитала Общества путем размещения им акций в пользу третьего лица,
            не являющегося акционером Общества на дату заключения ДКЗ 1 (за
            исключением случая, предусмотренного пунктом (iii) настоящего пункта
            далее), (iii) увеличение уставного капитала Общества путем
            размещения им акций в пользу Публичного акционерного общества
            "Акционерная Финансовая Корпорация "Система", юридического лица,
            учрежденного в соответствии с законодательством Российской
            Федерации, ОГРН 1027700003891 (далее – "Инвестор") и (iv) получение
            Заимодавцем 1 Оферты об общем праве присоединения (как определено в
            Форме ДКЗ 1).
            <br />
            1.4. Заимодавец 1 вправе требовать размещение ему Обыкновенных акций
            в количестве, определяемом в соответствии с Приложением 1 к Форме
            ДКЗ 1. <br />
            1.5. порядок определения цены размещения дополнительных Обыкновенных
            акций во исполнение ДКЗ 1:
            <br />
            1.5.1. в случае получения Заимодавцем 1 Оферты о смене контроля
            и/или Оферты об общем праве присоединения- 46 (сорок шесть) рублей
            53 копейки за одну Обыкновенную акцию, 1.5.2. в случае увеличения
            уставного капитала Общества путем размещения им акций в пользу
            третьего лица, не являющегося акционером Общества на дату заключения
            ДКЗ 1 и/или увеличения уставного капитала Общества путем размещения
            им акций в пользу Инвестора цена размещения одной Обыкновенной акции
            определяется по следующей формуле: P= CL/K, где: <br />
            P - цена размещения одной Обыкновенной акции; <br />
            K – количество Обыкновенных акций, которые должны быть выпущены
            Заимодавцу 1 в результате конвертации в соответствии с условиями ДКЗ
            1 (в случае получения дробного числа, округленное по правилам
            математического округления); CL – размер Займа 1. <br />
            1.6. Заем 1 предоставляются Обществу на срок до 28 марта 2023 года.{" "}
            <br />
            1.7. Проценты за пользование Займом 1 в размере 100% (сто процентов)
            от суммы Займа 1 начисляются: (i) в случае, предусмотренном в пункте
            5.2.1.2 Формы ДКЗ 1, (ii) в случае наступления Ликвидационного
            события (как определено в Форме ДКЗ 1) (если до наступления
            Ликвидационного события в пользу Заимодавца 1 не были размещены
            Конвертируемые акции (как определено в Форме ДКЗ 1)), (iii) в
            случае, если в пользу Заимодавца 1 не были размещены Конвертируемые
            акции в течение срока размещения Обыкновенных акций, определенного
            ДКЗ 1, во исполнение ДКЗ 1 по обстоятельствам, не зависящим от
            Заимодавца 1 или (iv) в случае наступления События дефолта (как
            определено в Форме ДКЗ 1). <br />
            Иные условия ДКЗ 1 указаны в Форме ДКЗ 1.
            <br />
            <br />
            <b>По вопросу повестки дня №3:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            3. В соответствии с п. 7 ст. 32.3 Федерального закона от 26 декабря
            1995 № 208-ФЗ "Об акционерных обществах" дать предварительное
            согласие на заключение Обществом договора конвертируемого займа
            путем внесения изменений в договор займа № 21-22 от 01 марта 2022
            года между Обществом и Варлашиным Владиславом Валерьевичем (далее –
            "ДКЗ 2") на следующих условиях: <br />
            1.1. Стороны ДКЗ 2: Общество (заемщик), Варлашин Владислав
            Валерьевич, ИНН 231204904297 (далее – "Заимодавец 2", далее
            Заимодавец 1 и Заимодавец 2 обозначаются "Заимодавцы" совместно и
            "Заимодавец" – по отдельности). <br />
            1.2. Заимодавец 2 обязуется предоставить Обществу заем в размере 10
            000 000 (десять миллионов) рублей 00 копеек (далее – "Заем 2").
            <br />
            1.3. Срок и (или) иные обстоятельства, при наступлении которых
            Заимодавец 2 вправе предъявить требование о размещении ему
            Обыкновенных акций (далее – "Требование 2", Требование 1 и
            Требование 2 обозначаются "Требования" совместно и "Требование" –
            по-отдельности): (i) получение Заимодавцем 2 Оферты о смене контроля
            (как определено в форме ДКЗ 2, приведенной в Приложении 2 к
            Протоколу (далее – "Форма ДКЗ 2", далее ДКЗ 1 и ДКЗ 2 обозначаются
            "Все ДКЗ" совместно и "ДКЗ" – по-отдельности), (ii) увеличение
            уставного капитала Общества путем размещения им акций в пользу
            третьего лица, не являющегося акционером Общества на дату заключения
            ДКЗ 2 (за исключением случая, предусмотренного пунктом (iii)
            настоящего пункта далее) и (iii) увеличение уставного капитала
            Общества путем размещения им акций в пользу Инвестора. <br />
            1.4. Заимодавец 2 вправе требовать размещение ему Обыкновенных акций
            в количестве, определяемом в соответствии с Приложением 1 к Форме
            ДКЗ 2. <br />
            1.5. порядок определения цены размещения дополнительных Обыкновенных
            акций во исполнение ДКЗ 2:
            <br />
            1.5.1. в случае получения Заимодавцем 2 Оферты о смене контроля - 46
            (сорок шесть) рублей 53 копейки за одну Обыкновенную акцию, <br />
            1.5.2. в случае увеличения уставного капитала Общества путем
            размещения им акций в пользу третьего лица, не являющегося
            акционером Общества на дату заключения ДКЗ 2 и/или увеличения
            уставного капитала Общества путем размещения им акций в пользу
            Инвестора цена размещения одной Обыкновенной акции определяется по
            следующей формуле: P= CL/K, где: <br />
            P - цена размещения одной Обыкновенной акции; <br />
            K – количество Обыкновенных акций, которые должны быть выпущены
            Заимодавцу 2 в результате конвертации в соответствии с условиями ДКЗ
            2 (в случае получения дробного числа, округленное по правилам
            математического округления); <br />
            CL – размер Займа 2. <br />
            1.6. Заем 2 предоставляются Обществу на срок до 28 марта 2023 года.{" "}
            <br />
            1.7. Проценты за пользование Займом 2 в размере 100% (сто процентов)
            от суммы Займа 2 начисляются: (i) в случае, предусмотренном в пункте
            5.2.1.2 Формы ДКЗ 2, (ii) в случае наступления Ликвидационного
            события (как определено в Форме ДКЗ 2) (если до наступления
            Ликвидационного события в пользу Заимодавца 2 не были размещены
            Конвертируемые акции (как определено в Форме ДКЗ 2)), (iii) в
            случае, если в пользу Заимодавца 2 не были размещены Конвертируемые
            акции в течение срока размещения Обыкновенных акций, определенного
            ДКЗ 2, во исполнение ДКЗ 2 по обстоятельствам, не зависящим от
            Заимодавца 2 или (iv) в случае наступления События дефолта (как
            определено в Форме ДКЗ 2). <br />
            Иные условия ДКЗ 2 указаны в Форме ДКЗ 2.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            3. В соответствии с п. 7 ст. 32.3 Федерального закона от 26 декабря
            1995 № 208-ФЗ "Об акционерных обществах" дать предварительное
            согласие на заключение Обществом договора конвертируемого займа
            путем внесения изменений в договор займа № 21-22 от 01 марта 2022
            года между Обществом и Варлашиным Владиславом Валерьевичем (далее –
            "ДКЗ 2") на следующих условиях: <br />
            1.1. Стороны ДКЗ 2: Общество (заемщик), Варлашин Владислав
            Валерьевич, ИНН 231204904297 (далее – "Заимодавец 2", далее
            Заимодавец 1 и Заимодавец 2 обозначаются "Заимодавцы" совместно и
            "Заимодавец" – по отдельности). <br />
            1.2. Заимодавец 2 обязуется предоставить Обществу заем в размере 10
            000 000 (десять миллионов) рублей 00 копеек (далее – "Заем 2").
            <br />
            1.3. Срок и (или) иные обстоятельства, при наступлении которых
            Заимодавец 2 вправе предъявить требование о размещении ему
            Обыкновенных акций (далее – "Требование 2", Требование 1 и
            Требование 2 обозначаются "Требования" совместно и "Требование" –
            по-отдельности): (i) получение Заимодавцем 2 Оферты о смене контроля
            (как определено в форме ДКЗ 2, приведенной в Приложении 2 к
            Протоколу (далее – "Форма ДКЗ 2", далее ДКЗ 1 и ДКЗ 2 обозначаются
            "Все ДКЗ" совместно и "ДКЗ" – по-отдельности), (ii) увеличение
            уставного капитала Общества путем размещения им акций в пользу
            третьего лица, не являющегося акционером Общества на дату заключения
            ДКЗ 2 (за исключением случая, предусмотренного пунктом (iii)
            настоящего пункта далее) и (iii) увеличение уставного капитала
            Общества путем размещения им акций в пользу Инвестора. <br />
            1.4. Заимодавец 2 вправе требовать размещение ему Обыкновенных акций
            в количестве, определяемом в соответствии с Приложением 1 к Форме
            ДКЗ 2. <br />
            1.5. порядок определения цены размещения дополнительных Обыкновенных
            акций во исполнение ДКЗ 2:
            <br />
            1.5.1. в случае получения Заимодавцем 2 Оферты о смене контроля - 46
            (сорок шесть) рублей 53 копейки за одну Обыкновенную акцию, <br />
            1.5.2. в случае увеличения уставного капитала Общества путем
            размещения им акций в пользу третьего лица, не являющегося
            акционером Общества на дату заключения ДКЗ 2 и/или увеличения
            уставного капитала Общества путем размещения им акций в пользу
            Инвестора цена размещения одной Обыкновенной акции определяется по
            следующей формуле: P= CL/K, где: <br />
            P - цена размещения одной Обыкновенной акции; <br />
            K – количество Обыкновенных акций, которые должны быть выпущены
            Заимодавцу 2 в результате конвертации в соответствии с условиями ДКЗ
            2 (в случае получения дробного числа, округленное по правилам
            математического округления); <br />
            CL – размер Займа 2. <br />
            1.6. Заем 2 предоставляются Обществу на срок до 28 марта 2023 года.{" "}
            <br />
            1.7. Проценты за пользование Займом 2 в размере 100% (сто процентов)
            от суммы Займа 2 начисляются: (i) в случае, предусмотренном в пункте
            5.2.1.2 Формы ДКЗ 2, (ii) в случае наступления Ликвидационного
            события (как определено в Форме ДКЗ 2) (если до наступления
            Ликвидационного события в пользу Заимодавца 2 не были размещены
            Конвертируемые акции (как определено в Форме ДКЗ 2)), (iii) в
            случае, если в пользу Заимодавца 2 не были размещены Конвертируемые
            акции в течение срока размещения Обыкновенных акций, определенного
            ДКЗ 2, во исполнение ДКЗ 2 по обстоятельствам, не зависящим от
            Заимодавца 2 или (iv) в случае наступления События дефолта (как
            определено в Форме ДКЗ 2). <br />
            Иные условия ДКЗ 2 указаны в Форме ДКЗ 2.
            <br />
            <br />
            <b>По вопросу повестки дня №4:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            4. Во исполнение всех ДКЗ увеличить уставный капитал Общества путем
            размещения дополнительных обыкновенных акций Общества, для чего
            определить:
            <br />
            1.1. категория (тип), номинальная стоимость размещаемых
            дополнительных акций: акции обыкновенные номинальной стоимостью 0,01
            (одна копейка) рубля каждая; <br />
            1.2. количество размещаемых дополнительных обыкновенных акций в
            пределах количества объявленных акций данной категории: 2 509 901
            954 (два миллиарда пятьсот девять миллионов девятьсот одна тысяча
            девятьсот пятьдесят четыре) штук; <br />
            1.3. способ размещения дополнительных Обыкновенных акций: закрытая
            подписка; <br />
            1.4. цена размещения дополнительных Обыкновенных акций, в том числе
            лицам, имеющим преимущественное право приобретения дополнительных
            Обыкновенных акций:
            <br />
            1.4.1. в случае направления Требования в связи с получением
            Заимодавцем Оферты о смене контроля и/или получения Заимодавцем 1
            Оферты об общем праве присоединения - 46 (сорок шесть) рублей 53
            копейки за одну Обыкновенную акцию, <br />
            1.4.2. в случае направления Требования в связи с увеличением
            уставного капитала Общества путем размещения им акций в пользу
            третьего лица, не являющегося акционером Общества на дату заключения
            соответствующего ДКЗ и/или увеличения уставного капитала Общества
            путем размещения им акций в пользу Инвестора цена размещения одной
            Обыкновенной акции определяется по следующей формуле: <br />
            P= CL/K, где:
            <br />
            P - цена размещения одной Обыкновенной акции; <br />
            K – количество Обыкновенных акций, которые должны быть выпущены
            Заимодавцу в результате конвертации в соответствии с условиями ДКЗ
            (в случае получения дробного числа, округленное по правилам
            математического округления); <br />
            CL – размер Займа 1 или займа 2 (в зависимости от того, что
            применимо). <br />
            1.5. форма оплаты размещаемых акций: размещаемые дополнительные
            Обыкновенные акции оплачиваются денежными средствами в валюте РФ (в
            рублях). Возможна оплата размещаемых акций путем зачета денежных
            требований к Обществу. <br />
            1.6. круг лиц, среди которых предполагается осуществить размещение
            дополнительных Обыкновенных акций: <br />
            1.6.1. Инвестиционное товарищество "Система СмартТех" (от имени и в
            интересах Инвестиционного товарищества "Система СмартТех"
            размещаемые обыкновенные акции приобретает Управляющий товарищ -
            Общество с ограниченной ответственностью Управляющая компания
            "Система СмартТех", ИНН 7708367753), созданное на основании договора
            инвестиционного товарищества, удостоверенного нотариусом города
            Москвы Ралько Василием Васильевичем 12 ноября 2020 года в реестре
            №77/2317-н/77-2020-4-1274 (регистрационный номер договора в Единой
            Информационной Системе Нотариата 172747157) вправе приобрести не
            более 1 254 950 977 (один миллиард двести пятьдесят четыре миллиона
            девятьсот пятьдесят тысяч девятьсот семьдесят семь) акций; <br />
            1.6.2. Варлашин Владислав Валерьевич (ИНН 231204904297) вправе
            приобрести не более 1 254 950 977 (один миллиард двести пятьдесят
            четыре миллиона девятьсот пятьдесят тысяч девятьсот семьдесят семь)
            акций, <br />
            1.7. расходы, связанные с внесением записи о зачислении размещаемых
            акций на лицевой счет (счет депо) приобретателя, несет приобретатель
            акций; <br />
            1.8. срок размещения дополнительных Обыкновенных акций: период с
            даты регистрации Банком России дополнительного выпуска Обыкновенных
            акций во исполнение ДКЗ 1 и ДКЗ 2 до (i) 28 марта 2023 года либо
            (ii) даты размещения последней Обыкновенной акции указанного
            дополнительного выпуска, в зависимости от того, какая из двух дат
            наступит раньше; <br />
            1.9. иные условия размещения акций, в т.ч. порядок заключения
            договоров в ходе размещения, будут определены документом, содержащим
            условия размещения ценных бумаг; <br />
            1.10. по результатам размещения дополнительных Обыкновенных акций
            внести в случае необходимости соответствующие изменения в Устав
            Общества.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            4. Во исполнение всех ДКЗ увеличить уставный капитал Общества путем
            размещения дополнительных обыкновенных акций Общества, для чего
            определить:
            <br />
            1.1. категория (тип), номинальная стоимость размещаемых
            дополнительных акций: акции обыкновенные номинальной стоимостью 0,01
            (одна копейка) рубля каждая; <br />
            1.2. количество размещаемых дополнительных обыкновенных акций в
            пределах количества объявленных акций данной категории: 2 509 901
            954 (два миллиарда пятьсот девять миллионов девятьсот одна тысяча
            девятьсот пятьдесят четыре) штук; <br />
            1.3. способ размещения дополнительных Обыкновенных акций: закрытая
            подписка; <br />
            1.4. цена размещения дополнительных Обыкновенных акций, в том числе
            лицам, имеющим преимущественное право приобретения дополнительных
            Обыкновенных акций:
            <br />
            1.4.1. в случае направления Требования в связи с получением
            Заимодавцем Оферты о смене контроля и/или получения Заимодавцем 1
            Оферты об общем праве присоединения - 46 (сорок шесть) рублей 53
            копейки за одну Обыкновенную акцию, <br />
            1.4.2. в случае направления Требования в связи с увеличением
            уставного капитала Общества путем размещения им акций в пользу
            третьего лица, не являющегося акционером Общества на дату заключения
            соответствующего ДКЗ и/или увеличения уставного капитала Общества
            путем размещения им акций в пользу Инвестора цена размещения одной
            Обыкновенной акции определяется по следующей формуле: <br />
            P= CL/K, где:
            <br />
            P - цена размещения одной Обыкновенной акции; <br />
            K – количество Обыкновенных акций, которые должны быть выпущены
            Заимодавцу в результате конвертации в соответствии с условиями ДКЗ
            (в случае получения дробного числа, округленное по правилам
            математического округления); <br />
            CL – размер Займа 1 или займа 2 (в зависимости от того, что
            применимо). <br />
            1.5. форма оплаты размещаемых акций: размещаемые дополнительные
            Обыкновенные акции оплачиваются денежными средствами в валюте РФ (в
            рублях). Возможна оплата размещаемых акций путем зачета денежных
            требований к Обществу. <br />
            1.6. круг лиц, среди которых предполагается осуществить размещение
            дополнительных Обыкновенных акций: <br />
            1.6.1. Инвестиционное товарищество "Система СмартТех" (от имени и в
            интересах Инвестиционного товарищества "Система СмартТех"
            размещаемые обыкновенные акции приобретает Управляющий товарищ -
            Общество с ограниченной ответственностью Управляющая компания
            "Система СмартТех", ИНН 7708367753), созданное на основании договора
            инвестиционного товарищества, удостоверенного нотариусом города
            Москвы Ралько Василием Васильевичем 12 ноября 2020 года в реестре
            №77/2317-н/77-2020-4-1274 (регистрационный номер договора в Единой
            Информационной Системе Нотариата 172747157) вправе приобрести не
            более 1 254 950 977 (один миллиард двести пятьдесят четыре миллиона
            девятьсот пятьдесят тысяч девятьсот семьдесят семь) акций; <br />
            1.6.2. Варлашин Владислав Валерьевич (ИНН 231204904297) вправе
            приобрести не более 1 254 950 977 (один миллиард двести пятьдесят
            четыре миллиона девятьсот пятьдесят тысяч девятьсот семьдесят семь)
            акций, <br />
            1.7. расходы, связанные с внесением записи о зачислении размещаемых
            акций на лицевой счет (счет депо) приобретателя, несет приобретатель
            акций; <br />
            1.8. срок размещения дополнительных Обыкновенных акций: период с
            даты регистрации Банком России дополнительного выпуска Обыкновенных
            акций во исполнение ДКЗ 1 и ДКЗ 2 до (i) 28 марта 2023 года либо
            (ii) даты размещения последней Обыкновенной акции указанного
            дополнительного выпуска, в зависимости от того, какая из двух дат
            наступит раньше; <br />
            1.9. иные условия размещения акций, в т.ч. порядок заключения
            договоров в ходе размещения, будут определены документом, содержащим
            условия размещения ценных бумаг; <br />
            1.10. по результатам размещения дополнительных Обыкновенных акций
            внести в случае необходимости соответствующие изменения в Устав
            Общества.
            <br />
            <br />
            <b>По вопросу повестки дня №5:</b>
            <br />
            <b>Формулировка решения, поставленного на голосование:</b>
            <br />
            5. Дать согласие на совершение сделки, в совершении которой имеется
            заинтересованность - одобрить заключение Обществом дополнительного
            соглашения №3 (далее – "Дополнительное соглашение") к договору об
            осуществлении прав акционеров Общества, заключенному 19 июня 2021
            года, с учетом дополнительного соглашения № 1 к нему от 29 октября
            2021 года, дополнительного соглашения № 2 к нему от 24 января 2022
            года по форме, приведенной в Приложении 3 к Протоколу, как сделки, в
            совершении которой имеется заинтересованность. Стороны
            Дополнительного соглашения: Общество, Заимодавец 1, Заимодавец 2,
            Акционерное общество "ТИЛТЕХ" (ИНН 9729027864), действующее в
            качестве управляющего товарища Инвестиционного товарищества "ТилТех
            Капитал" на основании договора инвестиционного товарищества,
            удостоверенного нотариусом города Москвы Горбатенко Николаем
            Владимировичем 11 ноября 2016 года за реестровым номером 2-2758
            (регистрационный номер договора в реестре нотариальных действий
            единой информационной системе нотариата 262942247), Евдаков Кирилл
            Сергеевич, Остапенко Владимир Валерьевич, Левицкий Ефим Романович,
            Литвиненко Сергей Иванович, Горный Александр Аркадьевич. <br />
            Условия Дополнительного соглашения определяются в соответствии с
            формой Дополнительного соглашения, приведенной в Приложении 3 к
            Протоколу. <br />
            Основания признания заключения Обществом Дополнительного соглашения
            сделкой, в совершении которой имеется заинтересованность: (i)
            Евдаков Кирилл Сергеевич одновременно является стороной
            Дополнительного соглашения и действует в качестве единоличного
            исполнительного органа Общества, (ii) Остапенко Владимир Валерьевич,
            Левицкий Ефим Романович, Литвиненко Сергей Иванович одновременно
            являются сторонами сделки и членами совета директоров Общества.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            5. Дать согласие на совершение сделки, в совершении которой имеется
            заинтересованность - одобрить заключение Обществом дополнительного
            соглашения №3 (далее – "Дополнительное соглашение") к договору об
            осуществлении прав акционеров Общества, заключенному 19 июня 2021
            года, с учетом дополнительного соглашения № 1 к нему от 29 октября
            2021 года, дополнительного соглашения № 2 к нему от 24 января 2022
            года по форме, приведенной в Приложении 3 к Протоколу, как сделки, в
            совершении которой имеется заинтересованность. Стороны
            Дополнительного соглашения: Общество, Заимодавец 1, Заимодавец 2,
            Акционерное общество "ТИЛТЕХ" (ИНН 9729027864), действующее в
            качестве управляющего товарища Инвестиционного товарищества "ТилТех
            Капитал" на основании договора инвестиционного товарищества,
            удостоверенного нотариусом города Москвы Горбатенко Николаем
            Владимировичем 11 ноября 2016 года за реестровым номером 2-2758
            (регистрационный номер договора в реестре нотариальных действий
            единой информационной системе нотариата 262942247), Евдаков Кирилл
            Сергеевич, Остапенко Владимир Валерьевич, Левицкий Ефим Романович,
            Литвиненко Сергей Иванович, Горный Александр Аркадьевич. <br />
            Условия Дополнительного соглашения определяются в соответствии с
            формой Дополнительного соглашения, приведенной в Приложении 3 к
            Протоколу. <br />
            Основания признания заключения Обществом Дополнительного соглашения
            сделкой, в совершении которой имеется заинтересованность: (i)
            Евдаков Кирилл Сергеевич одновременно является стороной
            Дополнительного соглашения и действует в качестве единоличного
            исполнительного органа Общества, (ii) Остапенко Владимир Валерьевич,
            Левицкий Ефим Романович, Литвиненко Сергей Иванович одновременно
            являются сторонами сделки и членами совета директоров Общества.
            <br />
            <b>Председательствующий на собрании Литвиненко Сергей Иванович </b>
            <br />
            <b>Секретарь собрания Левицкий Ефим Романович </b>
            <br />
            <br />
          </div>
          <hr />

          <div className="text">
            АО "Самокарт" (далее – Общество) сообщает акционерам Общества о
            проведении внеочередного общего собрания акционеров.
            <br />
            Полное фирменное наименование Общества: Акционерное общество
            "Самокарт".
            <br />
            Место нахождения Общества: 350051, Краснодарский край, г. Краснодар,
            ул. Стахановская, д. 15, корп. 3, комн. 2.
            <br />
            Дата окончания приема заполненных бюллетеней для голосования (дата
            проведения собрания): 18 марта 2022 года.
            <br />
            Дата, на которую определяются (фиксируются) лица, имеющие право на
            участие в общем собрании акционеров: 26 февраля 2022 года.
            <br />
            Категории (типы) акций, владельцы которых имеют право голоса по всем
            вопросам повестки дня общего собрания акционеров: обыкновенные.
            <br />
            Почтовый адрес, по которому должны направляться заполненные
            бюллетени для голосования: 350051, Краснодарский край, г. Краснодар,
            ул. Стахановская, д. 15, корп. 3, комн. 2.
            <br />
            При направлении заполненных документов в общество, представителям
            акционеров необходимо приложить документы, подтверждающие их
            полномочия для осуществления голосования (их копии,
            засвидетельствованные (удостоверенные) в порядке, предусмотренном
            законодательством Российской Федерации).
            <br />
            Вид собрания: внеочередное.
            <br />
            Форма проведения собрания: Заочное голосование.
            <br />
            <br />
            <b>
              Вопросы, включенные в повестку дня внеочередного общего собрания
              акционеров Общества:
            </b>
            <br />
            <br />
            1. Утверждение Устава Общества в новой редакции.
            <br />
            2. Принятие решения о согласии на совершение сделки - заключение
            Обществом договора конвертируемого займа.
            <br />
            3. Принятие решения о согласии на совершение сделки - заключение
            Обществом договора конвертируемого займа с Варлашиным В.В.
            <br />
            4. Увеличение уставного капитала Общества путем размещения
            дополнительных обыкновенных акций Общества.
            <br />
            5. Принятие решения о согласии на совершение сделки в совершении
            которой имеется заинтересованность - одобрение заключения Обществом
            дополнительного соглашения № 3 к договору об осуществлении прав
            акционеров Общества, заключенному 19 июня 2021 года.
            <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: г. Краснодар, ул. Стахановская, д.
            15, корп. 3, комн. 2. в течение 20 дней до даты проведения общего
            собрания акционеров, в рабочие дни и часы Общества.
            <br />
            <br />
            <a href="/2022_32710_Bulletin.doc">Бюллетень голосования</a>
            <br />
            <br />
          </div>
          <hr />

          <div className="text">
            <p>24.01.2022 </p>
            <br />
            <b>
              ОТЧЕТ ОБ ИТОГАХ ГОЛОСОВАНИЯ
              <br />
              на внеочередном общем собрании акционеров
              <br />
              АО «Самокарт»
              <br />
            </b>
            <br />
            Полное фирменное наименование общества: Акционерное общество
            «Самокарт» (далее именуемое Общество).
            <br />
            Место нахождения Общества: Краснодарский край, г. Краснодар.
            <br />
            Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, корпус 3, комната 2.
            <br />
            Вид общего собрания: внеочередное.
            <br />
            Форма проведения общего собрания: заочное голосование.
            <br />
            Дата определения (фиксации) лиц, имевших право на участие в общем
            собрании акционеров Общества: «21» января 2022 г.
            <br />
            Дата проведения общего собрания: «24» января 2022 г.
            <br />
            Лицо, подтвердившее принятие решений общим собранием и состав лиц,
            присутствовавших при их принятии: <br />
            Таракановская Наталья Сергеевна, паспорт РФ 0304 211130, выдан 21
            февраля 2003 года ПВС УВД Западного округа гор. Краснодара,
            зарегистрированная по адресу: г. Краснодар, ул. Карасунская
            набережная, д. 32, кв. 63 выполнявшая функции счетной комиссии.
            <br />
            Председательствующий на общем собрании акционеров: Литвиненко Сергей
            Иванович
            <br />
            Секретарь общего собрания акционеров: Левицкий Ефим Романович
            <br />
            <br />
            <b>ПОВЕСТКА ДНЯ СОБРАНИЯ:</b>
            <br />
            <br />
            1. Увеличение уставного капитала Общества путем размещения
            дополнительных обыкновенных акций. <br />
            2. О даче предварительного согласия на заключение Обществом договора
            конвертируемого займа посредством принятия решения об увеличении
            уставного капитала Общества путем размещения дополнительных
            обыкновенных акций Общества во исполнение данного договора.
            <br />
            3. Об одобрении заключения Обществом договора конвертируемого займа
            как крупной сделки.
            <br />
            4. Об одобрении заключения Обществом дополнительного соглашения №2 к
            договору об осуществлении прав акционеров Общества, заключенному 19
            июня 2021 года, с учетом дополнительного соглашения № 1 к нему от 29
            октября 2021 года, как сделки, в совершении которой имеется
            заинтересованность. <br />
            5. Об одобрении заключения Обществом дополнительного соглашения №2 к
            договору займа от 19 июня 2021 года между Обществом и Обществом с
            ограниченной ответственностью Управляющая компания "Система
            СмартТех" (ИНН 7708367753), выступающим в качестве управляющего
            товарища Инвестиционного товарищества "Система СмартТех", с учетом
            дополнительного соглашения №1 к нему от 29 октября 2021 года.
            <br />
            <br />
            <b>
              По вопросу повестки дня №1: « Увеличение уставного капитала
              Общества путем размещения дополнительных обыкновенных акций»
            </b>
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            1. Увеличить уставный капитал Акционерного общества «Самокарт» путем
            размещения дополнительных обыкновенных акций АО «Самокарт», для чего
            определить:
            <br />
            1.1. категория (тип), номинальная стоимость размещаемых
            дополнительных акций: акции обыкновенные номинальной стоимостью 0,01
            (Ноль рублей 01 копейка) рублей каждая;
            <br />
            1.2. количество размещаемых дополнительных обыкновенных акций в
            пределах количества объявленных акций данной категории: 1 762 303
            (один миллион семьсот шестьдесят две тысячи триста три) штук;
            <br />
            1.3. способ размещения дополнительных обыкновенных акций: закрытая
            подписка;
            <br />
            1.4. цена размещения дополнительных обыкновенных акций, в том числе
            лицам, имеющим преимущественное право приобретения дополнительных
            обыкновенных акций: 46 (сорок шесть) рублей 53 копейки за одну
            акцию; <br />
            1.5. форма оплаты размещаемых акций: размещаемые дополнительные
            обыкновенные акции оплачиваются денежными средствами в валюте РФ (в
            рублях). Возможна оплата размещаемых акций путем зачета денежных
            требований к акционерному обществу.
            <br />
            1.6. круг лиц, среди которых предполагается осуществить размещение
            дополнительных обыкновенных акций: <br />
            1.6.1. Инвестиционное товарищество «Система СмартТех» (от имени и в
            интересах Инвестиционного товарищества «Система СмартТех»
            размещаемые обыкновенные акции приобретает Управляющий товарищ -
            Общество с ограниченной ответственностью Управляющая компания
            "Система СмартТех", ИНН 7708367753), созданное на основании договора
            инвестиционного товарищества, удостоверенного нотариусом города
            Москвы Ралько Василием Васильевичем 12.11.2020 года в реестре
            №77/2317-н/77-2020-4-1274 (регистрационный номер договора в Единой
            Информационной Системе Нотариата 172747157) вправе приобрести не
            более 644 745 акции;
            <br />
            1.6.2. Инвестиционное товарищество "ТилТех Капитал" (от имени и в
            интересах Инвестиционного товарищества «ТилТех Капитал" размещаемые
            обыкновенные акции приобретает Управляющий товарищ - Акционерное
            общество "ТИЛТЕХ", (ИНН 9729027864), созданное на основании договора
            инвестиционного товарищества, удостоверенного нотариусом города
            Москвы Горбатенко Николаем Владимировичем 11 ноября 2016 года за
            реестровым номером 2-2758 (регистрационный номер договора в реестре
            нотариальных действий единой информационной системе нотариата
            262942247) вправе приобрести не более 472 813 акции;
            <br />
            1.6.3. Варлашин Владислав Валерьевич (ИНН 231204904297) вправе
            приобрести не более 644 745 акций,
            <br />
            1.7. расходы, связанные с внесением записи о зачислении размещаемых
            акций на лицевой счет (счет депо) приобретателя, несет приобретатель
            акций;
            <br />
            1.8. иные условия размещения акций, в т.ч. срок размещения, порядок
            заключения договоров в ходе размещения, будут определены документом,
            содержащим условия размещения ценных бумаг; <br />
            1.9. по результатам размещения дополнительных обыкновенных акций
            внести в случае необходимости соответствующие изменения в Устав
            Общества.
            <br />
            <br />
            <b>
              По вопросу повестки дня №2: «О даче предварительного согласия на
              заключение Обществом договора конвертируемого займа посредством
              принятия решения об увеличении уставного капитала Общества путем
              размещения дополнительных обыкновенных акций Общества во
              исполнение данного договора»
            </b>
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            1. В соответствии с п. 7 ст. 32.3 Федерального закона от 26 декабря
            1995 № 208-ФЗ "Об акционерных обществах" дать предварительное
            согласие на заключение Обществом договора конвертируемого займа
            (далее – "ДКЗ") на следующих условиях:
            <br />
            1.1. Стороны ДКЗ: Общество (заемщик), Общество с ограниченной
            ответственностью Управляющая компания "Система СмартТех", ИНН
            7708367753, выступающее в качестве управляющего товарища
            Инвестиционного товарищества "Система СмартТех", созданного на
            основании договора инвестиционного товарищества, удостоверенного
            нотариусом города Москвы Ралько Василием Васильевичем 12 ноября 2020
            года в реестре №77/2317н/77-2020-4-1274 (регистрационный номер
            договора в Единой Информационной Системе Нотариата 172747157), место
            нахождения нотариуса по адресу: 108812, г. Москва, 22км Киевское
            шоссе, д.6, стр. 1 (далее – "Заимодавец 1"), Акционерное общество
            "ТИЛТЕХ", ИНН 9729027864, действующее в качестве Управляющего
            Товарища Инвестиционного товарищества "ТилТех Капитал", созданного
            на основании договора инвестиционного товарищества, удостоверенного
            нотариусом города Москвы Горбатенко Николаем Владимировичем 11
            ноября 2016 года за реестровым номером 2-2758 (регистрационный номер
            договора в реестре нотариальных действий единой информационной
            системе нотариата 262942247) (далее – "Заимодавец 2"), Варлашин
            Владислав Валерьевич, ИНН 231204904297 (далее – "Заимодавец 3",
            далее Заимодавец 1, Заимодавец 2 и Заимодавец 3 обозначаются
            "Заимодавцы" совместно и "Заимодавец" – по отдельности).
            <br />
            1.2. Заимодавец 1 обязуется предоставить Обществу заем в совокупном
            размере 29 999 984 (двадцать девять миллионов девятьсот девяносто
            девять тысяч девятьсот восемьдесят четыре) рубля 85 копеек.
            Указанная сумма займа предоставляется двумя траншами – первый транш
            составляет 14 999 969 (четырнадцать миллионов девятьсот девяносто
            девять тысяч девятьсот шестьдесят девять) рублей 16 копеек, второй
            транш составляет 15 000 015 (пятнадцать миллионов пятнадцать) рублей
            69 копеек. Соответствующий транш предоставляется при соблюдении
            условий для предоставления такого транша, предусмотренных пунктом
            3.1 формы ДКЗ, приведенной в Приложении 1 (далее – "Форма ДКЗ").
            <br />
            1.3. ДКЗ предусматривает замену (новацию) обязательств Общества
            перед Заимодавцем 2, вытекающих из договора займа между Обществом с
            ограниченной ответственностью "ТИЛТЕХ КАПИТАЛ" (ОГРН 5187746020831)
            (далее – "Первоначальный кредитор") и Обществом от 22 декабря 2020
            года, с изменениями, внесенными дополнительным соглашением №1 от 29
            апреля 2021 года, дополнительным соглашением №2 от 29 ноября 2021
            года и дополнительным соглашением №3 от 11 января 2022 года,
            кредитором по которому в результате заключения договора уступки прав
            требования от 21 января 2022 года между Первоначальным кредитором
            (цедент) и Заимодавцем 2 (цессионарий) стал Заимодавец 2, договора
            займа между Первоначальным кредитором и Обществом от 15 декабря 2020
            года с учетом дополнительного соглашения №1 от 29 апреля 2021 года,
            дополнительного соглашения №2 от 11 января 2022 года, кредитором по
            которому в результате заключения договора уступки прав требования от
            21 января 2022 года между Первоначальным кредитором (цедент) и
            Заимодавцем 2 (цессионарий) стал Заимодавец 2, обязательством по
            возврату Заимодавцу 2 предоставленной Первоначальным кредитором
            Обществу суммы в размере 11 (одиннадцать) рублей 11 копеек в
            денежной форме до 31 декабря 2022 года и по размещению в пользу
            Заимодавца 2 обыкновенных акций Общества номинальной стоимостью 0,01
            (ноль целых одна сотая) рубля каждая (далее – "Обыкновенные акции")
            и осуществлению зачета предоставленной Первоначальным кредитором
            Обществу суммы займа в совокупном размере 21 999 988 (двадцать один
            миллион девятьсот девяносто девять тысяч девятьсот восемьдесят
            восемь) рублей 89 копеек (далее – "Заем 2") в счет оплаты
            Заимодавцем 2 приобретаемых им Обыкновенных акций, на условиях, в
            порядке и в сроки, предусмотренные разделом 4 Формы ДКЗ, или
            осуществить возврат Займа 2 в форме денежных средств и уплатить
            проценты, начисленные на Заем 2, в случаях, предусмотренных разделом
            6 Формы ДКЗ.
            <br />
            1.4. ДКЗ предусматривает замену (новацию) обязательств Общества
            перед Заимодавцем 3, вытекающих из договора займа №20-21 от 08
            декабря 2021 года между Заимодавцем 3 и Обществом, обязательством по
            возврату Заимодавцу 3 предоставленной Заимодавцем 3 Обществу
            денежной суммы в размере 15 (пятнадцать) рублей 15 копеек до 31
            декабря 2022 года и размещению в пользу Заимодавца 3 Обыкновенных
            акций и осуществлению зачета предоставленной Заимодавцем 3 Обществу
            суммы займа в совокупном размере 29 999 984 (двадцать девять
            миллионов девятьсот девяносто девять тысяч девятьсот восемьдесят
            четыре) рубля 85 копеек (далее – "Заем 3") в счет оплаты Заимодавцем
            3 приобретаемых им Обыкновенных акций, на условиях, в порядке и в
            сроки, предусмотренные разделом 4 Формы ДКЗ, или осуществить возврат
            Займа 3 в форме денежных средств и уплатить проценты, начисленные на
            Заем 3, в случаях, предусмотренных разделом 6 Формы ДКЗ.
            <br />
            1.5. Срок и (или) иные обстоятельства, при наступлении которых
            Заимодавец вправе предъявить требование о размещении ему
            Обыкновенных акций во исполнение ДКЗ: наступление наиболее раннего
            из следующих событий: (i) регистрация Банком России отчета об итогах
            выпуска всех Размещаемых акций (как определено в Форме ДКЗ), (ii)
            получение Заимодавцем 1 и/или Заимодавцем 2 Оферты об общем праве
            присоединения (как определено в Форме ДКЗ) (в таком случае
            требование может быть направлено только Заимодавцем 1 и/или
            Заимодавцем 2, в зависимости от того, кто получил Оферту об общем
            праве присоединения), (iii) получение Заимодавцем Оферты о смене
            контроля (как определено в Форме ДКЗ) или (iv) наступление 1 августа
            2022 года.
            <br />
            1.6. Займы предоставляются Обществу на срок до 31 декабря 2022 года.
            <br />
            1.7. Проценты за пользование соответствующим займом в размере 25%
            (двадцать пять процентов) начисляются: (i) в случае, предусмотренном
            в пункте 5.2.1(b) Формы ДКЗ, (ii) в случае наступления
            Ликвидационного события (как определено в Форме ДКЗ) (если до
            наступления Ликвидационного события в пользу такого Заимодавца не
            были размещены Конвертируемые акции (как определено в Форме ДКЗ)),
            (iii) в случае, если в пользу такого Заимодавца не были размещены
            Конвертируемые акции в течение срока размещения Обыкновенных акций,
            определенного ДКЗ, во исполнение ДКЗ по обстоятельствам, не
            зависящим от такого Заимодавца или (iii) в случае наступления
            События дефолта (как определено в Форме ДКЗ).
            <br />
            2. Во исполнение ДКЗ увеличить уставный капитал Общества путем
            размещения дополнительных Обыкновенных акций, как предусмотрено в
            формулировке решения по вопросу 1, поставленного на голосование:
            <br />
            2.1. категория (тип), номинальная стоимость размещаемых
            дополнительных акций: обыкновенные акции номинальной стоимостью 0,01
            (ноль целых одна сотая) рубля каждая;
            <br />
            2.2. количество размещаемых дополнительных обыкновенных акций в
            пределах количества объявленных акций данной категории: 1 762 303
            (один миллион семьсот шестьдесят две тысячи триста три) штуки на
            общую сумму 17623 (семнадцать тысяч шестьсот двадцать три) рубля 3
            копейки;
            <br />
            2.3. Способ размещения: закрытая подписка;
            <br />
            2.4. Круг лиц, среди которых предполагается разместить
            дополнительные Обыкновенные акции:
            <br />
            2.4.1. Заимодавец 1 вправе приобрести не более 644 745 (шестьсот
            сорок четыре тысячи семьсот сорок пять) Обыкновенных акций;
            <br />
            2.4.2. Заимодавец 2 вправе приобрести не более 472 813 (четыреста
            семьдесят две тысячи восемьсот тринадцать) Обыкновенных акций;
            <br />
            2.4.3. Заимодавец 3 вправе приобрести не более 644 745 (шестьсот
            сорок четыре тысячи семьсот сорок пять) Обыкновенных акций;
            <br />
            2.5. Цена размещения одной Обыкновенной акции (в том числе цена для
            лиц, имеющих преимущественное право приобретения): 46 (сорок шесть)
            рублей 53 копейки;
            <br />
            2.6. Форма оплаты Обыкновенных акций: денежными средствами путем
            зачета в соответствии с ДКЗ требования о погашении займа,
            предоставленного Заимодавцем, который направил требование о
            размещении в его пользу Обыкновенных акций (в случае Заимодавца 2 –
            путем зачета требования о погашении займа, предоставленного
            Первоначальным кредитором), против требования Общества об оплате
            Обыкновенных акций, размещаемых в пользу такого Заимодавца, во
            исполнение ДКЗ;
            <br />
            2.7. Срок размещения дополнительных Обыкновенных акций: период с
            даты регистрации Банком России дополнительного выпуска Обыкновенных
            акций на условиях, предусмотренных пунктом 4.9.1 Формы ДКЗ до (i) 1
            декабря 2022 года либо (ii) даты размещения последней Обыкновенной
            акции указанного дополнительного выпуска, в зависимости от того,
            какая из двух дат наступит раньше.
            <br />
            Иные условия ДКЗ указаны в Форме ДКЗ.
            <br />
            <br />
            <b>
              По вопросу повестки дня №3: «Об одобрении заключения Обществом
              договора конвертируемого займа как крупной сделки»
            </b>
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            Одобрить заключение Обществом ДКЗ как крупной сделки на условиях,
            указанных в формулировке решения по вопросу 2, поставленного на
            голосование.
            <br />
            <br />
            <b>
              По вопросу повестки дня №4: «Об одобрении заключения Обществом
              дополнительного соглашения №2 к договору об осуществлении прав
              акционеров Общества, заключенному 19 июня 2021 года, с учетом
              дополнительного соглашения № 1 к нему от 29 октября 2021 года, как
              сделки, в совершении которой имеется заинтересованность»
            </b>
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            Одобрить заключение Обществом дополнительного соглашения №2 (далее –
            "Дополнительное соглашение") к договору об осуществлении прав
            акционеров Общества, заключенному 19 июня 2021 года, с учетом
            дополнительного соглашения № 1 к нему от 29 октября 2021 года, по
            форме, приведенной в Приложении 2, как сделки, в совершении которой
            имеется заинтересованность.
            <br />
            Стороны Дополнительного соглашения: Общество, Заимодавец 1,
            Заимодавец 2, Заимодавец 3, Евдаков Кирилл Сергеевич, Остапенко
            Владимир Валерьевич, Левицкий Ефим Романович, Литвиненко Сергей
            Иванович, Горный Александр Аркадьевич.
            <br />
            Основание признания заключения Обществом Дополнительного соглашения
            сделкой, в совершении которой имеется заинтересованность: Евдаков
            Кирилл Сергеевич одновременно является стороной Дополнительного
            соглашения и действует в качестве единоличного исполнительного
            органа Общества.
            <br />
            <br />
            <b>
              По вопросу повестки дня №5: «Об одобрении заключения Обществом
              дополнительного соглашения №2 к договору займа от 19 июня 2021
              года между Обществом и Обществом с ограниченной ответственностью
              Управляющая компания "Система СмартТех" (ИНН 7708367753),
              выступающим в качестве управляющего товарища Инвестиционного
              товарищества "Система СмартТех", с учетом дополнительного
              соглашения №1 к нему от 29 октября 2021 года»
            </b>
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            Одобрить заключение Обществом дополнительного соглашения №2 к
            договору займа между Обществом и Заимодавцем 1 от 19 июня 2021 года,
            с учетом дополнительного соглашения №1 к нему от 29 октября 2021
            года.
            <br />
            <br />
            <b>
              Председательствующий на общем собрании
              <br />
              Литвиненко Сергей Иванович,
              <br />
              Секретарь собрания
              <br />
              Левицкий Ефим Романович
              <br />
            </b>
          </div>
          <hr />

          <div className="text">
            <p>20.01.2022 </p>
            <br />
            <b>
              Сообщение
              <br />
              о проведении внеочередного Общего собрания акционеров
              <br />
              Акционерного общества "Самокарт"
              <br />
            </b>
            <br />
            <br />
            Акционерное общество "Самокарт" (далее – АО "Самокарт", Общество)
            сообщает акционерам Общества о проведении внеочередного общего
            собрания акционеров.
            <br />
            Полное фирменное наименование Общества: Акционерное общество
            "Самокарт".
            <br />
            Место нахождения Общества: РФ, г. Краснодар.
            <br />
            Дата окончания приема заполненных бюллетеней для голосования (дата
            проведения собрания): 24 января 2022 года.
            <br />
            Дата, на которую определяются (фиксируются) лица, имеющие право на
            участие в общем собрании акционеров: 21 января 2022 года.
            <br />
            Категории (типы) акций, владельцы которых имеют право голоса по всем
            вопросам повестки дня общего собрания акционеров: обыкновенные.
            <br />
            <br />
            Почтовый адрес, по которому должны направляться заполненные
            бюллетени для голосования: 350051, г. Краснодар, ул. Стахановская,
            д. 15, корп.3, комната 2.
            <br />
            При направлении заполненных документов в общество, представителям
            акционеров необходимо приложить документы, подтверждающие их
            полномочия для осуществления голосования (их копии,
            засвидетельствованные (удостоверенные) в порядке, предусмотренном
            законодательством Российской Федерации).
            <br />
            <br />
            Вид собрания: внеочередное.
            <br />
            Форма проведения собрания: Заочное голосование.
            <br />
            <br />
            <b>
              Вопросы, включенные в повестку дня внеочередного общего собрания
              акционеров Общества:
            </b>
            <br />
            1. Увеличение уставного капитала Общества путем размещения
            дополнительных обыкновенных акций.
            <br />
            2. О даче предварительного согласия на заключение Обществом договора
            конвертируемого займа посредством принятия решения об увеличении
            уставного капитала Общества путем размещения дополнительных
            обыкновенных акций Общества во исполнение данного договора.
            <br />
            3. Об одобрении заключения Обществом договора конвертируемого займа
            как крупной сделки.
            <br />
            4. Об одобрении заключения Обществом дополнительного соглашения №2 к
            договору об осуществлении прав акционеров Общества, заключенному 19
            июня 2021 года, с учетом дополнительного соглашения № 1 к нему от 29
            октября 2021 года, как сделки, в совершении которой имеется
            заинтересованность. <br />
            5. Об одобрении заключения Обществом дополнительного соглашения №2 к
            договору займа между Обществом и Заимодавцем 1 от 19 июня 2021 года,
            с учетом дополнительного соглашения №1 к нему от 29 октября 2021
            года.
            <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: г. Краснодар, ул. Стахановская, д.
            15, корп. 3, комната 2 с 21.01.2022 по 23.01с 09 часов 00 минут до
            17 часов 45 минут (по местному времени).
            <br />
            20.01.2022 г.
            <br /> <br />
          </div>
          <hr />

          <div className="text">
            <p>18.11.2021 </p>
            <br />
            <b>
              ОТЧЕТ ОБ ИТОГАХ ГОЛОСОВАНИЯ <br />
              на внеочередном общем собрании акционеров <br />
              АО «Самокарт»
              <br />
            </b>
            <br />
            <br />
            Полное фирменное наименование общества: Акционерное общество
            «Самокарт» (далее именуемое Общество).
            <br />
            Место нахождения Общества: Краснодарский край, г. Краснодар.
            <br />
            Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, корпус 3, комната 2.
            <br />
            Вид общего собрания: внеочередное.
            <br />
            Форма проведения общего собрания: заочное голосование.
            <br />
            Дата определения (фиксации) лиц, имевших право на участие в общем
            собрании акционеров Общества: «23» октября 2021 г.
            <br />
            Дата проведения общего собрания: «15» ноября 2021 г.
            <br />
            Лицо, подтвердившее принятие решений общим собранием и состав лиц,
            присутствовавших при их принятии: регистратор Общества, выполнявший
            функции счетной комиссии. <br />
            Сведения о регистраторе:
            <br />
            Полное фирменное наименование: Акционерное общество «Реестр».
            <br />
            Место нахождения: Российская Федерация, г. Москва.
            <br />
            Адрес регистратора: 129090, Москва, Б. Балканский пер., д. 20, стр.
            1<br />
            Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
            <br />
            <br />
            Председательствующий на общем собрании акционеров: Литвиненко Сергей
            Иванович
            <br />
            <br />
            <b>ПОВЕСТКА ДНЯ СОБРАНИЯ:</b> <br />
            1. Увеличение уставного капитала Общества путем размещения
            дополнительных обыкновенных акций. <br />
            2. Увеличение уставного капитала Общества путем размещения
            привилегированных акций тип первый. <br />
            3. Увеличение уставного капитала Общества путем размещения
            привилегированных акций тип второй. <br />
            4. Об одобрении заключения Обществом дополнительного соглашения № 1
            к договору займа от 19.06.2021 г.
            <br /> <br />
            По вопросу повестки дня №1: «Увеличение уставного капитала Общества
            путем размещения дополнительных обыкновенных акций». <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            1. Увеличить уставный капитал Акционерного общества «Самокарт» путем
            размещения дополнительных обыкновенных акций АО «Самокарт», для чего
            определить: <br />
            1) категория (тип), номинальная стоимость размещаемых дополнительных
            акций: акции обыкновенные номинальной стоимостью 0,01 (Ноль рублей
            01 копейка) рублей каждая; <br />
            2) количество размещаемых дополнительных обыкновенных акций в
            пределах количества объявленных акций данной категории: 6 868 805
            (шесть миллионов восемьсот шестьдесят восемь тысяч восемьсот пять)
            штук; <br />
            3) способ размещения дополнительных обыкновенных акций: закрытая
            подписка; <br />
            4) цена размещения дополнительных обыкновенных акций, в том числе
            лицам, имеющим преимущественное право приобретения дополнительных
            обыкновенных акций: 16,10 рублей (Шестнадцать рублей 10 копеек) за
            одну акцию; <br />
            5) форма оплаты размещаемых акций: размещаемые дополнительные
            обыкновенные акции оплачиваются денежными средствами в валюте РФ (в
            рублях). Возможна оплата размещаемых акций путем зачета денежных
            требований к акционерному обществу. <br />
            6) круг лиц, среди которых предполагается осуществить размещение
            дополнительных обыкновенных акций: <br />
            Варлашин Владислав Валерьевич (ИНН 231204904297) вправе приобрести
            не более 543 380 акций, <br />
            Литвиненко Сергей Иванович (ИНН 231297474534) вправе приобрести не
            более 279 454 акций, <br />
            Инвестиционное товарищество "ТилТех Капитал" (от имени и в интересах
            Инвестиционного товарищества "ТилТех Капитал" размещаемые
            обыкновенные акции приобретает Управляющий товарищ - Акционерное
            общество "ТИЛТЕХ", ОГРН 1167746893995), созданное на основании
            договора инвестиционного товарищества, удостоверенного нотариусом
            города Москвы Горбатенко Николаем Владимировичем 11 ноября 2016 года
            за реестровым номером 2-2758 (регистрационный номер договора в
            реестре нотариальных действий единой информационной системе
            нотариата 262942247) вправе приобрести не более 6 045 971 акции;{" "}
            <br />
            7) расходы, связанные с внесением записи о зачислении размещаемых
            акций на лицевой счет (счет депо) приобретателя, несет приобретатель
            акций; <br />
            8) иные условия размещения акций, в т.ч. срок размещения, порядок
            заключения договоров в ходе размещения, будут определены документом,
            содержащим условия размещения ценных бумаг; <br />
            9) по результатам размещения дополнительных обыкновенных акций
            внести соответствующие изменения в Устав Общества.
            <br />
            <br />
            <b>
              По вопросу повестки дня №2: «Увеличение уставного капитала
              Общества путем размещения привилегированных акций тип первый».
            </b>
            <br />
            <b>Результаты голосования и формулировки принятых решений:</b>{" "}
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            2. Увеличить уставный капитал Акционерного общества «Самокарт» путем
            размещения привилегированных акций тип первый АО «Самокарт», для
            чего определить: <br />
            1) категория (тип), номинальная стоимость размещаемых акций: акции
            привилегированные тип первый номинальной стоимостью 0,01 (Ноль
            рублей 01 копейка) рублей каждая; <br />
            2) количество размещаемых привилегированных акций тип первый в
            пределах количества объявленных акций данной категории: 2 794 209
            (Два миллиона семьсот девяносто четыре тысячи двести девять) штук;{" "}
            <br />
            3) способ размещения привилегированных акций тип первый: закрытая
            подписка;
            <br />
            4) цена размещения привилегированных акций тип первый: 46,52 рублей
            (Сорок шесть рублей 52 копейки) за одну акцию; <br />
            5) форма оплаты размещаемых акций: размещаемые привилегированные
            акции тип первый оплачиваются денежными средствами в валюте РФ (в
            рублях). Возможна оплата размещаемых акций путем зачета денежных
            требований к акционерному обществу. <br />
            6) круг лиц, среди которых предполагается осуществить размещение
            привилегированных акций тип первый: Инвестиционное товарищество
            "Система СмартТех" (от имени и в интересах Инвестиционного
            товарищества "Система СмартТех" размещаемые привилегированные акции
            тип первый приобретает Управляющий товарищ - Общество с ограниченной
            ответственностью Управляющая компания "Система СмартТех", ОГРН
            1197746727441), созданное на основании договора инвестиционного
            товарищества, удостоверенного нотариусом города Москвы Ралько
            Василием Васильевичем 12 ноября 2020 года в реестре
            №77/2317-н/77-2020-4-1274 (регистрационный номер договора в единой
            информационной системе нотариата 172747157); <br />
            7) расходы, связанные с внесением записи о зачислении размещаемых
            акций на лицевой счет (счет депо) приобретателя, несет приобретатель
            акций; <br />
            8) иные условия размещения акций, в т.ч. срок размещения, порядок
            заключения договоров в ходе размещения, будут определены документом,
            содержащим условия размещения ценных бумаг; <br />
            9) по результатам размещения привилегированных акций тип первый
            внести соответствующие изменения в Устав Общества.
            <br />
            <br />
            <b>
              По вопросу повестки дня №3: «Увеличение уставного капитала
              Общества путем размещения привилегированных акций тип второй».
            </b>
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            3. Увеличить уставный капитал Акционерного общества «Самокарт» путем
            размещения привилегированных акций тип второй АО «Самокарт», для
            чего определить: <br />
            1) категория (тип), номинальная стоимость размещаемых акций: акции
            привилегированные тип второй номинальной стоимостью 0,01 (Ноль
            рублей 01 копейка) рублей каждая; <br />
            2) количество размещаемых привилегированных акций тип второй в
            пределах количества объявленных акций данной категории: 1 369 429
            (Один миллион триста шестьдесят девять тысяч четыреста двадцать
            девять) штук; <br />
            3) способ размещения привилегированных акций тип второй: закрытая
            подписка; <br />
            4) цена размещения привилегированных акций тип второй: 16,30 рублей
            (Шестнадцать рублей 30 копеек) за одну акцию. <br />
            5) форма оплаты размещаемых акций: размещаемые привилегированные
            акции тип второй оплачиваются денежными средствами в валюте РФ (в
            рублях). Возможна оплата размещаемых акций путем зачета денежных
            требований к акционерному обществу. <br />
            6) круг лиц, среди которых предполагается осуществить размещение
            привилегированных акций тип второй: Инвестиционное товарищество
            "ТилТех Капитал" (от имени и в интересах Инвестиционного
            товарищества "ТилТех Капитал" размещаемые привилегированные акции
            тип второй приобретает Управляющий товарищ - Акционерное общество
            "ТИЛТЕХ", ОГРН 1167746893995), созданное на основании договора
            инвестиционного товарищества, удостоверенного нотариусом города
            Москвы Горбатенко Николаем Владимировичем 11 ноября 2016 года за
            реестровым номером 2-2758 (регистрационный номер договора в реестре
            нотариальных действий единой информационной системе нотариата
            262942247); <br />
            7) расходы, связанные с внесением записи о зачислении размещаемых
            акций на лицевой счет (счет депо) приобретателя, несет приобретатель
            акций; <br />
            8) иные условия размещения акций, в т.ч. срок размещения, порядок
            заключения договоров в ходе размещения, будут определены документом,
            содержащим условия размещения ценных бумаг; <br />
            9) по результатам размещения привилегированных акций тип второй
            внести соответствующие изменения в Устав Общества.
            <br />
            <br />
            <b>
              По вопросу повестки дня №4: «Об одобрении заключения Обществом
              дополнительного соглашения № 1 к договору займа от 19.06.2021 г.».
            </b>
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>
                      <b>12 557 800 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <b>Формулировка решения, принятого общим собранием:</b>
            <br />
            4. Одобрить совершение Обществом крупной сделки, предметом которой
            является имущество, стоимость которого составляет более 50%
            балансовой стоимости активов Общества – заключение Обществом
            дополнительного соглашения № 1 к договору займа от 19.06.2021 г.
            (далее – "Договор"):
            <br />
            - лица, являющиеся стороной сделки и ее выгодоприобретателями:
            Акционерное общество "Самокарт" ОГРН: 1192375009276, ИНН: 2310212217
            (заемщик) и Общество с ограниченной ответственностью Управляющая
            компания "Система СмартТех", юридическое лицо, созданное и
            действующее в соответствии с законодательством Российской Федерации,
            ОГРН 1197746727441, ИНН 7708367753, (адрес) место нахождения:
            129090, город Москва, переулок Большой Балканский, дом 20, строение
            1, эт. 2 пом. 25, выступающее в качестве управляющего товарища
            Инвестиционного товарищества "Система СмартТех", созданного на
            основании договора инвестиционного товарищества, удостоверенного
            нотариусом города Москвы Ралько Василием Васильевичем 12.11.2020
            года в реестре № 77/2317-н/77-2020-4-1274 (регистрационный номер
            договора в Единой Информационной Системе Нотариата 172747157), место
            нахождения нотариуса по адресу: 108812, г. Москва, 22 км Киевское
            шоссе, д. 6, стр. 1 (займодавец);
            <br />
            - предмет сделки: внесение изменений в Договор (Договор
            предусматривает передачу заемщику в собственность суммы денежных
            средств и возврат денежных средств заемщиком займодавцу (проценты на
            сумму займа начисляются в предусмотренных Договором случаях)), в
            частности, изменение суммы займа, порядка ее предоставления и срока
            погашения займа;
            <br />
            - цена сделки: 129 986 603 (сто двадцать девять миллионов девятьсот
            восемьдесят шесть тысяч шестьсот три) рубля;
            <br />
            - иные условия сделки содержатся в форме дополнительного соглашения
            № 1 к Договору займа от 19.06.2021 г.
            <br />
            <br />
            <p>
              <b>Председательствующий на общем собрании </b>
              <br />
              <b>Литвиненко Сергей Иванович</b>
            </p>
            <br />
          </div>
          <hr />

          <div className="text">
            <p>24.10.2021 </p>
            <br />
            <b>
              Сообщение
              <br />о проведении внеочередного Общего собрания акционеров
              Акционерного общества "Самокарт"
            </b>
            Акционерное общество "Самокарт" (далее – АО "Самокарт", Общество)
            сообщает акционерам Общества о проведении внеочередного общего
            собрания акционеров.
            <br />
            <u>Полное фирменное наименование Общества:</u> Акционерное общество
            "Самокарт".
            <br />
            <u>Место нахождения Общества:</u> РФ, г. Краснодар.
            <br />
            <u>Адрес Общества:</u> г. Краснодар, ул. Стахановская, д. 15,
            корп.3, ком.2. <br />
            <u>
              Дата окончания приема заполненных бюллетеней для голосования (дата
              проведения собрания):
            </u>{" "}
            15 ноября 2021 года. <br />
            <u>
              Дата, на которую определяются (фиксируются) лица, имеющие право на
              участие в общем собрании акционеров:
            </u>{" "}
            23 октября 2021 года. <br />
            <u>
              Категории (типы) акций, владельцы которых имеют право голоса по
              всем вопросам повестки дня общего собрания акционеров:
            </u>{" "}
            обыкновенные. <br />
            <u>
              Почтовый адрес, по которому должны направляться заполненные
              бюллетени для голосования:
            </u>{" "}
            350051, г. Краснодар, ул. Стахановская, д. 15, корп.3, комната 2.{" "}
            <br />
            При направлении заполненных документов в общество, представителям
            акционеров необходимо приложить документы, подтверждающие их
            полномочия для осуществления голосования (их копии,
            засвидетельствованные (удостоверенные) в порядке, предусмотренном
            законодательством Российской Федерации). <br />
            <u>Вид собрания:</u> внеочередное. <br />
            <u>Форма проведения собрания:</u> Заочное голосование. <br />
            <br />
            <b>
              Вопросы, включенные в повестку дня внеочередного общего собрания
              акционеров Общества:
            </b>
            <br />
            1. Увеличение уставного капитала Общества путем размещения
            дополнительных обыкновенных акций. <br />
            2. Увеличение уставного капитала Общества путем размещения
            привилегированных акций тип первый. <br />
            3. Увеличение уставного капитала Общества путем размещения
            привилегированных акций тип второй. <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: г. Краснодар, ул. Стахановская, д.
            15, корп. 3, комната 2 в течение 20 дней до даты проведения общего
            собрания акционеров, в рабочие дни с понедельника по пятницу с 09
            часов 00 минут до 17 часов 45 минут (по местному времени).
            <br />
            <a href="/Bulletin_24102021.doc">Бюллетень голосования</a>
            <br />
            <br />
            <p>01.07.2021 </p>
            <br />
            <b>
              {" "}
              ОТЧЕТ <br />
              об итогах голосования на годовом общем собрании акционеров
              Акционерного общества «Самокарт»
            </b>
            <p>
              г. Краснодар «01» июля 2021 <br />
              Полное фирменное наименование общества: Акционерное общество
              "Самокарт" (далее именуемое Общество).
            </p>
            <p>Место нахождения Общества: РФ, г. Краснодар.</p>
            <p>
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, корп. 3, ком. 2.
            </p>
            <p>Вид общего собрания: годовое.</p>
            <p>Форма проведения общего собрания: заочное голосование.</p>
            <p>
              Дата определения (фиксации) лиц, имевших право на участие в общем
              собрании акционеров Общества: «06» июня 2021 г.
            </p>
            <p>Дата проведения общего собрания: «29» июня 2021 г.</p>
            <p>
              Сведения о регистраторе, выполнявшем функции счетной комиссии:
            </p>
            <p>Полное фирменное наименование: Акционерное общество "Реестр".</p>
            <p>Место нахождения: Российская Федерация, г. Москва.</p>
            <p>
              Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
              стр. 1.
            </p>
            <p>
              Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
            </p>
            <br />
            <b>Повестка дня собрания:</b>
            <p>
              1. Утверждение годового отчета, годовой бухгалтерской (финансовой)
              отчетности Общества за 2020 год.
            </p>
            <p>
              2. Распределение прибыли (в том числе выплата (объявление)
              дивидендов) и убытков общества по результатам 2020 отчетного года{" "}
            </p>
            <p>3. Избрание членов Совета директоров Общества.</p>
            <p>4. Утверждение аудитора Общества.</p>
            <p>
              5. Последующее утверждение о возложении обязанности по оплате
              расходов, связанных с внесением записей о зачислении размещаемых
              ценных бумаг на лицевые счета их первых владельцев
              (приобретателей).
            </p>
            <p>6. Утверждение Устава Общества в новой редакции.</p>
            <p>
              7. Принятие решений о последующем одобрении совершенных Обществом
              крупных сделок стоимость которых более 50% стоимости активов
              Общества.
            </p>
            <p>
              <b>Результаты голосования и формулировки принятых решений</b>
              <b>:</b>
            </p>
            <p>
              <b>По вопросу повестки дня №1:</b>
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              1. Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
              отчетность за 2020 отчетный год.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              1. Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
              отчетность за 2020 отчетный год.
            </p>
            <p>
              <b>По вопросу повестки дня №</b>
              <b>2:</b>
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              2. Дивиденды за 2020 год по обыкновенным бездокументарным акциям
              общества не выплачивать, в связи с получением убытка по
              результатам деятельности АО &quot;Самокарт&quot; в 2020 году.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              2. Дивиденды за 2020 год по обыкновенным бездокументарным акциям
              общества не выплачивать, в связи с получением убытка по
              результатам деятельности АО &quot;Самокарт&quot; в 2020 году.
            </p>
            <p>
              <b>По вопросу повестки дня №3:</b>
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>3. Избрать членом Совета директоров Общества:</p>
            <p>1. Топоров Александр Викторович </p>
            <p>2. Кобяков Илья Сергеевич </p>
            <p>3. Левицкий Роман Борисович </p>
            <p>4. Литвиненко Сергей Иванович </p>
            <p>5. Остапенко Владимир Валерьевич </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми по данному вопросу повестки дня
                      обладали все лица, включенные в список лиц, имевших право
                      на участие в общем собрании
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>88 638 325</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>88 638 325</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми по данному вопросу повестки дня
                      обладали лица, принявшие участие в общем собрании
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>88 638 325</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Голосование кумулятивное</b>
              <b>. 5 </b>
              <b>вакансий</b>
              <b>.</b>
            </p>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <p>
              Число голосов &laquo;ЗА&raquo;, распределенных среди кандидатов:{" "}
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Ф.И.О. кандидата</p>
                  </td>
                  <td>
                    <p>
                      <b>Число голосов</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Топоров Александр Викторович</p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 633</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Кобяков Илья Сергеевич</p>
                  </td>
                  <td>
                    <p>
                      <b>35 115 793</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Левицкий Роман Борисович</p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 633</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Литвиненко Сергей Иванович</p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 633</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Остапенко Владимир Валерьевич</p>
                  </td>
                  <td>
                    <p>
                      <b>13 380 633</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ в отношении всех кандидатов&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ в отношении всех кандидатов&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>3. Избрать членом Совета директоров Общества:</p>
            <p>1. Топоров Александр Викторович </p>
            <p>2. Кобяков Илья Сергеевич </p>
            <p>3. Левицкий Роман Борисович </p>
            <p>4. Литвиненко Сергей Иванович </p>
            <p>5. Остапенко Владимир Валерьевич </p>
            <p>
              <b>По вопросу повестки дня №4:</b>
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              4. Утвердить аудитором Общества на 2021 год ООО &quot;Гик
              Аудит&quot; (ИНН 2310181350).
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              4. Утвердить аудитором Общества на 2021 год ООО &quot;Гик
              Аудит&quot; (ИНН 2310181350).
            </p>
            <p>
              <b>По вопросу повестки дня №</b>
              <b>5:</b>
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              5. Расходы, связанные с внесением записей о зачислении размещаемых
              ценных бумаг на лицевые счета их первых владельцев
              (приобретателей), возложить на первых владельцев (приобретателей)
              акций дополнительного выпуска (регистрационный номер
              дополнительного выпуска ценных бумаг 1-01-09986-Р-002D от
              21.01.2021 г.).
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              5. Расходы, связанные с внесением записей о зачислении размещаемых
              ценных бумаг на лицевые счета их первых владельцев
              (приобретателей), возложить на первых владельцев (приобретателей)
              акций дополнительного выпуска (регистрационный номер
              дополнительного выпуска ценных бумаг 1-01-09986-Р-002D от
              21.01.2021 г.).
            </p>
            <p>
              <b>По вопросу повестки дня №</b>
              <b>6:</b>
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>6. Утвердить Устав Общества в новой редакции.</p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>6. Утвердить Устав Общества в новой редакции.</p>
            <p>
              <b>По вопросу повестки дня №</b>
              <b>7:</b>
            </p>
            <p>
              <b>7.1.</b>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              7.1. Одобрить совершенную обществом крупную сделку (договор
              займа), предметом которой является имущество, стоимость которого
              составляет более 50 процентов балансовой стоимости активов
              общества. Лица, являющиеся стороной сделки и ее
              выгодоприобретателями: Общество с ограниченной ответственностью
              Управляющая компания &quot;Система СмартТех&quot;, юридическое
              лицо, созданное и действующее в соответствии с законодательством
              Российской Федерации, ОГРН 1197746727441, с местом нахождения по
              адресу: 129090, г. Москва, переулок Большой Балканский, дом 20,
              строение 1, этаж 2, помещение 25, в лице Генерального директора
              Арутюновой Карины Георгиевны, действующей на основании Устава,
              выступающее в качестве Управляющего Товарища Инвестиционного
              товарищества &quot;Система СмартТех&quot;, созданного на основании
              договора инвестиционного товарищества, удостоверенного нотариусом
              города Москвы Ралько Василием Васильевичем 12 ноября 2020 года в
              реестре №77/2317-н/77-2020-4-1274 (регистрационный номер договора
              в Единой Информационной Системе Нотариата 172747157), место
              нахождения нотариуса по адресу: 108812, г. Москва, 22км Киевское
              шоссе, д.6, стр. 1 (&quot;Заимодавец&quot;), и Акционерное
              общество &quot;Самокарт&quot;, юридическое лицо, учрежденное в
              соответствии с законодательством Российской Федерации,
              зарегистрированное Межрайонной инспекцией Федеральной налоговой
              службы №16 по Краснодарскому краю за ОГРН 1192375009276, ИНН
              2310212217, с местом нахождения по адресу: 350051, Краснодарский
              край, г. Краснодар, ул. Стахановская, д. 15, корпус 3, комната 2,
              в лице Генерального директора Евдакова Кирилла Сергеевича,
              действующего на основании устава (&quot;Заемщик&quot;). Цена и
              Предмет сделки - Заимодавец обязуется передать в собственность
              Заемщика денежные средства в размере 100 000 000 (Сто миллионов)
              рублей, а Заемщик обязуется по выбору Заимодавца (в соответствии
              со статьей 308.1 ГК РФ) либо возвратить полученные денежные
              средства и проценты за их пользование, либо осуществить Зачет
              &ndash; в сроки, в порядке и на условиях, предусмотренных
              Договором. Заимодавец обязуется предоставить Заемщику часть Суммы
              займа в размере 65 000 *** (Шестьдесят пять миллионов) рублей не
              позднее 5 (пяти) рабочих дней с даты подписания Договора, а часть
              Суммы займа в размере 35 *** 000 (Тридцать пять миллионов) рублей
              &ndash; не позднее 31 августа 2021 года. Сумма займа должна быть
              предоставлена Заимодавцем в рублях посредством банковского
              перевода на расчетный счет Заемщика. Сумма займа предоставляется
              Заемщику на срок 5 (пять) месяцев с даты подписания Договора
              займа. Иные существенные условия сделки - в случае неосуществления
              Сторонами Зачета до истечения Срока погашения, либо в случае
              наступления любого События дефолта, на Сумму займа подлежат
              начислению и уплате проценты в размере 10 % (десять процентов)
              годовых, начиная с даты предоставления Заемщику Суммы займа и до
              даты возврата Суммы займа, исходя из расчета 365 дней в году.
              Проценты не подлежат капитализации.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              7.1. Одобрить совершенную обществом крупную сделку (договор
              займа), предметом которой является имущество, стоимость которого
              составляет более 50 процентов балансовой стоимости активов
              общества. Лица, являющиеся стороной сделки и ее
              выгодоприобретателями: Общество с ограниченной ответственностью
              Управляющая компания &quot;Система СмартТех&quot;, юридическое
              лицо, созданное и действующее в соответствии с законодательством
              Российской Федерации, ОГРН 1197746727441, с местом нахождения по
              адресу: 129090, г. Москва, переулок Большой Балканский, дом 20,
              строение 1, этаж 2, помещение 25, в лице Генерального директора
              Арутюновой Карины Георгиевны, действующей на основании Устава,
              выступающее в качестве Управляющего Товарища Инвестиционного
              товарищества &quot;Система СмартТех&quot;, созданного на основании
              договора инвестиционного товарищества, удостоверенного нотариусом
              города Москвы Ралько Василием Васильевичем 12 ноября 2020 года в
              реестре №77/2317-н/77-2020-4-1274 (регистрационный номер договора
              в Единой Информационной Системе Нотариата 172747157), место
              нахождения нотариуса по адресу: 108812, г. Москва, 22км Киевское
              шоссе, д.6, стр. 1 (&quot;Заимодавец&quot;), и Акционерное
              общество &quot;Самокарт&quot;, юридическое лицо, учрежденное в
              соответствии с законодательством Российской Федерации,
              зарегистрированное Межрайонной инспекцией Федеральной налоговой
              службы №16 по Краснодарскому краю за ОГРН 1192375009276, ИНН
              2310212217, с местом нахождения по адресу: 350051, Краснодарский
              край, г. Краснодар, ул. Стахановская, д. 15, корпус 3, комната 2,
              в лице Генерального директора Евдакова Кирилла Сергеевича,
              действующего на основании устава (&quot;Заемщик&quot;). Цена и
              Предмет сделки - Заимодавец обязуется передать в собственность
              Заемщика денежные средства в размере 100 000 000 (Сто миллионов)
              рублей, а Заемщик обязуется по выбору Заимодавца (в соответствии
              со статьей 308.1 ГК РФ) либо возвратить полученные денежные
              средства и проценты за их пользование, либо осуществить Зачет
              &ndash; в сроки, в порядке и на условиях, предусмотренных
              Договором. Заимодавец обязуется предоставить Заемщику часть Суммы
              займа в размере 65 000 000 (Шестьдесят пять миллионов) рублей не
              позднее 5 (пяти) рабочих дней с даты подписания Договора, а часть
              Суммы займа в размере 35 000 000 (Тридцать пять миллионов) рублей
              &ndash; не позднее 31 августа 2021 года. Сумма займа должна быть
              предоставлена Заимодавцем в рублях посредством банковского
              перевода на расчетный счет Заемщика. Сумма займа предоставляется
              Заемщику на срок 5 (пять) месяцев с даты подписания Договора
              займа. Иные существенные условия сделки - в случае неосуществления
              Сторонами Зачета до истечения Срока погашения, либо в случае
              наступления любого События дефолта, на Сумму займа подлежат
              начислению и уплате проценты в размере 10 % (десять процентов)
              годовых, начиная с даты предоставления Заемщику Суммы займа и до
              даты возврата Суммы займа, исходя из расчета 365 дней в году.
              Проценты не подлежат капитализации.
            </p>
            <p>
              <b>7.2.</b>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              7.2. Одобрить совершенную обществом крупную сделку (договор займа
              № ИТ-40/20 от 19.08.2020 г.), предметом которой является
              имущество, стоимость которого составляет более 50 процентов
              балансовой стоимости активов общества. Лица, являющиеся стороной
              сделки и ее выгодоприобретателями: Акционерное общество
              &laquo;ТИЛТЕХ&raquo;, действующее в качестве управляющего товарища
              инвестиционного товарищества &laquo;ТИЛТЕХ КАПИТАЛ&raquo; по
              договору инвестиционного товарищества &laquo;ТИЛТЕХ КАПИТАЛ&raquo;
              (рег. № 26294247 в реестре нотариальных действий ЕИСН),
              удостоверенному нотариусом города Москвы Горбатенко Николаем
              Владимировичем 11.11.2016 г. (далее &ndash; &laquo;ДИТ&raquo;), в
              лице Генерального директора Лисицына Е.В., действующего на
              основании Устава (Займодавец), и Акционерное общество
              &quot;Самокарт&quot; в лице Генерального директора Евдакова
              Кирилла Сергеевича, действующего на основании Устава (Заемщик).
              Цена сделки &mdash; 70 000 000 (Семьдесят миллионов) рублей.
              Предмет сделки &mdash; Займодавец предоставляет Заемщику сумму
              займа, а Заемщик обязуется возвратить Займодавцу Сумму займа и
              уплатить проценты за пользование Суммой займа в порядке и на
              условиях, предусмотренных Договором. Заемщик обязуется возвратить
              Займодавцу Сумму займа не позднее 30.11.2020 г., при условии
              закрытия Сделки. Под закрытием Сделки Стороны понимают переход
              согласованного Сторонами количества акций АО
              &laquo;Самокарт&raquo; в собственность Займодавца (участников ДИТ)
              не позднее 30.11.2020 г. Иные существенные условия сделки &mdash;
              в случае если Сделка не состоится в указанный в п. 2.2. Договора
              срок, Заемщик обязуется возвратить Сумму займа не позднее
              19.08.2021 г. В этом случае на Сумму займа начисляются проценты в
              размере 8% годовых.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              7.2. Одобрить совершенную обществом крупную сделку (договор займа
              № ИТ-40/20 от 19.08.2020 г.), предметом которой является
              имущество, стоимость которого составляет более 50 процентов
              балансовой стоимости активов общества. Лица, являющиеся стороной
              сделки и ее выгодоприобретателями: Акционерное общество
              &laquo;ТИЛТЕХ&raquo;, действующее в качестве управляющего товарища
              инвестиционного товарищества &laquo;ТИЛТЕХ КАПИТАЛ&raquo; по
              договору инвестиционного товарищества &laquo;ТИЛТЕХ КАПИТАЛ&raquo;
              (рег. № 26294247 в реестре нотариальных действий ЕИСН),
              удостоверенному нотариусом города Москвы Горбатенко Николаем
              Владимировичем 11.11.2016 г. (далее &ndash; &laquo;ДИТ&raquo;), в
              лице Генерального директора Лисицына Е.В., действующего на
              основании Устава (Займодавец), и Акционерное общество
              &quot;Самокарт&quot; в лице Генерального директора Евдакова
              Кирилла Сергеевича, действующего на основании Устава (Заемщик).
              Цена сделки &mdash; 70 000 000 (Семьдесят миллионов) рублей.
              Предмет сделки &mdash; Займодавец предоставляет Заемщику сумму
              займа, а Заемщик обязуется возвратить Займодавцу Сумму займа и
              уплатить проценты за пользование Суммой займа в порядке и на
              условиях, предусмотренных Договором. Заемщик обязуется возвратить
              Займодавцу Сумму займа не позднее 30.11.2020 г., при условии
              закрытия Сделки. Под закрытием Сделки Стороны понимают переход
              согласованного Сторонами количества акций АО
              &laquo;Самокарт&raquo; в собственность Займодавца (участников ДИТ)
              не позднее 30.11.2020 г. Иные существенные условия сделки &mdash;
              в случае если Сделка не состоится в указанный в п. 2.2. Договора
              срок, Заемщик обязуется возвратить Сумму займа не позднее
              19.08.2021 г. В этом случае на Сумму займа начисляются проценты в
              размере 8% годовых.
            </p>
            <p>
              <b>7.3.</b>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              7.3. Одобрить совершенную обществом крупную сделку (договор займа
              № ИТ-45/20 от 25.12.2020 г.), предметом которой является
              имущество, стоимость которого составляет более 50 процентов
              балансовой стоимости активов общества. Лица, являющиеся стороной
              сделки и ее выгодоприобретателями: Акционерное общество
              &laquo;ТИЛТЕХ&raquo;, действующее в качестве управляющего товарища
              инвестиционного товарищества &laquo;ТИЛТЕХ КАПИТАЛ&raquo; по
              договору инвестиционного товарищества &laquo;ТИЛТЕХ КАПИТАЛ&raquo;
              (рег. № 26294247 в реестре нотариальных действий ЕИСН),
              удостоверенному нотариусом города Москвы Горбатенко Николаем
              Владимировичем 11.11.2016 г. (далее &ndash; &laquo;ДИТ&raquo;), в
              лице Генерального директора Лисицына Е.В., действующего на
              основании Устава (Займодавец), и Акционерное общество
              &quot;Самокарт&quot; в лице Генерального директора Евдакова
              Кирилла Сергеевича, действующего на основании Устава (Заемщик).
              Цена сделки &mdash; 50 000 000 (Пятьдесят миллионов) рублей.
              Предмет сделки &mdash; Займодавец предоставляет Заемщику сумму
              займа, а Заемщик обязуется возвратить Займодавцу Сумму займа и
              уплатить проценты за пользование Суммой займа в порядке и на
              условиях, предусмотренных Договором. Заемщик обязуется возвратить
              Займодавцу Сумму займа не позднее 01.04.2021 г., при условии
              закрытия Сделки. Под закрытием Сделки Стороны понимают переход
              согласованного Сторонами количества акций АО
              &laquo;Самокарт&raquo; в собственность Займодавца (участников ДИТ)
              не позднее 01.04.2021 г. Иные существенные условия сделки &mdash;
              в случае если Сделка не состоится в указанный в п. 2.2. Договора
              срок, Заемщик обязуется возвратить Сумму займа не позднее
              19.08.2021 г. В этом случае на Сумму займа начисляются проценты в
              размере 8% годовых.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              7.3. Одобрить совершенную обществом крупную сделку (договор займа
              № ИТ-45/20 от 25.12.2020 г.), предметом которой является
              имущество, стоимость которого составляет более 50 процентов
              балансовой стоимости активов общества. Лица, являющиеся стороной
              сделки и ее выгодоприобретателями: Акционерное общество
              &laquo;ТИЛТЕХ&raquo;, действующее в качестве управляющего товарища
              инвестиционного товарищества &laquo;ТИЛТЕХ КАПИТАЛ&raquo; по
              договору инвестиционного товарищества &laquo;ТИЛТЕХ КАПИТАЛ&raquo;
              (рег. № 26294247 в реестре нотариальных действий ЕИСН),
              удостоверенному нотариусом города Москвы Горбатенко Николаем
              Владимировичем 11.11.2016 г. (далее &ndash; &laquo;ДИТ&raquo;), в
              лице Генерального директора Лисицына Е.В., действующего на
              основании Устава (Займодавец), и Акционерное общество
              &quot;Самокарт&quot; в лице Генерального директора Евдакова
              Кирилла Сергеевича, действующего на основании Устава (Заемщик).
              Цена сделки &mdash; 50 000 000 (Пятьдесят миллионов) рублей.
              Предмет сделки &mdash; Займодавец предоставляет Заемщику сумму
              займа, а Заемщик обязуется возвратить Займодавцу Сумму займа и
              уплатить проценты за пользование Суммой займа в порядке и на
              условиях, предусмотренных Договором. Заемщик обязуется возвратить
              Займодавцу Сумму займа не позднее 01.04.2021 г., при условии
              закрытия Сделки. Под закрытием Сделки Стороны понимают переход
              согласованного Сторонами количества акций АО
              &laquo;Самокарт&raquo; в собственность Займодавца (участников ДИТ)
              не позднее 01.04.2021 г. Иные существенные условия сделки &mdash;
              в случае если Сделка не состоится в указанный в п. 2.2. Договора
              срок, Заемщик обязуется возвратить Сумму займа не позднее
              19.08.2021 г. В этом случае на Сумму займа начисляются проценты в
              размере 8% годовых.
            </p>
            <p>
              <b>7.4.</b>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              7.4. Одобрить совершенные обществом несколько взаимосвязанных
              сделок (договор займа № б/н от 15.12.2020 г. и договор займа № б/н
              от 22.12.2020 г.), предметом которой является имущество, стоимость
              которого составляет более 50 процентов балансовой стоимости
              активов общества. Лица, являющиеся стороной сделки и ее
              выгодоприобретателями: Общество с ограниченной ответственностью
              &quot;Тилтех Капитал&quot; в лице Генерального директора Кобяков
              Илья Сергеевич действующего на основании Устава (Займодавец), и
              Акционерное общество &quot;Самокарт&quot; в лице Генерального
              директора Евдакова Кирилла Сергеевича, действующего на основании
              Устава (Заемщик). Предмет сделки &mdash; Займодавец предоставляет
              Заемщику сумму займа, а Заемщик обязуется возвратить Займодавцу
              Сумму займа и уплатить проценты за пользование Суммой займа в
              порядке и на условиях, предусмотренных Договором. Цена сделки
              &mdash;12 000 000 (Двенадцать миллионов) рублей. Заемщик обязуется
              возвратить Займодавцу Сумму займа не позднее 30.04.2021 г., при
              условии закрытия Сделки. Иные существенные условия сделки &mdash;
              в случае если Сделка не состоится в указанный в п. 2.2. Договора
              срок, Заемщик обязуется возвратить Сумму займа не позднее
              22.12.2021 г. В этом случае на Сумму займа начисляются проценты в
              размере 10% годовых.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              7.4. Одобрить совершенные обществом несколько взаимосвязанных
              сделок (договор займа № б/н от 15.12.2020 г. и договор займа № б/н
              от 22.12.2020 г.), предметом которой является имущество, стоимость
              которого составляет более 50 процентов балансовой стоимости
              активов общества. Лица, являющиеся стороной сделки и ее
              выгодоприобретателями: Общество с ограниченной ответственностью
              &quot;Тилтех Капитал&quot; в лице Генерального директора Кобяков
              Илья Сергеевич действующего на основании Устава (Займодавец), и
              Акционерное общество &quot;Самокарт&quot; в лице Генерального
              директора Евдакова Кирилла Сергеевича, действующего на основании
              Устава (Заемщик). Предмет сделки &mdash; Займодавец предоставляет
              Заемщику сумму займа, а Заемщик обязуется возвратить Займодавцу
              Сумму займа и уплатить проценты за пользование Суммой займа в
              порядке и на условиях, предусмотренных Договором. Цена сделки
              &mdash;12 000 000 (Двенадцать миллионов) рублей. Заемщик обязуется
              возвратить Займодавцу Сумму займа не позднее 30.04.2021 г., при
              условии закрытия Сделки. Иные существенные условия сделки &mdash;
              в случае если Сделка не состоится в указанный в п. 2.2. Договора
              срок, Заемщик обязуется возвратить Сумму займа не позднее
              22.12.2021 г. В этом случае на Сумму займа начисляются проценты в
              размере 10% годовых.
            </p>
            <p>
              <b>7.5.</b>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              7.5. Одобрить совершенную обществом крупную сделку (договор №
              30/04-2020 от 30.04.2020 г.), предметом которой является
              имущество, стоимость которого составляет более 50 процентов
              балансовой стоимости активов общества. Лица, являющиеся стороной
              сделки и ее выгодоприобретателями: Общество с ограниченной
              ответственностью &quot;Разработка и Аналитика&quot;, в лице
              Директора Куркова Виктория Сергеевна, действующего на основании
              Устава (Исполнитель), и Акционерное общество &quot;Самокарт&quot;
              в лице Генерального директора Евдакова Кирилла Сергеевича,
              действующего на основании Устава (Заказчик). Предмет сделки
              &mdash; Исполнитель обязуется по заданию Заказчика выполнить
              следующие работы (услуги) по разработке программного обеспечения
              для сервиса транспортных перевозок Checkbox https://checkbox.ru
              для электронно- вычислительных устройств и передать результат этих
              работ (услуг) Заказчику. Цена сделки &mdash; до 5 500 000 (Пять
              миллионов пятьсот тысяч) рублей. Срок выполнения работ по
              настоящему Договору &mdash; не позднее 15.11.2020 г.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              7.5. Одобрить совершенную обществом крупную сделку (договор №
              30/04-2020 от 30.04.2020 г.), предметом которой является
              имущество, стоимость которого составляет более 50 процентов
              балансовой стоимости активов общества. Лица, являющиеся стороной
              сделки и ее выгодоприобретателями: Общество с ограниченной
              ответственностью &quot;Разработка и Аналитика&quot;, в лице
              Директора Куркова Виктория Сергеевна, действующего на основании
              Устава (Исполнитель), и Акционерное общество &quot;Самокарт&quot;
              в лице Генерального директора Евдакова Кирилла Сергеевича,
              действующего на основании Устава (Заказчик). Предмет сделки
              &mdash; Исполнитель обязуется по заданию Заказчика выполнить
              следующие работы (услуги) по разработке программного обеспечения
              для сервиса транспортных перевозок Checkbox https://checkbox.ru
              для электронно- вычислительных устройств и передать результат этих
              работ (услуг) Заказчику. Цена сделки &mdash; до 5 500 *** (Пять
              миллионов пятьсот тысяч) рублей. Срок выполнения работ по
              настоящему Договору &mdash; не позднее 15.11.2020 г.
            </p>
            <p>
              <b>7.6.</b>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              7.6. Одобрить совершенную обществом крупную сделку (договор займа
              № 2-19 от 18.02.2019 г.), предметом которой является имущество,
              стоимость которого составляет более 50 процентов балансовой
              стоимости активов общества. Лица, являющиеся стороной сделки и ее
              выгодоприобретателями: Литвиненко Сергей Иванович (ИНН:
              231297474534) (Займодавец), и Акционерное общество
              &quot;Самокарт&quot; в лице Генерального директора Евдакова
              Кирилла Сергеевича, действующего на основании Устава (Заемщик).
              Предмет сделки &mdash; Заимодавец предоставляет Заемщику в
              качестве денежный займ, на условиях срочности платности и
              возвратности денежные средства. Цена сделки &mdash; до 7 000 000
              (Семь миллионов ) рублей. Заемщик обязуется возвратить Заимодавцу
              денежные средства, составляющие полную сумму займа, путем зачета
              денежных средств при оплате обыкновенных акций при дополнительной
              эмиссии &mdash; не позднее 18.12.2019 г.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              7.6. Одобрить совершенную обществом крупную сделку (договор займа
              № 2-19 от 18.02.2019 г.), предметом которой является имущество,
              стоимость которого составляет более 50 процентов балансовой
              стоимости активов общества. Лица, являющиеся стороной сделки и ее
              выгодоприобретателями: Литвиненко Сергей Иванович (ИНН:
              231297474534) (Займодавец), и Акционерное общество
              &quot;Самокарт&quot; в лице Генерального директора Евдакова
              Кирилла Сергеевича, действующего на основании Устава (Заемщик).
              Предмет сделки &mdash; Заимодавец предоставляет Заемщику в
              качестве денежный займ, на условиях срочности платности и
              возвратности денежные средства. Цена сделки &mdash; до 7 000 000
              (Семь миллионов ) рублей. Заемщик обязуется возвратить Заимодавцу
              денежные средства, составляющие полную сумму займа, путем зачета
              денежных средств при оплате обыкновенных акций при дополнительной
              эмиссии &mdash; не позднее 18.12.2019 г.
            </p>
            <p>
              <b>7.7.</b>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>поставленного на голосование</b>
              <b>:</b>
            </p>
            <p>
              7.7. Одобрить совершенные обществом несколько взаимосвязанных
              сделок (договор займа № 1-19 от 18.02.2019 г., договор займа №
              5-19 от 08.10.2019 г., договор займа № 7-19 от 01.11.2019 г.,
              договор займа № 8-19 от 09.12.2019 г., договор займа № 9-20 от
              09.01.2020 г., договор займа № 10-20 от 04.02.2020 г., договор
              займа № 12-20 от 03.03.2020 г., договор займа № 15-20 от
              24.04.2020 г.), предметом которой является имущество, стоимость
              которого составляет более 50 процентов балансовой стоимости
              активов общества. Лица, являющиеся стороной сделки и ее
              выгодоприобретателями: Варлашин Владислав Валерьевич (ИНН:
              231204904297) (Займодавец), и Акционерное общество
              &quot;Самокарт&quot; в лице Генерального директора Евдакова
              Кирилла Сергеевича, действующего на основании Устава (Заемщик).
              Предмет сделки &mdash; Заимодавец предоставляет Заемщику в
              качестве денежный займ, на условиях срочности платности и
              возвратности денежные средства. Цена сделки &mdash; до 17 000 000
              (Семнадцать миллионов) рублей.
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24
                      &laquo;Положения об общих собраниях акционеров&raquo;
                      (утв. Банком России 16.11.2018 N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>Кворум по данному вопросу повестки дня имеется</b>
              <b>.</b>
            </p>
            <p>
              <b>Итоги голосования</b>
              <b>:</b>
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ЗА&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>17 727 665 | 100%*</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ПРОТИВ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      &laquo;ВОЗДЕРЖАЛСЯ&raquo;
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>0</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {" "}
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 &laquo;Положения об
              общих собраниях акционеров&raquo; (утв. Банком России 16.11.2018 N
              660-П).
            </p>
            <p>
              <b>Формулировка решения</b>
              <b>, </b>
              <b>принятого общим собранием</b>
              <b>:</b>
            </p>
            <p>
              7.7. Одобрить совершенные обществом несколько взаимосвязанных
              сделок (договор займа № 1-19 от 18.02.2019 г., договор займа №
              5-19 от 08.10.2019 г., договор займа № 7-19 от 01.11.2019 г.,
              договор займа № 8-19 от 09.12.2019 г., договор займа № 9-20 от
              09.01.2020 г., договор займа № 10-20 от 04.02.2020 г., договор
              займа № 12-20 от 03.03.2020 г., договор займа № 15-20 от
              24.04.2020 г.), предметом которой является имущество, стоимость
              которого составляет более 50 процентов балансовой стоимости
              активов общества. Лица, являющиеся стороной сделки и ее
              выгодоприобретателями: Варлашин Владислав Валерьевич (ИНН:
              231204904297) (Займодавец), и Акционерное общество
              &quot;Самокарт&quot; в лице Генерального директора Евдакова
              Кирилла Сергеевича, действующего на основании Устава (Заемщик).
              Предмет сделки &mdash; Заимодавец предоставляет Заемщику в
              качестве денежный займ, на условиях срочности платности и
              возвратности денежные средства. Цена сделки &mdash; до 17 000 000
              (Семнадцать миллионов) рублей.
            </p>
            <p>
              <b>Председательствующий на общем собрании </b>
              <br />
              <b>Литвиненко Сергей Иванович</b>
            </p>
            <p>
              <b>Секретарь общего собрания</b> <br />
              <b>Левицкий Ефим Романович</b>
            </p>
          </div>
          <hr />

          <div className="text">
            <p>11.06.2021</p>
            <br />
            <b>
              {" "}
              Уведомление
              <br />
              акционеров о содержании извещения о намерении продать акции
              третьему лицу
            </b>
            <br /> <br />
            В соответствии с абз. 1 п. 4 ст. 7 Федерального закона от 26.12.1995
            N 208-ФЗ "Об акционерных обществах" АО «Самокарт» сообщает о
            поступлении 10.06.2021 г. от ниже перечисленных акционеров
            «Извещение о намерении продать акции третьему лицу».
            <br />
            <b>Варлашин Владислав Валерьевич</b> извещает акционеров АО
            "Самокарт", а также само Общество о своем намерении продать
            принадлежащие на праве собственности акции АО "Самокарт" третьему
            лицу на следующих условиях:
            <br />
            Количество продаваемых акций — 992 027 (Девятьсот девяносто две
            тысячи двадцать семь) штук.
            <br />
            Номинальная стоимость каждой акции - 0,01 (Одна копейка) рублей.
            <br />
            Вид и форма акций - обыкновенные бездокументарные акции,
            государственный регистрационный номер выпуска 1-01-09986-Р.
            <br />
            Цена продажи — 30 000 000 (Тридцать миллионов) рублей.
            <br />
            <b>Левицкий Ефим Романович</b> извещает акционеров АО "Самокарт", а
            также само Общество о своем намерении продать принадлежащие на праве
            собственности акции АО "Самокарт" третьему лицу на следующих
            условиях:
            <br />
            Количество продаваемых акций — 661 351 (Шестьсот шестьдесят одна
            тысяча триста пятьдесят одна) штук.
            <br />
            Номинальная стоимость каждой акции - 0,01 (Одна копейка) рублей.
            <br />
            Вид и форма акций - обыкновенные бездокументарные акции,
            государственный регистрационный номер выпуска 1-01-09986-Р.
            <br />
            Цена продажи — 20 000 000 (Двадцать миллионов) рублей.
            <br />
            <b>Литвиненко Сергей Иванович</b> извещает акционеров АО "Самокарт",
            а также само Общество о своем намерении продать принадлежащие на
            праве собственности акции АО "Самокарт" третьему лицу на следующих
            условиях:
            <br />
            Количество продаваемых акций — 661 351 (Шестьсот шестьдесят одна
            тысяча триста пятьдесят одна) штук.
            <br />
            Номинальная стоимость каждой акции - 0,01 (Одна копейка) рублей.
            <br />
            Вид и форма акций - обыкновенные бездокументарные акции,
            государственный регистрационный номер выпуска 1-01-09986-Р.
            <br />
            Цена продажи — 20 000 000 (Двадцать миллионов) рублей.
            <br /> <br />
            Согласно п. 4.33. Устава общества в случае если акционеры Общества и
            Общество не воспользуются преимущественным правом приобретения всех
            акций, предлагаемых для продажи, в течение 30 дней со дня такого
            извещения, то акции могут быть проданы третьему лицу по цене и на
            условиях, которые сообщены Обществу и его акционерам.
            <br />
            Срок осуществления преимущественного права прекращается, если до его
            истечения от всех акционеров общества получены письменные заявления
            об использовании преимущественного права или об отказе от его
            использования.
            <br />
            Дата раскрытия на сайте общества в
            информационно-телекоммуникационной сети "Интернет" samocart.ru
            «Уведомление акционеров о содержании извещения о намерении продать
            акции третьему лицу» –11.06.2021 г.
            <br />
            За дополнительной информацией обращаться по тел. +7 (938) 865-97-58.
            <br /> <br />
            Генеральный директор АО "Самокарт"
            <br />
          </div>
          <hr />

          <div className="text">
            <p>07.06.2021</p>
            <br />
            <b>
              {" "}
              Сообщение
              <br />о проведении годового Общего собрания акционеров АО
              "САМОКАРТ"
            </b>
            <br /> <br />
            Акционерное общество "Самокарт" (далее – Общество) сообщает
            акционерам Общества о проведении годового общего собрания
            акционеров.
            <br /> <br />
            Полное фирменное наименование Общества: Акционерное общество
            "Самокарт".
            <br /> <br />
            Место нахождения Общества: г. Краснодар, ул. Стахановская, д. 15,
            корпус 3, комната 2.
            <br /> <br />
            Дата окончания приема заполненных бюллетеней для голосования (дата
            проведения собрания): 29 июня 2021 года.
            <br /> <br />
            Дата, на которую определяются (фиксируются) лица, имеющие право на
            участие в общем собрании акционеров: 06 июня 2021 года. Категории
            (типы) акций, владельцы которых имеют право голоса по всем вопросам
            повестки дня общего собрания акционеров: обыкновенные.
            <br /> <br />
            Почтовый адрес, по которому должны направляться заполненные
            бюллетени для голосования: 350051, г. Краснодар, ул. Стахановская,
            д. 15, корпус 3, комната 2.
            <br />
            При направлении заполненных документов в общество, представителям
            акционеров необходимо приложить документы, подтверждающие их
            полномочия для осуществления голосования (их копии,
            засвидетельствованные (удостоверенные) в порядке, предусмотренном
            законодательством Российской Федерации). Принявшими участие в общем
            собрании акционеров, проводимом в форме заочного голосования,
            считаются акционеры, бюллетени которых получены до даты окончания
            приема бюллетеней.
            <br /> <br />
            Вид собрания: годовое.
            <br />
            Форма проведения собрания: Заочное голосование*.
            <br /> <br />
            <b>
              Вопросы, включенные в повестку дня годового общего собрания
              акционеров Общества:
            </b>
            <br /> <br />
            1. Утверждение годового отчета, годовой бухгалтерской (финансовой)
            отчетности Общества за 2020 год.
            <br />
            2. Распределение прибыли (в том числе выплата (объявление)
            дивидендов) и убытков общества по результатам 2020 отчетного года
            <br />
            3. Избрание членов Совета директоров Общества.
            <br />
            4. Утверждение аудитора Общества.
            <br />
            5. Последующее утверждение о возложении обязанности по оплате
            расходов, связанных с внесением записей о зачислении размещаемых
            ценных бумаг на лицевые счета их первых владельцев (приобретателей).
            <br />
            6. Утверждение Устава Общества в новой редакции.
            <br />
            7. Принятие решений о последующем одобрении совершенных Обществом
            крупных сделок стоимость которых более 50 % стоимости активов
            Общества.
            <br /> <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: 350051, г. Краснодар, ул.
            Стахановская, д. 15, корпус 3, комната 2 в течение 20 дней до даты
            проведения общего собрания акционеров, в рабочие дни и часы
            Общества.
            <br />
            <br />
            &#42; В соответствии с п. 1 ст. 2 Федерального закона от 24.02.2021
            N 17-ФЗ "О внесении изменений в Федеральный закон "О международных
            компаниях и международных фондах" и о приостановлении действия
            отдельных положений Федерального закона "Об акционерных обществах" и
            Федерального закона "Об обществах с ограниченной ответственностью".
            <br />
            <br />
            Совет директоров АО "Самокарт"
            <br />
            <br />
          </div>
          <hr />

          <div className="text">
            <b>28.01.2021</b>
            <br />
            <b>
              <i>
                Уведомление о возможности приобретения акций дополнительного
                выпуска.
              </i>
            </b>
            <br /> <br />
            <b>Количество размещаемых акций:</b> 5 170 000 (Пять миллионов сто
            семьдесят тысяч) штук дополнительных обыкновенных акций.
            <br />
            <b>
              Участник закрытой подписки вправе полностью или частично
              осуществить свое право на приобретение размещаемых ценных бумаг.
            </b>
            <br />
            <b>Цена размещения акций:</b> 16,10 (Шестнадцать рублей десять
            копеек) рублей каждая.
            <br />
            <b>
              Количество ценных бумаг, которое вправе приобрести участник
              закрытой подписки:
            </b>{" "}
            5 170 000 (Пять миллионов сто семьдесят тысяч) штук дополнительных
            обыкновенных акций.
            <br />
            <b>Дата начала размещения:</b> является первый рабочий день,
            следующий за днем уведомления о возможности приобретения акций
            дополнительного выпуска.
            <br />
            <b>Дата окончания размещения:</b> является 10 рабочий день с даты
            начала размещения ценных бумаг.
            <br />
            В случае если все ценные бумаги дополнительного выпуска будут
            размещены до истечения указанного срока, то датой окончания
            размещения ценных бумаг дополнительного выпуска считается размещение
            последней ценной бумаги. В случае если последний день размещения
            ценных бумаг приходится на не рабочий день, то днем окончания срока
            размещения ценных бумаг будет являться первый, следующий за ним,
            рабочий день.
            <br />
            <b>
              Срок заключения договора на приобретение акций и оплаты за
              приобретаемые акции, а также срок заключения соглашения о зачете
              денежных требований:
            </b>
            <br />
            Размещение обыкновенных акций дополнительного выпуска по закрытой
            подписке осуществляется путем заключения между Обществом и
            участником закрытой подписки (далее именуемые сторонами) договора
            купли-продажи ценных бумаг дополнительного выпуска (далее Договор).
            <br />
            Договоры заключаются в простой письменной форме после достижения
            сторонами соглашения по всем существенным условиям Договора путем
            составления единого документа, подписываемого обеими сторонами в
            двух экземплярах.
            <br />
            Договоры заключаются каждый рабочий день (пн-пт) с 10-00 до 16-00
            часов в течение всего срока размещения с учетом срока оформления
            Эмитентом и направления регистратору распоряжения о списании
            (зачислении) ценных бумаг, а также срока необходимого для внесения
            приходных записей по лицевым счетам (счетам депо) первых владельцев.
            <br />
            Датой заключения Договора считается дата его подписания последней из
            сторон.
            <br />
            Размещаемые акции должны быть полностью оплачены участником закрытой
            подписки до внесения приходной записи по его лицевому счету в
            системе ведения реестра акционеров.
            <br />
            Эмитент заключает с участником закрытой подписки соглашение о зачете
            денежных требований к Обществу в рабочие дни (пн-пт) с 10-00 до
            16-00 часов. Соглашение о зачете денежных требований к Обществу
            заключается в двух экземплярах в простой письменной форме в
            соответствии с нормами Гражданского кодекса Российской Федерации.
            <br />
            Датой оплаты размещаемых акций дополнительного выпуска считается
            дата подписания соглашения о зачете денежных требований к Обществу в
            счет оплаты размещаемых акций.
            <br />
            Соглашение о зачете денежных требований к Обществу, должно быть
            заключено между Обществом и участником закрытой подписки в течение
            срока размещения ценных бумаг Общества после размещения уведомления
            о возможности приобретения акций дополнительного выпуска на сайте
            общества в информационно-телекоммуникационной сети "Интернет" -
            samocart.ru.
            <br />
            Срок заключения договора купли-продажи и/или соглашения о зачете
            денежных требований к Обществу, а также срок оплаты акций - в
            пределах установленного срока размещения, но с учетом сроков,
            необходимых для оформления и направления регистратору распоряжения о
            списании (зачислении) ценных бумаг, а также совершения операции в
            реестре владельцев ценных бумаг по переходу прав собственности на
            акции.
            <br />
            <b>
              Адрес, по которому будут заключаться договор на приобретение акций
              и/или соглашение о зачете денежных требований:
            </b>{" "}
            Общество заключает с участником закрытой подписки Договор
            купли-продажи ценных бумаг и/или соглашение о зачете денежных
            требований к Обществу каждый рабочий день (пн-пт) с 10-00 до 16-00
            часов в течение всего срока размещения по адресу: 350051,
            Краснодарский край, г. Краснодар, ул. Стахановская, д. 15, корп. 3,
            ком. 2.
            <br />
            <b>
              Информация о месте, где участник может ознакомиться с документом,
              содержащим условия размещения ценных бумаг:
            </b>
            <br />
            1. г. Краснодар, ул. Стахановская, д. 15, корпус 3, комната 2 с
            09-00 до 17-00 часов в рабочие дни.
            <br />
            2. г. Москва, Б. Балканский пер., д.20, стр.1 или Краснодарский
            филиал АО «Реестр»: г. Краснодар, ул. им. Орджоникидзе, д. 41 с
            09-00 до 13-00 часов в рабочие дни.
            <br />
            <b>Условия и порядок оплаты ценных бумаг:</b> документом, содержащим
            условия размещения ценных бумаг предусмотрена оплата приобретаемых
            акций денежные средства в рублях Российской Федерации в безналичной
            форме (на расчетный счет Общества); путем зачета денежных требований
            к Обществу (включая, но не ограничиваясь: требованиями, возникшими
            при оказании услуг обществу, реализации товаров, предоставлении
            кредитов (займов), а также в иных случаях).
            <br />
            При оплате размещаемых акций путем зачета денежных требований к
            Обществу участник закрытой подписки подает заявление о зачете
            денежных требований к Обществу.
            <br />
            Заявление о зачете денежных требований к Обществу должно содержать:
            <br />
            - основание для проведения зачета (реквизиты документов (договоров),
            содержащих денежные обязательства);
            <br />
            - срок исполнения денежных обязательств, прекращаемых зачетом;
            <br />
            - размер (сумму) денежных требований и наименование обязательств,
            прекращаемых зачетом (размер денежных требований, погашаемых
            зачетом).
            <br />
            Соглашение о зачете денежных требований к Обществу должно быть
            заключено между Обществом и участником закрытой подписки в течение
            срока размещения ценных бумаг Общества, указанного в пункте 3
            настоящего документа, содержащего условия размещения ценных бумаг,
            но с учетом сроков, необходимых для оформления и направления
            регистратору распоряжение о списании (зачислении) ценных бумаг, а
            также совершения операции в реестре владельцев ценных бумаг по
            переходу прав собственности на акции.
            <br />
            При осуществлении оплаты размещаемых акций путем зачета денежных
            требований к Обществу, размер денежных требований к Обществу должен
            соответствовать стоимости размещаемых приобретаемых участником
            закрытой подписки дополнительных акций. В случае, если размер
            денежных требований к Обществу участника закрытой подписки будет
            меньше стоимости дополнительных акций, заявленных для приобретения,
            лицо, участвующее в подписке, обязано доплатить разницу между
            стоимостью дополнительных акций, заявленных для приобретения и
            размером денежных требований к Обществу в рублях Российской
            Федерации. В случае если участник закрытой подписки не произведет
            доплату указанной разницы в сроки, установленные для оплаты
            дополнительных акций, Общество отказывается от исполнения встречного
            обязательства по передаче акций, не оплаченных лицом, участвующим в
            подписке, и соответствующего договора о приобретении акций в части
            акций, не оплаченных лицом, осуществляющим приобретение акций.
            <br />
            Возможность рассрочки при оплате ценных бумаг выпуска не
            предусмотрена.
            <br />
            Форма безналичных расчетов: расчеты платежными поручениями.
            <br />
            Сведения о кредитной организации:
            <br />
            Полное наименование: Филиал «Ростовский» Акционерного общества
            «АЛЬФА - БАНК»
            <br />
            Сокращенное наименование: Филиал «Ростовский» АО «АЛЬФА - БАНК»
            <br />
            Место нахождения: 34400, г. Ростов-на-Дону, ул. Красноармейская, д.
            206
            <br />
            Банковские реквизиты счета эмитента, на который должны перечисляться
            денежные средства, поступающие в оплату ценных бумаг:
            <br />
            БИК: 046015207
            <br />
            ИНН: 7728168971;
            <br />
            к/сч:30101810500000000207 в отделение Ростов-на-Дону;
            <br />
            р/сч: 40702810026020005282.
            <br />
            Сокращенное наименование получателя денежных средств: АО «Самокарт»
            <br />
            ИНН получателя средств: 2310212217
            <br />
            Наличная форма за ценные бумаги не предусмотрена.
            <br />
            Назначение платежа при оплате акций дополнительного выпуска должно
            быть в следующей формулировке: «Оплата акций дополнительного выпуска
            ценных бумаг АО «Самокарт».
            <br />
            <br />
            Дата раскрытия на сайте общества в
            информационно-телекоммуникационной сети "Интернет" samocart.ru
            Уведомление о возможности приобретения акций дополнительного выпуска
            –28.01.2021 г.
            <br />
            За дополнительной информацией обращаться по тел. +7 (938) 865-97-58.
            <br />
            <br /> <br /> <br />
            Генеральный директор АО "Самокарт"
          </div>
          <hr />

          <div className="text">
            <p>19.11.2020 </p>
            <br />
            <b>
              {" "}
              ОТЧЕТ <br />
              об итогах голосования на внеочередном общем собрании акционеров
              Акционерного общества «Самокарт»
            </b>
            <p>
              г. Краснодар «19» ноября 2020 <br />
              Полное фирменное наименование общества: Акционерное общество
              "Самокарт" (далее именуемое Общество).
            </p>
            <p>Место нахождения Общества: РФ, г. Краснодар.</p>
            <p>
              Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
              Стахановская, д. 15, корп. 3, ком. 2.
            </p>
            <p>Вид общего собрания: внеочередное.</p>
            <p>Форма проведения общего собрания: заочное голосование.</p>
            <p>
              Дата определения (фиксации) лиц, имевших право на участие в общем
              собрании акционеров Общества: «26» октября 2020 г.
            </p>
            <p>Дата проведения общего собрания: «18» ноября 2020 г.</p>
            <p>
              Сведения о регистраторе, выполнявшем функции счетной комиссии:
            </p>
            <p>Полное фирменное наименование: Акционерное общество "Реестр".</p>
            <p>Место нахождения: Российская Федерация, г. Москва.</p>
            <p>
              Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
              стр. 1.
            </p>
            <p>
              Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
            </p>
            <br />
            <b>Повестка дня собрания:</b>
            <p>
              1. Об увеличении уставного капитала общества путем размещения
              дополнительных акций посредством закрытой подписки.
            </p>
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, включенные в список
                      лиц, имевших право на участие в общем собрании, по данному
                      вопросу повестки дня общего собрания
                    </p>
                  </td>
                  <td>
                    <p>12 557 800</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, приходившихся на голосующие акции Общества,
                      по данному вопросу повестки дня общего собрания,
                      определенное с учетом положений пункта 4.24 «Положения об
                      общих собраниях акционеров» (утв. Банком России 16.11.2018
                      N 660-П)
                    </p>
                  </td>
                  <td>
                    <p>12 557 800</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которыми обладали лица, принявшие участие в
                      общем собрании, по данному вопросу повестки дня общего
                      собрания
                    </p>
                  </td>
                  <td>
                    <p>12 557 800</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
              <br />
            </p>
            <b>Кворум по данному вопросу повестки дня имеется.</b>
            <p>
              <br />
            </p>
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    <p>Число голосов, отданных за вариант голосования «ЗА»</p>
                  </td>
                  <td>
                    <p>12 557 800 | 100%*</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования «ПРОТИВ»
                    </p>
                  </td>
                  <td>
                    <p>0</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, отданных за вариант голосования
                      «ВОЗДЕРЖАЛСЯ»
                    </p>
                  </td>
                  <td>
                    <p>0</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Число голосов, которые не подсчитывались в связи с
                      признанием бюллетеней в части голосования по данному
                      вопросу повестки дня недействительными или по иным
                      основаниям
                    </p>
                  </td>
                  <td>
                    <p>0</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
              <br />
            </p>
            <p>
              *процент определяется от числа голосов, которыми обладали лица,
              принявшие участие в общем собрании, по данному вопросу повестки
              дня, определенное с учетом положений п. 4.24 «Положения об общих
              собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            </p>
            <p>
              <br />
            </p>
            <b>Формулировка решения, принятого общим собранием:</b>
            <p>
              Увеличить уставный капитал Акционерного общества "Самокарт" путем
              размещения 5 170 000 (Пять миллионов сто семьдесят тысяч) штук
              дополнительных обыкновенных бездокументарных акций, номинальной
              стоимостью 0,01 (Одна копейка) рублей каждая акция, на общую сумму
              51 700 (Пятьдесят одна тысяча семьсот) рублей.
            </p>
            <p>Способ размещения дополнительных акций: закрытая подписка.</p>
            <p>
              Круг лиц, среди которых предполагается осуществить размещение
              ценных бумаг посредством закрытой подписки:
            </p>
            <p>- Варлашин Владислав Валерьевич (ИНН: 231204904297),</p>
            <p>- Литвиненко Сергей Иванович (ИНН: 231297474534), </p>
            <p>- Акционерное общество "Тилтех" (ОГРН: 1167746893995). </p>
            <p>
              Цена размещения каждой дополнительной обыкновенной
              бездокументарной акции, в том числе лицам, имеющим
              преимущественное право приобретения размещаемых акций равна 16,10
              (Шестнадцать рублей десять копеек) рублей.
            </p>
            <p>
              Форма оплаты размещаемых дополнительных акций: денежные средства в
              рублях РФ по безналичному расчету на расчетный счет АО «Самокарт»,
              а также путем зачета денежных требований к акционерному обществу.
            </p>
            <p>
              Рассрочка при оплате акций дополнительного выпуска не
              предусмотрена.
            </p>
            <p>
              После государственной регистрации отчета об итогах дополнительного
              выпуска ценных бумаг внести изменения в Устав Общества, отражающие
              увеличение уставного капитала, числа размещенных акций и
              уменьшение числа объявленных акций на количество фактически
              размещенных акций.
            </p>
            <br />
            <b>Председательствующий на общем собрании </b>
            <br />
            <b>Литвиненко Сергей Иванович</b>
            <br />
            <br />
            <b>Секретарь общего собрания</b>
            <br />
            <b>Левицкий Ефим Романович</b>
            <p>
              <span id="bx-cursor-node"> </span>
              <br />
            </p>
            26.10.2020
            <br />
            <b>
              Сообщение
              <br />
              о проведении внеочередного Общего собрания акционеров АО
              <br />
              "САМОКАРТ"
              <br />
            </b>
            <br />
            г. Краснодар «26» октября 2020 г.
            <br />
            <br />
            АО "САМОКАРТ" (далее – Общество) сообщает акционерам Общества о
            проведении внеочередного общего собрания акционеров.
            <br />
            <u>Полное фирменное наименование общества:</u> Акционерное общество
            "Самокарт" (далее именуемое Общество).
            <br />
            <u>Место нахождения Общества:</u> 350051, г. Краснодар, ул.
            Стахановская, д. 15, корпус 3, комната 2.
            <br />
            <u>
              Дата окончания приема заполненных бюллетеней для голосования (дата
              проведения собрания):
            </u>{" "}
            18 ноября 2020 года.
            <br />
            <u>
              Дата, на которую определяются (фиксируются) лица, имеющие право на
              участие в общем собрании акционеров:
            </u>{" "}
            26 октября 2020 года.
            <br />
            <u>
              Категории (типы) акций, владельцы которых имеют право голоса по
              всем вопросам повестки дня общего собрания акционеров:
            </u>{" "}
            обыкновенные.
            <br />
            <u>
              Почтовый адрес, по которому должны направляться заполненные
              бюллетени для голосования:
            </u>{" "}
            350051, г. Краснодар, ул. Стахановская, д. 15, корпус 3, комната 2.
            <br />
            <u>Вид собрания:</u> внеочередное.
            <br />
            <u>Форма проведения собрания:</u> Заочное голосование*. Акционеры,
            желающие принять участие в общем собрании акционеров Общества,
            должны направить заполненные и подписанные бюллетени по адресу,
            указанному в настоящем сообщении. Документы, удостоверяющие
            полномочия правопреемников и представителей лиц, включенных в список
            лиц, имеющих право на участие в общем собрании (их копии,
            засвидетельствованные в установленном порядке), прилагаются к
            направляемым этими лицами бюллетеням для голосования.
            <br />
            <br />
            <b>
              Вопросы, включенные в повестку дня годового общего собрания
              акционеров Общества:
            </b>
            <br />
            <br />
            1. Об увеличении уставного капитала общества путем размещения
            дополнительных акций посредством закрытой подписки.
            <br />
            <br />
            Принявшими участие в общем собрании акционеров, проводимом в форме
            заочного голосования, считаются акционеры, бюллетени которых{" "}
            <u>получены до даты окончания приема бюллетеней.</u>
            <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: г. Краснодар, ул. Стахановская, д.
            15, корпус 3, комната 2 в течение 20 дней до даты проведения общего
            собрания акционеров, в рабочие дни и часы Общества.
            <br />
            <br />
            <b>Совет директоров АО "САМОКАРТ"</b>
            <br />
            <br />
            25.06.2020
            <br />
            ОТЧЕТ
            <br />
            об итогах голосования на
            <br />
            годовом общем собрании акционеров
            <br />
            Акционерного общества
            <br />
            "Самокарт"
            <br />
            <br />
            г. Краснодар 23 июня 2020 г.
            <br />
            <br />
            Полное фирменное наименование общества: Акционерное общество
            "Самокарт" (далее именуемое Общество).
            <br />
            Место нахождения Общества: РФ, г. Краснодар.
            <br />
            Адрес Общества: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, корп. 3, ком. 2.
            <br />
            Вид общего собрания: годовое.
            <br />
            Форма проведения общего собрания: заочное голосование.
            <br />
            Дата определения (фиксации) лиц, имевших право на участие в общем
            собрании акционеров Общества: «29» мая 2020 г.
            <br />
            Дата проведения общего собрания: «22» июня 2020 г.
            <br />
            Почтовый адрес, по которому направлялись заполненные бюллетени для
            голосования при проведении общего собрания в форме заочного
            голосования: 350051, Краснодарский край, г. Краснодар, ул.
            Стахановская, д. 15, корп. 3, ком. 2.
            <br />
            Сведения о регистраторе, выполнявшем функции счетной комиссии:
            <br />
            Полное фирменное наименование: Акционерное общество "Реестр".
            <br />
            Место нахождения: Российская Федерация, г. Москва.
            <br />
            Адрес регистратора: 129090, г. Москва, Б. Балканский пер., д. 20,
            стр. 1.
            <br />
            Лицо, уполномоченное АО «Реестр»: Максимушкина Марина Борисовна.
            <br />
            <br />
            Повестка дня собрания:
            <br />
            <br />
            1. Утверждение годового отчета, годовой бухгалтерской (финансовой)
            отчетности Общества за 2019 год.
            <br />
            2. Распределение прибыли (в том числе выплата (объявление)
            дивидендов) и убытков общества по результатам 2019 отчетного года.
            <br />
            3. Избрание членов Совета директоров Общества.
            <br />
            4. Утверждение аудитора Общества на 2020 год.
            <br />
            5. Последующее утверждение аудитора Общества на 2019 год.
            <br />
            <br />
            Результаты голосования и формулировки принятых решений:
            <br />
            <br />
            По вопросу повестки дня №1:
            <br />
            <br />
            Формулировка решения, поставленного на голосование:
            <br />
            1. Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
            отчетность за 2019 отчетный год.
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, включенные в список
                    лиц, имевших право на участие в общем собрании, по данному
                    вопросу повестки дня общего собрания
                  </td>
                  <td>12 557 800</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, приходившихся на голосующие акции Общества,
                    по данному вопросу повестки дня общего собрания,
                    определенное с учетом положений пункта 4.24 «Положения об
                    общих собраниях акционеров» (утв. Банком России 16.11.2018 N
                    660-П)
                  </td>
                  <td>12 557 800</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, принявшие участие в
                    общем собрании, по данному вопросу повестки дня общего
                    собрания
                  </td>
                  <td>12 557 800</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <br />
            <br />
            <br />
            Итоги голосования:
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>Число голосов, отданных за вариант голосования «ЗА»</td>
                  <td>12 557 800 | 100%*</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ПРОТИВ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которые не подсчитывались в связи с
                    признанием бюллетеней в части голосования по данному вопросу
                    повестки дня недействительными или по иным основаниям
                  </td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Формулировка решения, принятого общим собранием:
            <br />
            1. Утвердить годовой отчет, годовую бухгалтерскую (финансовую)
            отчетность за 2019 отчетный год.
            <br />
            <br />
            По вопросу повестки дня №2:
            <br />
            <br />
            Формулировка решения, поставленного на голосование:
            <br />
            2. Дивиденды за 2019 год по обыкновенным именным бездокументарным
            акциям общества не выплачивать, в связи с получением убытка по
            результатам деятельности АО "Самокарт" в 2019 году.
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, включенные в список
                    лиц, имевших право на участие в общем собрании, по данному
                    вопросу повестки дня общего собрания
                  </td>
                  <td>12 557 800</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, приходившихся на голосующие акции Общества,
                    по данному вопросу повестки дня общего собрания,
                    определенное с учетом положений пункта 4.24 «Положения об
                    общих собраниях акционеров» (утв. Банком России 16.11.2018 N
                    660-П)
                  </td>
                  <td>12 557 800</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, принявшие участие в
                    общем собрании, по данному вопросу повестки дня общего
                    собрания
                  </td>
                  <td>12 557 800</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <br />
            <br />
            <br />
            Итоги голосования:
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>Число голосов, отданных за вариант голосования «ЗА»</td>
                  <td>12 557 800 | 100%*</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ПРОТИВ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которые не подсчитывались в связи с
                    признанием бюллетеней в части голосования по данному вопросу
                    повестки дня недействительными или по иным основаниям
                  </td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Формулировка решения, принятого общим собранием:
            <br />
            2. Дивиденды за 2019 год по обыкновенным именным бездокументарным
            акциям общества не выплачивать, в связи с получением убытка по
            результатам деятельности АО "Самокарт" в 2019 году.
            <br />
            <br />
            По вопросу повестки дня №3:
            <br />
            <br />
            Формулировка решения, поставленного на голосование:
            <br />
            3. Избрать членом Совета директоров Общества:
            <br />
            1. Литвиненко Сергей Иванович
            <br />
            2. Варлашин Владислав Валерьевич
            <br />
            3. Евдаков Кирилл Сергеевич
            <br />
            4. Левицкий Ефим Романович
            <br />
            5. Остапенко Владимир Валерьевич
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, включенные в список
                    лиц, имевших право на участие в общем собрании, по данному
                    вопросу повестки дня общего собрания
                  </td>
                  <td>62 789 000</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, приходившихся на голосующие акции Общества,
                    по данному вопросу повестки дня общего собрания,
                    определенное с учетом положений пункта 4.24 «Положения об
                    общих собраниях акционеров» (утв. Банком России 16.11.2018 N
                    660-П)
                  </td>
                  <td>62 789 000</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, принявшие участие в
                    общем собрании, по данному вопросу повестки дня общего
                    собрания
                  </td>
                  <td>62 789 000</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Голосование кумулятивное. 5 вакансий.
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <br />
            <br />
            <br />
            Итоги голосования:
            <br />
            Число голосов «ЗА», распределенных среди кандидатов:
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <thead>
                <tr>
                  <th>Ф.И.О. кандидата</th>
                  <th>Число голосов</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Литвиненко Сергей Иванович</td>
                  <td>12 500 000</td>
                </tr>
                <tr>
                  <td>Варлашин Владислав Валерьевич</td>
                  <td>12 500 000</td>
                </tr>
                <tr>
                  <td>Евдаков Кирилл Сергеевич</td>
                  <td>12 500 000</td>
                </tr>
                <tr>
                  <td>Левицкий Ефим Романович</td>
                  <td>12 500 000</td>
                </tr>
                <tr>
                  <td>Остапенко Владимир Валерьевич</td>
                  <td>12 500 000</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ПРОТИВ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которые не подсчитывались в связи с
                    признанием бюллетеней в части голосования по данному вопросу
                    повестки дня недействительными или по иным основаниям
                  </td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Формулировка решения, принятого общим собранием:
            <br />
            3. Избрать членом Совета директоров Общества:
            <br />
            1. Литвиненко Сергей Иванович
            <br />
            2. Варлашин Владислав Валерьевич
            <br />
            3. Евдаков Кирилл Сергеевич
            <br />
            4. Левицкий Ефим Романович
            <br />
            5. Остапенко Владимир Валерьевич
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            По вопросу повестки дня №4:
            <br />
            <br />
            Формулировка решения, поставленного на голосование:
            <br />
            4. Утвердить аудитором Общества на 2020 год ООО "Гик Аудит" (ИНН
            2310181350).
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, включенные в список
                    лиц, имевших право на участие в общем собрании, по данному
                    вопросу повестки дня общего собрания
                  </td>
                  <td>12 557 800</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, приходившихся на голосующие акции Общества,
                    по данному вопросу повестки дня общего собрания,
                    определенное с учетом положений пункта 4.24 «Положения об
                    общих собраниях акционеров» (утв. Банком России 16.11.2018 N
                    660-П)
                  </td>
                  <td>12 557 800</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, принявшие участие в
                    общем собрании, по данному вопросу повестки дня общего
                    собрания
                  </td>
                  <td>12 557 800</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <br />
            <br />
            <br />
            Итоги голосования:
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>Число голосов, отданных за вариант голосования «ЗА»</td>
                  <td>12 557 800 | 100%*</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ПРОТИВ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которые не подсчитывались в связи с
                    признанием бюллетеней в части голосования по данному вопросу
                    повестки дня недействительными или по иным основаниям
                  </td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Формулировка решения, принятого общим собранием:
            <br />
            4. Утвердить аудитором Общества на 2020 год ООО "Гик Аудит" (ИНН
            2310181350).
            <br />
            <br />
            По вопросу повестки дня №5:
            <br />
            <br />
            Формулировка решения, поставленного на голосование:
            <br />
            5. Утвердить аудитором Общества, осуществившего проверку
            финансово-хозяйственной деятельности по результатам отчетного 2019
            года на основании заключенного с ним договора, ООО "Гик Аудит" (ИНН
            2310181350).
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, включенные в список
                    лиц, имевших право на участие в общем собрании, по данному
                    вопросу повестки дня общего собрания
                  </td>
                  <td>12 557 800</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, приходившихся на голосующие акции Общества,
                    по данному вопросу повестки дня общего собрания,
                    определенное с учетом положений пункта 4.24 «Положения об
                    общих собраниях акционеров» (утв. Банком России 16.11.2018 N
                    660-П)
                  </td>
                  <td>12 557 800</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, принявшие участие в
                    общем собрании, по данному вопросу повестки дня общего
                    собрания
                  </td>
                  <td>12 557 800</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <br />
            <br />
            <br />
            Итоги голосования:
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>Число голосов, отданных за вариант голосования «ЗА»</td>
                  <td>12 557 800 | 100%*</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ПРОТИВ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которые не подсчитывались в связи с
                    признанием бюллетеней в части голосования по данному вопросу
                    повестки дня недействительными или по иным основаниям
                  </td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Формулировка решения, принятого общим собранием:
            <br />
            5. Утвердить аудитором Общества, осуществившего проверку
            финансово-хозяйственной деятельности по результатам отчетного 2019
            года на основании заключенного с ним договора, ООО "Гик Аудит" (ИНН
            2310181350).
            <br />
            <br />
            Председательствующий на общем собрании
            <br />
            Литвиненко Сергей Иванович
            <br />
            <br />
            Секретарь общего собрания
            <br />
            Левицкий Ефим Романович
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Сообщение
            <br />
            о проведении годового Общего собрания акционеров АО "САМОКАРТ"
            <br />
            г. Краснодар «29» мая 2020 г.
            <br />
            <br />
            АО "САМОКАРТ" (далее – Общество) сообщает акционерам Общества о
            проведении годового общего собрания акционеров.
            <br />
            Полное фирменное наименование Общества: АКЦИОНЕРНОЕ ОБЩЕСТВО
            "САМОКАРТ".
            <br />
            Место нахождения Общества: Г. КРАСНОДАР, УЛ. СТАХАНОВСКАЯ, Д. 15,
            КОРП. 3, КОМ. 2.
            <br />
            Дата окончания приема заполненных бюллетеней для голосования (дата
            проведения собрания): 22 июня 2020 года.
            <br />
            Дата, на которую определяются (фиксируются) лица, имеющие право на
            участие в общем собрании акционеров: 29 мая 2020 года.
            <br />
            Категории (типы) акций, владельцы которых имеют право голоса по всем
            вопросам повестки дня общего собрания акционеров: обыкновенные.
            <br />
            Почтовый адрес, по которому должны направляться заполненные
            бюллетени для голосования: 350051, КРАСНОДАРСКИЙ КРАЙ, Г. КРАСНОДАР,
            УЛ. СТАХАНОВСКАЯ, Д. 15, КОРПУС 3, КОМНАТА 2.
            <br />
            Вид собрания: годовое.
            <br />
            Форма проведения собрания: Заочное голосование*. Акционеры, желающие
            принять участие в общем собрании акционеров Общества, должны
            направить заполненные и подписанные бюллетени по адресу, указанному
            в настоящем сообщении. Документы, удостоверяющие полномочия
            правопреемников и представителей лиц, включенных в список лиц,
            имеющих право на участие в общем собрании (их копии,
            засвидетельствованные в установленном порядке), прилагаются к
            направляемым этими лицами бюллетеням для голосования.
            <br />
            <br />
            Вопросы, включенные в повестку дня годового общего собрания
            акционеров Общества:
            <br />
            <br />
            1. Утверждение годового отчета, годовой бухгалтерской (финансовой)
            отчетности Общества за 2019 год.
            <br />
            2. Распределение прибыли (в том числе выплата (объявление)
            дивидендов) и убытков общества по результатам 2019 отчетного года.
            <br />
            3. Избрание членов Совета директоров Общества.
            <br />
            4. Утверждение аудитора Общества.
            <br />
            5. Последующее утверждение аудитора Общества на 2019 год.
            <br />
            <br />
            Принявшими участие в общем собрании акционеров, проводимом в форме
            заочного голосования, считаются акционеры, бюллетени которых
            получены до даты окончания приема бюллетеней.
            <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: Г. КРАСНОДАР, УЛ. СТАХАНОВСКАЯ, Д.
            15, КОРП. 3, КОМ. 2 в течение 20 дней до даты проведения общего
            собрания акционеров, в рабочие дни и часы Общества.
            <br />
            <br />
            Совет директоров АО "САМОКАРТ"
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Уведомление о возможности приобретения акций дополнительного выпуска
            <br />
            Акционерного общества «САМОКАРТ»
            <br />
            г. Краснодар «6» ноября 2019 г.
            <br />
            <br />
            Количество размещаемых акций: 2 600 000 (Два миллиона шестьсот
            тысяч) штук обыкновенных именных бездокументарных акций.
            <br />
            Участник закрытой подписки вправе полностью или частично осуществить
            свое право на приобретение размещаемых ценных бумаг.
            <br />
            Цена размещения акций: 7 (Семь) рублей каждая.
            <br />
            Дата начала размещения: после государственной регистрации
            дополнительного выпуска ценных бумаг на следующий рабочий день с
            даты уведомления о возможности приобретения акций дополнительного
            выпуска.
            <br />
            Дата окончания срока размещения: через 10 рабочих дней с даты начала
            размещения ценных бумаг, а в случае, если все ценные бумаги будут
            размещены до истечения этого срока, то днем размещения считается
            размещение последней ценной бумаги настоящего дополнительного
            выпуска.
            <br />
            Срок подачи заявлений на приобретение акций: в пределах
            установленного срока размещения.
            <br />
            Срок заключения договора на приобретение акций и оплаты за
            приобретаемые акции, а также срок заключения соглашения о зачете
            денежных требований:
            <br />
            Размещение обыкновенных именных бездокументарных акций
            дополнительного выпуска по закрытой подписке осуществляется путем
            заключения между Обществом и потенциальным приобретателем (далее -
            Участник подписки, а также - Приобретатель) договора купли-продажи
            ценных бумаг дополнительного выпуска.
            <br />
            <br />
            Договоры о приобретении акций дополнительного выпуска заключаются в
            простой письменной форме после достижения сторонами соглашения по
            всем существенным условиям договора путем составления единого
            документа, подписываемого обеими сторонами в двух экземплярах.
            <br />
            <br />
            Соглашение о зачете денежных требований к Обществу, должно быть
            заключено между Обществом и участником закрытой подписки в течение
            срока размещения ценных бумаг Общества после размещения уведомления
            о возможности приобретения акций дополнительного выпуска на сайте
            общества в информационно-телекоммуникационной сети "Интернет" -
            samocart.ru.
            <br />
            <br />
            Срок заключения договора купли-продажи и/или соглашения о зачете
            денежных требований к Обществу, а также срок оплаты акций - в
            пределах установленного срока размещения.
            <br />
            <br />
            Адрес, по которому будут заключаться договор на приобретение акций
            и/или соглашение о зачете денежных требований: Общество заключает с
            участником закрытой подписки Договор купли-продажи ценных бумаг
            и/или соглашение о зачете денежных требований к Обществу в рабочие
            дни с 09-00 до 17-00 часов по адресу: г. Краснодар, ул.
            Стахановская, д. 15, корпус 3, комната 2.
            <br />
            Информация о месте, где участник может ознакомиться с содержанием
            решения о дополнительном выпуске ценных бумаг: акционер может
            ознакомиться с содержанием решения о дополнительном выпуске ценных
            бумаг по следующим адресам:
            <br />
            1. г. Краснодар, ул. Стахановская, д. 15, корпус 3, комната 2 с
            09-00 до 17-00 часов в рабочие дни.
            <br />
            2. г. Москва, Б. Балканский пер., д.20, стр.1 или Краснодарский
            филиал АО «Реестр»: 350000,
            <br />
            г. Краснодар, ул. им. Орджоникидзе, д. 41 с 09-00 до 13-00 часов в
            рабочие дни.
            <br />
            Условия и порядок оплаты ценных бумаг: решением о размещении ценных
            бумаг предусмотрена оплата приобретаемых акций денежными средствами
            в рублях Российской Федерации по безналичному расчету на расчетный
            счет АО "Самокарт", а также путем зачета денежных требований к
            акционерному обществу, по усмотрению участника.
            <br />
            <br />
            При оплате размещаемых дополнительных акций посредством закрытой
            подписки, путем зачета денежных требований к Обществу-эмитенту,
            участник закрытой подписки подает заявление (заключает соглашения) о
            зачете денежных требований к Обществу.
            <br />
            <br />
            Заявление о зачете денежных требований к Обществу должно содержать:
            <br />
            - основание для проведения зачета (реквизиты документов (договоров),
            содержащих денежные обязательства);
            <br />
            - срок исполнения денежных обязательств, прекращаемых зачетом;
            <br />
            - размер (сумму) денежных требований и наименование обязательств,
            прекращаемых зачетом (размер денежных требований, погашаемых
            зачетом).
            <br />
            <br />
            При осуществлении оплаты размещаемых акций путем зачета денежных
            требований к Обществу, размер денежных требований к Обществу должен
            соответствовать стоимости размещаемых приобретаемых участником
            закрытой подписки дополнительных акций. В случае если размер
            денежных требований к Обществу участника закрытой подписки будет
            меньше стоимости дополнительных акций, заявленных для приобретения,
            лицо, участвующее в подписке обязано доплатить разницу между
            стоимостью дополнительных акций, заявленных для приобретения и
            размером денежных требований к Обществу в рублях Российской
            Федерации. В случае если участник закрытой подписки не произведет
            доплату указанной разницы в сроки, установленные для оплаты
            дополнительных акций, Общество отказывается от исполнения встречного
            обязательства по передаче акций, не оплаченных лицом участвующем в
            подписке, и соответствующего договора о приобретении акций в части
            акций, не оплаченных лицом, осуществляющим приобретение акций.
            <br />
            <br />
            Соглашение о зачете денежных требований к Обществу заключается в
            двух экземплярах в простой письменной форме в соответствии с ГК РФ.
            <br />
            <br />
            Датой оплаты размещаемых акций дополнительного выпуска считается
            дата подписания соглашения о зачете денежных требований к Обществу в
            счет оплаты размещаемых акций.
            <br />
            <br />
            Размещаемые акции должны быть полностью оплачены участником закрытой
            подписки до внесения приходной записи по его лицевому счету в
            системе ведения реестра акционеров.
            <br />
            <br />
            Возможность рассрочки при оплате ценных бумаг выпуска не
            предусмотрена.
            <br />
            Предусмотрена безналичная форма расчетов.
            <br />
            Форма безналичных расчетов: расчеты платежными поручениями.
            <br />
            <br />
            Сведения о кредитной организации:
            <br />
            Расчетный счет эмитента для перечисления денежных средств при оплате
            ценных бумаг в безналичной форме:
            <br />
            Полное наименование: Филиал «Ростовский» Акционерного общества
            «АЛЬФА - БАНК»
            <br />
            Сокращенное наименование: Филиал «Ростовский» АО «АЛЬФА - БАНК»
            <br />
            Место нахождения: 34400, г. Ростов-на-Дону, ул. Красноармейская, д.
            206
            <br />
            Банковские реквизиты счетов, на которые должны перечисляться
            денежные средства, поступающие в оплату ценных бумаг:
            <br />
            БИК: 046015207
            <br />
            ИНН: 7728168971;
            <br />
            к/сч:30101810500000000207 в отделение Ростов-на-Дону;
            <br />
            р/сч: 40702810026020005282.
            <br />
            Наименование получателя средств: Акционерное общество «Самокарт»
            <br />
            ИНН получателя средств: 2310212217
            <br />
            Неденежная форма оплаты не предусмотрена.
            <br />
            <br />
            Дата раскрытия на сайте общества в
            информационно-телекоммуникационной сети "Интернет" samocart.ru
            Уведомление о возможности приобретения акций дополнительного выпуска
            –06.11.2019 г.
            <br />
            <br />
            За дополнительной информацией обращаться по тел. +7 (938) 865-97-58.
            <br />
            <br />
            <br />
            Генеральный директор АО "Самокарт"
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Отчет об итогах голосования
            <br />
            на общем собрании акционеров
            <br />
            Акционерного общества «САМОКАРТ»
            <br />
            г. Краснодар «10» сентября 2019 г.
            <br />
            <br />
            Полное фирменное наименование общества: Акционерное общество
            «Самокарт».
            <br />
            Место нахождения Общества: г. Краснодар, ул. Стахановская, д. 15,
            корп.3, ком.2.
            <br />
            Адрес Общества: г. Краснодар, ул. Стахановская, д. 15, корп.3,
            ком.2.
            <br />
            Вид общего собрания: внеочередное.
            <br />
            Форма проведения общего собрания: заочное голосование.
            <br />
            Дата определения (фиксации) лиц, имевших право на участие в общем
            собрании акционеров Общества: «16» августа 2019 г.
            <br />
            Дата проведения общего собрания: «10» сентября 2019 г.
            <br />
            Сведения о регистраторе, выполнявшем функции счетной комиссии:
            <br />
            Полное фирменное наименование: Акционерное общество "Реестр".
            <br />
            Место нахождения: Российская Федерация, г.Москва.
            <br />
            Адрес регистратора: 129090, Москва, Б. Балканский пер., д. 20, стр.
            1.
            <br />
            Лицо, уполномоченное АО «Реестр»: Нестерова Валерия Андреевна.
            <br />
            Председатель собрания: Литвиненко С.И.
            <br />
            Секретарь собрания: Евдаков К.С.
            <br />
            <br />
            <br />
            ПОВЕСТКА ДНЯ СОБРАНИЯ
            <br />
            1. Об увеличении уставного капитала общества путем размещения
            дополнительных акций посредством закрытой подписки
            <br />
            <br />
            Результаты голосования и формулировки принятых решений:
            <br />
            <br />
            По вопросу повестки дня №1:
            <br />
            Формулировка решения, поставленного на голосование:
            <br />
            Увеличить уставный капитал Акционерного общества "Самокарт" путем
            размещения 2 600 000 (Два миллиона шестьсот тысяч) штук
            дополнительных обыкновенных именных бездокументарных акций,
            номинальной стоимостью 0,01 (Одна копейка) рублей каждая акция, на
            общую сумму 26 000 (Двадцать шесть тысяч) рублей. Способ размещения
            дополнительных акций: закрытая подписка. Круг лиц, среди которых
            предполагается осуществить размещение ценных бумаг посредством
            закрытой подписки:
            <br />
            - Варлашин Владислав Валерьевич (ИНН: 231204904297) , - Литвиненко
            Сергей Иванович (ИНН: 231297474534). Цена размещения каждой
            дополнительной обыкновенной именной бездокументарной акции, в том
            числе лицам, имеющим преимущественное право приобретения размещаемых
            акций равна 7 (Семь) рублей. Форма оплаты размещаемых дополнительных
            акций: денежные средства в рублях РФ по безналичному расчету на
            расчетный счет АО «Самокарт», а также путем зачета денежных
            требований к акционерному обществу. Рассрочка при оплате акций
            дополнительного выпуска не предусмотрена. После государственной
            регистрации отчета об итогах дополнительного выпуска ценных бумаг
            внести изменения в Устав Общества, отражающие увеличение уставного
            капитала, числа размещенных акций и уменьшение числа объявленных
            акций на количество фактически размещенных акций.
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, включенные в список
                    лиц, имевших право на участие в общем собрании, по данному
                    вопросу повестки дня общего собрания
                  </td>
                  <td>10 000 000</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, приходившихся на голосующие акции Общества,
                    по данному вопросу повестки дня общего собрания,
                    определенное с учетом положений пункта 4.24 «Положения об
                    общих собраниях акционеров» (утв. Банком России 16.11.2018 N
                    660-П)
                  </td>
                  <td>10 000 000</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которыми обладали лица, принявшие участие в
                    общем собрании, по данному вопросу повестки дня общего
                    собрания
                  </td>
                  <td>10 000 000</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Кворум по данному вопросу повестки дня имеется.
            <br />
            <br />
            <br />
            <br />
            Итоги голосования:
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <table className={"big"}>
              <tbody>
                <tr>
                  <td>Число голосов, отданных за вариант голосования «ЗА»</td>
                  <td>10 000 000 | 100%*</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ПРОТИВ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, отданных за вариант голосования «ВОЗДЕРЖАЛСЯ»
                  </td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>
                    Число голосов, которые не подсчитывались в связи с
                    признанием бюллетеней в части голосования по данному вопросу
                    повестки дня недействительными или по иным основаниям
                  </td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            *процент определяется от числа голосов, которыми обладали лица,
            принявшие участие в общем собрании, по данному вопросу повестки дня,
            определенное с учетом положений п. 4.24 «Положения об общих
            собраниях акционеров» (утв. Банком России 16.11.2018 N 660-П).
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Формулировка решения, принятого общим собранием:
            <br />
            <br />
            Увеличить уставный капитал Акционерного общества "Самокарт" путем
            размещения 2 600 000 (Два миллиона шестьсот тысяч) штук
            дополнительных обыкновенных именных бездокументарных акций,
            номинальной стоимостью 0,01 (Одна копейка) рублей каждая акция, на
            общую сумму 26 000 (Двадцать шесть тысяч) рублей. Способ размещения
            дополнительных акций: закрытая подписка. Круг лиц, среди которых
            предполагается осуществить размещение ценных бумаг посредством
            закрытой подписки: - Варлашин Владислав Валерьевич (ИНН:
            231204904297), - Литвиненко Сергей Иванович (ИНН: 231297474534).
            Цена размещения каждой дополнительной обыкновенной именной
            бездокументарной акции, в том числе лицам, имеющим преимущественное
            право приобретения размещаемых акций равна 7 (Семь) рублей. Форма
            оплаты размещаемых дополнительных акций: денежные средства в рублях
            РФ по безналичному расчету на расчетный счет АО «Самокарт», а также
            путем зачета денежных требований к акционерному обществу. Рассрочка
            при оплате акций дополнительного выпуска не предусмотрена. После
            государственной регистрации отчета об итогах дополнительного выпуска
            ценных бумаг внести изменения в Устав Общества, отражающие
            увеличение уставного капитала, числа размещенных акций и уменьшение
            числа объявленных акций на количество фактически размещенных акций.
            <br />
            <br />
            <br />
            Председатель собрания Литвиненко С.И.
            <br />
            Секретарь собрания Евдаков К.С.
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Сообщение о проведении внеочередного Общего собрания акционеров АО
            «САМОКАРТ»
            <br />
            <br />
            Дата публикации: 19.08.19
            <br />
            <br />
            АО «САМОКАРТ» (далее — Общество) сообщает акционерам Общества о
            проведении внеочередного общего собрания акционеров.
            <br />
            <br />
            Полное фирменное наименование Общества: АКЦИОНЕРНОЕ ОБЩЕСТВО
            «САМОКАРТ».
            <br />
            <br />
            Место нахождения Общества: Г. КРАСНОДАР, УЛ. СТАХАНОВСКАЯ, Д. 15,
            КОРП.3, КОМ.2.
            <br />
            <br />
            Дата окончания приема заполненных бюллетеней для голосования (дата
            проведения собрания): 10 сентября 2019 года.
            <br />
            <br />
            Дата, на которую определяются (фиксируются) лица, имеющие право на
            участие в общем собрании акционеров: 16 августа 2019 года.
            <br />
            <br />
            Категории (типы) акций, владельцы которых имеют право голоса по всем
            вопросам повестки дня общего собрания акционеров: обыкновенные.
            <br />
            <br />
            Почтовый адрес, по которому должны направляться заполненные
            бюллетени для голосования: 350 051, Г. КРАСНОДАР, УЛ. СТАХАНОВСКАЯ,
            Д. 15, КОРПУС 3, КОМНАТА 2.
            <br />
            <br />
            Вид собрания: внеочередное.
            <br />
            <br />
            Форма проведения собрания: Заочное голосование.
            <br />
            <br />
            Акционеры, желающие принять участие в общем собрании акционеров
            Общества, должны направить заполненные и подписанные бюллетени по
            адресу, указанному в настоящем сообщении.
            <br />
            <br />
            Документы, удостоверяющие полномочия правопреемников и
            представителей лиц, включенных в список лиц, имеющих право на
            участие в общем собрании (их копии, засвидетельствованные в
            установленном порядке), прилагаются к направляемым этими лицами
            бюллетеням для голосования.
            <br />
            <br />
            <br />
            Вопросы, включенные в повестку дня внеочередного общего собрания
            акционеров Общества:
            <br />
            <br />
            1. Об увеличении уставного капитала общества путём размещения
            дополнительных акций посредством закрытой подписки.
            <br />
            <br />
            Принявшими участие в общем собрании акционеров, проводимом в форме
            заочного голосования, считаются акционеры, бюллетени которых
            получены до даты окончания приема бюллетеней.
            <br />
            <br />
            <br />
            Акционеры Общества могут ознакомиться с материалами, подлежащими
            предоставлению при подготовке к проведению общего собрания
            акционеров Общества по адресу: Г. КРАСНОДАР, УЛ. СТАХАНОВСКАЯ, Д.
            15, КОРП.3, КОМ.2 в течение 20 дней до даты проведения общего
            собрания акционеров, в рабочие дни и часы Общества.
            <br />
            <br />
            <br />
            АО «САМОКАРТ»
            <br />
            <span id="bx-cursor-node"> </span>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <hr />
        </Container>
      </React.Fragment>
    );
  }
}

export default PageShareholders;
